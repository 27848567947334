import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

function CorteDiario() {
  const { user } = useContext(AuthContext);
  const URL_CORTE_DIARIO = process.env.REACT_APP_URL_CORTE_DIARIO;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  let hoy = new Date();
  let endDate = moment(hoy).format("YYYY-MM-DD");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(endDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [corteDiarioCortes, setCorteDiarioCortes] = useState([]);
  const [corteDiarioCompras, setCorteDiarioCompras] = useState([]);
  const [corteDiarioDevPro, setCorteDiarioDevPro] = useState([]);

  useMemo(() => {
    axios
      .get(`${URL_CORTE_DIARIO}/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCorteDiarioCortes = response.data.dataCortes;
        setCorteDiarioCortes(allCorteDiarioCortes);

        let allCorteDiarioCompras = response.data.dataCompras;
        setCorteDiarioCompras(allCorteDiarioCompras);

        let allCorteDiarioDevPro = response.data.dataDevPro;
        setCorteDiarioDevPro(allCorteDiarioDevPro);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);

  function excel() {
    const dataExcelCortes = corteDiarioCortes.map((a) => {
      return {
        Fecha: a.fecha,
        Cliente: a.cliente,
        Tela: a.tela,
        Color: a.color,
        Cantidad: a.cantidad,
      };
    });
    const dataExportExcelCortes = Object.values(dataExcelCortes);
    const dataExcelCortesLimpia = dataExportExcelCortes.filter(function (el) {
      return el != null;
    });

    const dataExcelCompras = corteDiarioCompras.map((a) => {
      return {
        Fecha: a.fecha,
        Compra: a.compra,
        Proveedor: a.proveedor,
        Tela: a.tela,
        Color: a.color,
        Cantidad: a.cantidad,
        Precio: a.precio,
        Total: a.total,
      };
    });
    const dataExportExcelCompras = Object.values(dataExcelCompras);
    const dataExcelComprasLimpia = dataExportExcelCompras.filter(function (el) {
      return el != null;
    });


    const dataExcelDevProveedor = corteDiarioDevPro.map((a) => {
      return {
        Fecha: a.fecha,
        Devolucion: a.devolucion,
        Proveedor: a.proveedor,
        Tela: a.tela,
        Color: a.color,
        Cantidad: a.cantidad,
        Precio: a.precio,
        Total: a.total,
      };
    });
    const dataExportExcelDevProveedor = Object.values(dataExcelDevProveedor);
    const dataExcelDevProveedorLimpia = dataExportExcelDevProveedor.filter(
      function (el) {
        return el != null;
      }
    );

    var option = {};

    option.fileName = "CorteDiario";

    option.datas = [
      {
        sheetData: dataExcelCortesLimpia,
        sheetName: "Cortes",
        sheetFilter: [
          "Fecha",
          "Cliente",
          "Tela",
          "Color",
          "Cantidad",
        ],
        sheetHeader: [
          "Fecha",
          "Cliente",
          "Tela",
          "Color",
          "Cantidad",
        ],
      },

      {
        sheetData: dataExcelComprasLimpia,
        sheetName: "Compras",
        sheetFilter: [
          "Fecha",
          "Compra",
          "Proveedor",
          "Tela",
          "Color",
          "Cantidad",
          "Precio",
          "Total",
        ],
        sheetHeader: [
          "Fecha",
          "Compra",
          "Proveedor",
          "Tela",
          "Color",
          "Cantidad",
          "Precio",
          "Total",
        ],
      },

      {
        sheetData: dataExcelDevProveedorLimpia,
        sheetName: "DevProveedor",
        sheetFilter: [
          "Fecha",
          "Devolucion",
          "Proveedor",
          "Tela",
          "Color",
          "Cantidad",
          "Precio",
          "Total",
        ],
        sheetHeader: [
          "Fecha",
          "Devolucion",
          "Proveedor",
          "Tela",
          "Color",
          "Cantidad",
          "Precio",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  let totalCortesDinero = 0;
  let totalCortesMetros = 0;
  let totalComprasDinero = 0;
  let totalComprasMetros = 0;
  let totalDevProDinero = 0;
  let totalDevProMetros = 0;
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_compras ? (
        <div className="card container col-11">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuReportes"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <h3 align="center"> Reporte Telas</h3>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            {/* Cortes */}
            <tbody>
              <tr>
                <th>CORTES</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th>Fecha</th>
                <th>Cliente</th>
                <th>Tela</th>
                <th>Color</th>
                <th>Cantidad</th>
              </tr>
              {corteDiarioCortes.map((a) => {
                // {commentsData.map((a) => {
                {
                  totalCortesMetros = totalCortesMetros + a.cantidad;
                  return (
                    <tr>
                      <td>{a.fecha}</td>
                      <td>{a.cliente}</td>
                      <td>{a.tela}</td>
                      <td>{a.color}</td>
                      <td>
                        {new Intl.NumberFormat("en-US", {
                          // style: "currency",
                          // currency: "USD",
                          maximumFractionDigits: 2,
                        }).format(a.cantidad)}
                      </td>
                    </tr>
                  );
                }
              })}

              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita">TOTAL</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                  }).format(totalCortesMetros)}
                </td>
              </tr>
            </tbody>
            <br />
            <br />
            {/* Compras */}
            <tbody>
              <tr>
                <th>COMPRAS</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th>Fecha</th>
                <th>Compra</th>
                <th>Proveedor</th>
                <th>Tela</th>
                <th>Color</th>
                <th>Cantidad</th>
                <th>Precio</th>
                <th>Total</th>
              </tr>
              {corteDiarioCompras.map((a) => {
                // {commentsData.map((a) => {
                {
                  totalComprasDinero = totalComprasDinero + a.total;
                  totalComprasMetros = totalComprasMetros + a.cantidad;
                  return (
                    <tr>
                      <td>{a.fecha}</td>
                      <td>{a.compra}</td>
                      <td>{a.proveedor}</td>
                      <td>{a.tela}</td>
                      <td>{a.color}</td>
                      <td>
                        {new Intl.NumberFormat("en-US", {
                          // style: "currency",
                          // currency: "USD",
                          maximumFractionDigits: 2,
                        }).format(a.cantidad)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                        }).format(a.precio)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                        }).format(a.total)}
                      </td>
                    </tr>
                  );
                }
              })}

              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita">TOTAL</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                  }).format(totalComprasMetros)}
                </td>
                <td></td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(totalComprasDinero)}
                </td>
              </tr>
            </tbody>
            <br />
            <br />

            {/* DEVOLUCIONES PROVEEDORES */}
            <tbody>
              <tr>
                <th>DEVOLUCIONES PROVEEDORES</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th>Fecha</th>
                <th>Devolucion</th>
                <th>Proveedor</th>
                <th>Tela</th>
                <th>Color</th>
                <th>Cantidad</th>
                <th>Precio</th>
                <th>Total</th>
              </tr>
              {corteDiarioDevPro.map((a) => {
                // {commentsData.map((a) => {
                {
                  totalDevProDinero = totalDevProDinero + a.total;
                  totalDevProMetros = totalDevProMetros + a.cantidad;
                  return (
                    <tr>
                      <td>{a.fecha}</td>
                      <td>{a.devolucion}</td>
                      <td>{a.proveedor}</td>
                      <td>{a.tela}</td>
                      <td>{a.color}</td>
                      <td>
                        {new Intl.NumberFormat("en-US", {
                          // style: "currency",
                          // currency: "USD",
                          maximumFractionDigits: 2,
                        }).format(a.cantidad)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                        }).format(a.precio)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                        }).format(a.total)}
                      </td>
                    </tr>
                  );
                }
              })}

              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita">TOTAL</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                  }).format(totalDevProMetros)}
                </td>
                <td></td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(totalDevProDinero)}
                </td>
              </tr>
            </tbody>
            <br />
            <br />
          </Table>
        </div>
      ) : undefined}
      <br />
    </>
  );
}

export default CorteDiario;
