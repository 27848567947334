import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesProduccion() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_produccion ?(
      <div className="container">
        <br />
        <br />
        <Row >
        {/* <Col md={4} align="center">
            {user.menu_proveedores ?(
            <Button href="/Maquileros" className="botonesMenu" color="success">
            <i class="fas fa-dolly fa-7x"></i>
              <br />
              <br />
              Maquileros
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-dolly fa-7x"></i>
              <br />
              <br />
              Maquileros
            </Button> }
          </Col>

          <Col md={4} align="center">
            {user.menu_produccion ?(
            <Button href="/CatalogoProcesos" className="botonesMenu" color="success">
            <i class="fas fa-tasks fa-7x"></i>
              <br />
              <br />
              Catalogo Procesos
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-tasks fa-7x"></i>
              <br />
              <br />
              Catalogo Procesos
            </Button> }
          </Col> */}

          <Col md={4} align="center">
            {user.menu_produccion ?(
            <Button href="/ListadoOrdenesCorte" className="botonesMenu" color="success">
            <i class="fas fa-file-alt fa-7x"></i>
              <br />
              <br />
              Ordenes de Corte
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-file-alt fa-7x"></i>
              <br />
              <br />
              Ordenes de Corte
            </Button> }
          </Col>

          <Col md={4} align="center">
            {user.menu_produccion ?(
            <Button href="/ListadoCortes" className="botonesMenu" color="success">
            <i class="fas fa-cut fa-7x"></i>
              <br />
              <br />
              Cortes
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-cut fa-7x"></i>
              <br />
              <br />
              Cortes
            </Button> }
          </Col>

          <Col md={4} align="center">
            {user.menu_produccion ?(
            <Button href="/CortesPendientesRecibir" className="botonesMenu" color="success">
            <i class="fas fa-cut fa-7x"></i>
              <br />
              <br />
              Procesos Pendientes <br/> Recibir
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-cut fa-7x"></i>
              <br />
              <br />
              Procesos Pendientes <br/> Recibir
            </Button> }
          </Col>

          </Row>
          <br />
          <Row>
          <Col md={4} align="center">
          {user.menu_produccion ?(
            <Button href="/ListadoApartados" className="botonesMenu" color="success">
              <i class="fas fa-people-carry fa-7x"></i>
              <br />
              <br />
              Apartados
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-people-carry fa-7x"></i>
          <br />
          <br />
          Apartados
        </Button> }
          </Col>


          <Col md={4} align="center">
            {user.menu_produccion ?(
            <Button href="/ListadoArticulosCortes" className="botonesMenu" color="success">
            <i class="fas fa-cut fa-7x"></i>
              <br />
              <br />
              Reporte Cortes
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-cut fa-7x"></i>
              <br />
              <br />
              Reporte Cortes
            </Button> }
          </Col>

        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesProduccion;
