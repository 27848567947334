import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import qrcode from "qrcode-js";
import { makeStyles } from "@material-ui/core/styles";
import BajaMultiple from "../../BajaMultiple";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function ListadoOrdenesCorte() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ORDENES_CORTE = process.env.REACT_APP_URL_ORDENES_CORTE;
  const URL_ARTICULOS_ORDENES_CORTE =
    process.env.REACT_APP_URL_ARTICULOS_ORDENES_CORTE;
  const URL_INVENTARIOS_TELAS = process.env.REACT_APP_URL_INVENTARIOS_TELAS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_EXPLOSION = process.env.REACT_APP_URL_EXPLOSION;
  const URL_EXPLOSION_GLOBAL = process.env.REACT_APP_URL_EXPLOSION_GLOBAL;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  // Agregar Tela a Orden de Corte
  const URL_TELAS_ORDENES_CORTE = process.env.REACT_APP_URL_TELAS_ORDENES_CORTE;
  const URL_TELAS_ORDENES_CORTES_ACUMULADOS =
    process.env.REACT_APP_URL_TELAS_ORDENES_CORTES_ACUMULADOS;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [ordenesCorte, setOrdenesCorte] = useState([]);

  const [inventarios, setInventarios] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [articulos, setArticulos] = useState([]);
  const [selectedArticulo, setSelectedArticulo] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [articulosEdit, setArticulosEdit] = useState([]);
  const [total_piezas, setTotalPiezas] = useState(0);

  const classes = useStyles();

  const [validaBoton, setValidaBoton] = useState(true);

  const [fechaTelas, setFechaTelas] = useState(endDate);
  const [observacionesTelas, setObservacionesTelas] = useState("NA");
  const [observacionesTelasPDF, setObservacionesTelasPDF] = useState("");
  const [selectedOrdenCorte, setSelectedOrdenCorte] = useState("");
  const [selectedOrdenCorteNumero, setSelectedOrdenCorteNumero] = useState("");
  const [totalMetros, setTotalMetros] = useState("");
  const [totalKilos, setTotalKilos] = useState("");
  const [cantidadAgrupado, setCantidadAgrupado] = useState([]);
  const [articulosOrden, setArticulosOrden] = useState([]);
  const [rollosTotales, setRollosTotales] = useState(0);
  const [articulosUnicos, setArticulosUnicos] = useState([]);

  const [modalTelas, setModalTelas] = useState(false);
  const toggleTelas = () => setModalTelas(!modalTelas);

  const [modalTelasConsumo, setModalTelasConsumo] = useState(false);
  const toggleTelasConsumo = () => setModalTelasConsumo(!modalTelasConsumo);
  const [explosion, setExplosion] = useState([]);
  const [idEdit, setIdEdit] = useState("");
  const [numero_pedido, setNumeroPedido] = useState("");
  const [modalExplosion, setModalExplosion] = useState(false);
  const toggleExplosion = () => setModalExplosion(!modalExplosion);

  const [telasDevolucion, setTelasDevolucion] = useState("");
  const [idsInventario, setIdsInventario] = useState("");
  const [selectedOrdenCorteDev, setSelectedOrdenCorteDev] = useState("");
  const [selectedOrdenCorteNumeroDev, setSelectedOrdenCorteNumeroDev] =
    useState("");
  const [modalTelasDev, setModalTelasDev] = useState(false);
  const toggleTelasDev = () => setModalTelasDev(!modalTelasDev);
  const [rollosTotalesDev, setRollosTotalesDev] = useState(0);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      idInventarioTelas: "",
      telas: "",
      idTela: "",
      unidad: "",
      colores: "",
      idColor: "",
      cantidadOriginal: 0,
      cantidad: 0,
      idArticulo: "",
      compras: "",
      telasOC: "",
      telaCodigo: "",
      telaNombre: "",
      telaColor: "",
    },
  ]);

  const [inputFieldsAnterior, setInputFieldsAnterior] = useState([]);

  const [inputFieldsAnteriorConsumo, setInputFieldsAnteriorConsumo] = useState([]);
  useMemo(() => {
    axios
      .get(
        `${URL_ORDENES_CORTE}Fechas/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allOrdenes = response.data;
        // Array para pagination
        let arrayTabla = allOrdenes
          .sort((a, b) => (a.numero < b.numero ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.numero,
              fecha: a.fecha,
              pedido: a.pedidos[0].idPedido,
              idPedidos: a.pedidos[0]._id,
              cortado: a.cortado,
              cliente: a.pedidos[0].clientes[0].nombre_comercial,
              idCliente: a.pedidos[0].clientes[0]._id,
              articulo:
                "Cod: " +
                a.pedidos[0].articulos[0].codigoCliente +
                " / Ref: " +
                a.pedidos[0].articulos[0].codigo +
                " / Desc: " +
                a.pedidos[0].articulos[0].descripcion,
              idArticulo: a.pedidos[0].articulos[0]._id,
              fechaCancelacion: a.pedidos[0].fecha_cancelacion,
              total_piezas: a.cantidad,
              observaciones: a.observaciones,
              observacionesPedido: a.pedidos[0].observaciones,
              is_active: a.is_active,
              pedidoEditado: a.pedidos[0].editado,
              patronista: a.pedidos[0].articulos[0].patronista[0].nombre + " " + a.pedidos[0].articulos[0].patronista[0].apellido,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setOrdenesCorte(allOrdenes);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_INVENTARIOS_TELAS}Existencia`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInventarios = response.data;
        setInventarios(allInventarios);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);



  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente >= a.idCliente) &&
        (selectedArticulo == 0 || selectedArticulo >= a.idArticulo)
      ) {
        return [a.numero, a.fecha, a.pedido];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Ordenes de Corte`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Pedido"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    // doc.save(`Ordenes-Corte.pdf`);
    window.open(doc.output("bloburl"), "_blank");
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente >= a.idCliente) &&
        (selectedArticulo == 0 || selectedArticulo >= a.idArticulo)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Pedido: a.pedido,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoOrdenesCorte";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoOrdenesCorte",
        sheetFilter: ["Numero", "Fecha", "Pedido"],
        sheetHeader: ["Numero", "Fecha", "Pedido"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente >= a.idCliente) &&
        (selectedArticulo == 0 || selectedArticulo >= a.idArticulo)
      ) {
        return [a.numero, a.fecha, a.pedido];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Ordenes de Corte`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Pedido"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Ordenes Corte",
          email: mailTo,
          fileName: "ListadoOrdenesCorte.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Ordenes de Corte.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha Creacion", field: "fecha", sortable: false },
    { name: "Cliente", field: "cliente", sortable: false },
    { name: "Articulo", field: "articulo", sortable: false },
    { name: "Observaciones", field: "observacionesPedido", sortable: false },
    { name: "Fecha Cancelacion", field: "fechaCancelacion", sortable: false },
    { name: "Cantidad", field: "total_piezas", sortable: false },
    { name: "Pedido Interno", field: "pedido", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
    { name: "Tela", field: "Tela", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.fecha.toLowerCase().includes(search.toLowerCase()) ||
          comment.articulo.toLowerCase().includes(search.toLowerCase()) ||
          comment.observacionesPedido.toLowerCase().includes(search.toLowerCase()) ||
          comment.fechaCancelacion
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.numero.toString().includes(search) ||
          comment.total_piezas.toString().includes(search) ||
          comment.pedido.toString().includes(search)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idCliente.includes(selectedCliente)
      );
    }
    if (selectedArticulo) {
      computedComments = computedComments.filter((e) =>
        e.idArticulo.includes(selectedArticulo)
      );
    }
    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "total_piezas" &&
      sorting.field != "numero" &&
      sorting.field != "pedido"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "numero" ||
        sorting.field == "total_piezas" ||
        sorting.field == "pedido")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "numero" ||
        sorting.field == "total_piezas" ||
        sorting.field == "pedido")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
    selectedCliente,
    selectedArticulo,
  ]);

  let totalCantidad = 0;
  let totalCortado = 0;
  let totalPendCortar = 0;

  async function PDFOC(numero, fecha, pedido, idPedidos, articulo, idArticulo, totalPiezas, observaciones, idPDFOC, cliente, patronista) {
    let temp2 = [];
    await axios
        .get(`${URL_ARTICULOS_ORDENES_CORTE}/orden/${idPDFOC}`, {
            headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
        })
        .then(async (response) => {
            let allArticulosOrdenCorte = response.data;
            let colorTallaCantidad = {};

            allArticulosOrdenCorte.forEach((a) => {
                const color = a.articulosPedido[0].colores[0].name;
                const talla = a.articulosPedido[0].tallas[0].name;
                const cantidad = a.cantidad;

                if (!colorTallaCantidad[talla]) {
                    colorTallaCantidad[talla] = {};
                }
                if (!colorTallaCantidad[talla][color]) {
                    colorTallaCantidad[talla][color] = 0;
                }
                colorTallaCantidad[talla][color] += cantidad;
            });

            const tallas = Object.keys(colorTallaCantidad).sort();
            const colores = [
                ...new Set(allArticulosOrdenCorte.map((a) => a.articulosPedido[0].colores[0].name)),
            ].sort();


            await axios
                .get(`${URL_EXPLOSION}Articulo/${idArticulo}`, {
                    headers: {
                        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                    },
                })
                .then(async (response) => {
                    let allExplosion = response.data;
                    let arrayExp = allExplosion
                        .map((b) => {
                            if (b.tipo == "Habilitacion") {
                                return {
                                    material: b.habilitacion[0].nombre + " " + b.habilitacion[0].descripcion,
                                    cantidadTotal: b.cantidad * totalPiezas,
                                    cantidad: b.cantidad,
                                    observaciones: b.observaciones,
                                };
                            }
                        })
                        .filter(function (el) {
                            return el != null;
                        });

                    let dataFinalHab = Object.values(arrayExp);
                    const dataHab = dataFinalHab.map((a) => {
                        return [
                            a.material,
                            a.observaciones,
                            new Intl.NumberFormat("en-US").format(a.cantidad),
                            new Intl.NumberFormat("en-US").format(a.cantidadTotal),
                        ];
                    });
                    const dataPDF = Object.values(dataHab);
                    const dataPDFLimpia = dataPDF.filter(function (el) {
                        return el != null;
                    });



                    await axios
                        .get(`${URL_EXPLOSION_GLOBAL}Pedido/${idPedidos}`, {
                            headers: {
                                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                            },
                        })
                        .then((response) => {
                            let allExplosionGlb = response.data;
                            let arrayExpGlb = allExplosionGlb
                                .map((a) => {
                                    if (a.cantidad > 0) {
                                        return {
                                            id: a._id,
                                            tela: a.telas[0].nombre,
                                            color: a.colores[0].name,
                                            cantidad: a.cantidad,
                                            unidad: a.telas[0].unidad,
                                            cantidadPrenda: a.cantidadPrenda,
                                            composicion: a.telas[0].composicion
                                        };
                                    }
                                })
                                .filter(function (el) {
                                    return el != null;
                                });

                            let dataFinalGlb = Object.values(arrayExpGlb);
                            const dataGlb = dataFinalGlb.map((a) => {
                                return [
                                    a.tela,
                                    a.color,
                                    new Intl.NumberFormat("en-US").format(a.cantidad) +
                                    " " +
                                    a.unidad,
                                    new Intl.NumberFormat("en-US").format(a.cantidadPrenda),
                                    a.composicion,
                                ];
                            });
                            const dataPDFGlb = Object.values(dataGlb);
                            const dataPDFLimpiaGlb = dataPDFGlb.filter(function (el) {
                                return el != null;
                            });

                            const doc = new jsPDF();

                            // Agregar imágenes y texto
                            var img = new Image();
                            img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
                            doc.addImage(img, "png", 150, 10, 33, 10);
                            var img2 = new Image();
                            img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
                            doc.addImage(img2, "png", 150, 20, 45, 25);
                            doc.setFont("helvetica", "bold")
                            doc.setFontSize(14);
                            doc.text(`ORDEN DE PRODUCCION`, 15, 13);
                            doc.setFont("helvetica", "normal")
                            doc.setFontSize(12);
                            doc.text(`Numero Orden de Corte # ${numero}`, 15, 20);
                            doc.text(`Fecha ${fecha}`, 15, 25);
                            doc.text(`Pedido ${pedido}`, 15, 30);
                            doc.text(`Observaciones ${observaciones}`, 15, 35);
                            doc.text(`Cliente ${cliente}`, 15, 40);
                            doc.text(`Patronista ${patronista}`, 15, 45);
                            doc.setFontSize(10);
                            doc.text(`Articulo ${articulo}`, 15, 50);

                            // Crear una variable para almacenar todas las filas de datos
                            const bodyData = [];

                            // Iterar sobre las tallas
                            tallas.forEach((talla) => {
                                let rowData = [talla];
                                let totalTalla = 0;

                                // Iterar sobre los colores
                                colores.forEach((color) => {
                                    const cantidad = colorTallaCantidad[talla][color] || 0;
                                    rowData.push(cantidad);
                                    totalTalla += cantidad;
                                });

                                // Agregar el total por talla al final de la fila
                                rowData.push(totalTalla);

                                // Agregar la fila de datos al conjunto de datos del cuerpo
                                bodyData.push(rowData);
                            });

                            // Calcular y agregar totales por color en una fila al final de la tabla
                            const totalesColorRow = ["Total"];
                            let totalGeneral = 0; // Inicializar el total general
                            colores.forEach((color) => {
                                let totalColor = 0;
                                tallas.forEach((talla) => {
                                    totalColor += colorTallaCantidad[talla][color] || 0;
                                });
                                totalesColorRow.push(totalColor);
                                totalGeneral += totalColor; // Sumar al total general
                            });

                            // Agregar el total general al final de la fila de totales
                            totalesColorRow.push(totalGeneral);

                            // Agregar totalesColorRow al final de bodyData
                            bodyData.push(totalesColorRow);

                            // Generar la tabla con todos los datos del cuerpo y los estilos definidos fuera del bucle
                            doc.autoTable({
                                head: [["Tallas", ...colores, "Total"]],
                                body: bodyData, // Utilizar el conjunto de datos del cuerpo
                                startY: 60,
                                styles: {
                                    fontSize: 10,
                                    cellPadding: 0.7,
                                    valign: "middle",
                                    halign: "center",
                                }, // Estilos generales de las celdas
                                headStyles: {
                                    fillColor: [41, 128, 186],
                                    textColor: [255, 255, 255],
                                    fontStyle: "bold",
                                    halign: "center",
                                }, // Estilos para el encabezado
                            });

                            doc.text(`Tela`, 15, doc.autoTable.previous.finalY + 10);
                            doc.autoTable({
                                head: [["Tela",
                                    "Color",
                                    "Cantidad",
                                    "Consumo Prenda",
                                    "Composicion",]],
                                body: dataPDFLimpiaGlb,
                                startY: doc.autoTable.previous.finalY + 13,
                                showFoot: "lastPage",
                            });

                            doc.text(`Habilitacion`, 15, doc.autoTable.previous.finalY + 10);
                            doc.autoTable({
                                head: [["Descripcion", "Observaciones", "Cantidad", "Total"]],
                                body: dataPDFLimpia,
                                startY: doc.autoTable.previous.finalY + 13,
                                showFoot: "lastPage",
                            });

                            // Guardar o mostrar el PDF
                            doc.save(`OrdenCorte-${numero}.pdf`);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
        })
        .catch((err) => {
            console.log(err);
        });
}


  async function requisicion(id, numero) {
    setExplosion([]);
    toggleProgreso();
    setIdEdit(id);
    setNumeroPedido(numero);
    axios
      .get(`${URL_ARTICULOS_ORDENES_CORTE}/orden/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then(async (response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = await allArticulosPedido
          .map((a) => {
            return {
              id: a._id,
              activo: a.is_active,
              articulos: a.articulosPedido[0].articulos[0]._id,
              articulosCodigo:
                a.articulosPedido[0].articulos[0].codigo +
                " / " +
                a.articulosPedido[0].articulos[0].codigoCliente,
              colores: a.articulosPedido[0].colores[0]._id,
              coloresName: a.articulosPedido[0].colores[0].name,
              cantidad: a.cantidad,
              surtido: a.articulosPedido[0].surtido,
              pendiente_surtir: a.articulosPedido[0].pendiente_surtir,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);

        let agrupado = data.reduce(function (groups, item) {
          const val = item["articulos"];
          groups[val] = groups[val] || {
            articulos: item.articulos,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].articulos = item.articulos;
          groups[val].articulosCodigo = item.articulosCodigo;
          return groups;
        }, []);

        let dataFinal = Object.values(agrupado);

        let agrupadoColores = data.reduce(function (groups, item) {
          const val = item["articulos"] + item["colores"];
          groups[val] = groups[val] || {
            articulos: item.articulos,
            colores: item.colores,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].articulos = item.articulos;
          groups[val].articulosCodigo = item.articulosCodigo;
          groups[val].colores = item.colores;
          groups[val].coloresName = item.coloresName;
          return groups;
        }, []);

        let dataFinalColores = Object.values(agrupadoColores);

        let temp2 = [];

        let exp = await dataFinal.map((a) => {
          axios
            .get(`${URL_EXPLOSION}Articulo/${a.articulos}`, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then((response) => {
              let allExplosion = response.data;
              let arrayExp = allExplosion
                .map((b) => {
                  if (b.habilitacion != "") {
                    temp2.push({
                      articulos: a.articulos,
                      articulosCodigo: a.articulosCodigo,
                      material: b.habilitacion[0].nombre  + " " + b.habilitacion[0].descripcion,
                      cantidadTotal: b.cantidad * a.cantidad,
                      cantidad: b.cantidad,
                      colores: "NA",
                      coloresName: "NA",
                    });
                  }
                })
                .filter(function (el) {
                  return el != null;
                });
            })
            .catch((err) => {
              console.log(err);
            });
        });

        let expColores = await dataFinalColores.map((a) => {
          axios
            .get(`${URL_EXPLOSION}Articulo/${a.articulos}`, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then((response) => {
              let allExplosion = response.data;
              let arrayExp = allExplosion
                .map((b) => {
                  if (b.telas != "") {
                    temp2.push({
                      articulos: a.articulos,
                      articulosCodigo: a.articulosCodigo,
                      material: b.telas[0].nombre,
                      cantidadTotal: b.cantidad * a.cantidad,
                      cantidad: b.cantidad,
                      colores: a.colores,
                      coloresName: a.coloresName,
                    });
                  }
                })
                .filter(function (el) {
                  return el != null;
                });
            })
            .catch((err) => {
              console.log(err);
            });
        });
        setExplosion(temp2);
        setTimeout(() => {
          setModalProgreso(false);
          toggleExplosion();
        }, 3500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function telasCortes(id, numero) {
    toggleProgreso()
    axios
      .get(`${URL_TELAS_ORDENES_CORTES_ACUMULADOS}/ordenCorte/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInputFieldsAnterior = response.data;
        let tablaTelas = allInputFieldsAnterior.map((a) => {
          return {
            articulo:
              a.articulos[0].codigo +
              " / " +
              a.articulos[0].codigoCliente +
              " / " +
              a.articulos[0].descripcion,
            tela: a.telas[0].nombre,
            color: a.colores[0].name,
            unidad: a.telas[0].unidad,
            cantidad: a.cantidad,
            telaNombre: a.telas[0].codigo + " / " + a.telas[0].nombre + " / " + a.colores[0].name
          };
        });
        let dataFinal = Object.values(tablaTelas);

        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["tela"] + item["color"];
          groups[val] = groups[val] || {
            tela: item.tela,
            color: item.color,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].tela = item.tela;
          groups[val].unidad = item.unidad;
          groups[val].color = item.color;
          groups[val].articulo = item.articulo;
          groups[val].telaNombre = item.telaNombre;
          return groups;
        }, []);

        let agrupadoValues = Object.values(agrupado);

        setInputFieldsAnterior(agrupadoValues);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_ARTICULOS_ORDENES_CORTE}/orden/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosOrdenCorte = response.data;
        let arrayTabla = allArticulosOrdenCorte
          .map((a) => {
            totalCantidad = totalCantidad + a.cantidad;
            totalCortado = totalCortado + a.cortado;
            totalPendCortar = totalPendCortar + a.pendiente_cortar;
            return {
              id: a._id,
              articuloNombre:
                a.articulosPedido[0].articulos[0].codigo +
                " / " +
                a.articulosPedido[0].articulos[0].codigoCliente +
                " / " +
                a.articulosPedido[0].articulos[0].descripcion,
              idArticulo: a.articulosPedido[0].articulos[0]._id,
              cantidad: a.cantidad,
              cortado: a.cortado,
              pendiente_cortar: a.pendiente_cortar,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        const data = Object.values(arrayTabla);
        setArticulosOrden(data);

        let agrupado = data.reduce(function (groups, item) {
          const val = item["idArticulo"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
          };
          groups[val].idArticulo = item.idArticulo;
          groups[val].articuloNombre = item.articuloNombre;
          return groups;
        }, []);
        let dataFinalAgru = Object.values(agrupado);
        setArticulosUnicos(dataFinalAgru);
      })
      .catch((err) => {
        console.log(err);
      });

    setSelectedOrdenCorte(id);
    setSelectedOrdenCorteNumero(numero);
    setTimeout(() => {
      toggleTelas();
      setModalProgreso(false)
    }, 2000);
  }

  function telasCortesConsumo(id, numero) {
    toggleProgreso()
    axios
      .get(`${URL_TELAS_ORDENES_CORTES_ACUMULADOS}/ordenCorte/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInputFieldsAnterior = response.data;
        let tablaTelas = allInputFieldsAnterior.map((a) => {
          return {
            id: a._id,
            articulo:
              a.articulos[0].codigo +
              " / " +
              a.articulos[0].codigoCliente +
              " / " +
              a.articulos[0].descripcion,
            tela: a.telas[0].nombre,
            color: a.colores[0].name,
            unidad: a.telas[0].unidad,
            cantidad: a.cantidad,
            telaNombre: a.telas[0].codigo + " / " + a.telas[0].nombre + " / " + a.colores[0].name,
            consumo: a.consumo,
            piezas: a.piezas,
          };
        });
        let dataFinal = Object.values(tablaTelas);

       
        setInputFieldsAnteriorConsumo(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });

    setSelectedOrdenCorte(id);
    setSelectedOrdenCorteNumero(numero);
    setTimeout(() => {
      toggleTelasConsumo();
      setModalProgreso(false)
    }, 1000);
  }

  const handleChangeInput = (id, event) => {
    const newInventarios = inputFieldsAnteriorConsumo.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
      }
      return i;
    });
    setInputFieldsAnteriorConsumo(newInventarios);
    ActualizaTotales();
};

  function telasCortesDev(id, numero) {
    setIdsInventario([]);
    axios
      .get(`${URL_INVENTARIOS_TELAS}/cortesDevolucion/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTelasDevolucion = res.data;
        setTelasDevolucion(allTelasDevolucion);
        let result = allTelasDevolucion.map(({ _id }) => _id);
        setIdsInventario(result);
      })
      .catch((err) => {
        console.log(err);
      });
    setSelectedOrdenCorteDev(id);
    setSelectedOrdenCorteNumeroDev(numero);
    toggleTelasDev();
  }

  function BuscaRolloDev(id, event) {
    if (event.target.value.length >= 24) {
      if (idsInventario.includes(event.target.value)) {
        if (
          inputFields.filter((e) => e.idInventarioTelas == event.target.value)
            .length > 0
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El rollo ya fue escaneado!",
            showConfirmButton: false,
          });
          const newInputFields = inputFields.map((i) => {
            if (id === i.id) {
              i.idInventarioTelas = "";
            }
            return i;
          });
          setInputFields(newInputFields);
        } else {
          telasDevolucion.map((a) => {
            if (a._id == event.target.value) {
              let telasDevolucion = a._id;
              let tela = a.tela;
              let idTela = a.idTela;
              let unidadTela = a.unidadTela;
              let color = a.color;
              let cantidadOriginal = a.cantidad;
              let cantidad = a.cantidad;
              let compras = a.compras;
              let telasOC = a.telasOC;
              let telaCodigo = a.telaCodigo;
              let telaNombre = a.telaNombre;
              let telaColor = a.telaColor;
              handleChangeInputArticuloDev(
                id,
                telasDevolucion,
                tela,
                idTela,
                unidadTela,
                color,
                cantidad,
                cantidadOriginal,
                compras,
                telasOC,
                telaCodigo,
                telaNombre,
                telaColor
              );
            }
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El rollo no pertenece al pedido o fue devuelto previamente!",
          showConfirmButton: false,
        });
        const newInputFields = inputFields.map((i) => {
          if (id === i.id) {
            i.idInventarioTelas = "";
          }
          return i;
        });
        setInputFields(newInputFields);
      }
    } else {
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.idInventarioTelas = event.target.value;
        }
        return i;
      });
      setInputFields(newInputFields);
    }
  }

  const handleChangeInputArticuloDev = (
    id,
    telasDevolucion,
    tela,
    idTela,
    unidadTela,
    color,
    cantidad,
    cantidadOriginal,
    compras,
    telasOC,
    telaCodigo,
    telaNombre,
    telaColor
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.idInventarioTelas = telasDevolucion;
        i.telas = tela;
        i.idTela = idTela;
        i.unidad = unidadTela;
        i.colores = color;
        i.cantidad = cantidad;
        i.cantidadOriginal = cantidadOriginal;
        i.compras = compras;
        i.telasOC = telasOC;
        i.telaCodigo = telaCodigo;
        i.telaNombre = telaNombre;
        i.telaColor = telaColor;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotalesDev();
    handleAddFields();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotalesDev();
  };

  function ActualizaTotalesDev() {
    let mts = inputFields.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = inputFields.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);

    let agrupado = inputFields.reduce(function (groups, item) {
      const val = item["colores"] + item["idTela"];
      groups[val] = groups[val] || {
        colores: item.colores,
        idTela: item.idTela,
        cantidad: 0,
      };
      groups[val].id = item.id;
      groups[val].cantidad += item.cantidad;
      groups[val].colores = item.colores;
      groups[val].idTela = item.idTela;
      return groups;
    }, []);

    let CA = Object.values(agrupado);
    setCantidadAgrupado(CA);

    let tr = 0;
    inputFields.map((a) => {
      if (a.idTela != "") {
        return (tr = tr + 1);
      }
    });
    setRollosTotalesDev(tr);
  }

  const saveTelasDev = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();

    let totalRollos = inputFields.length + cantidadAgrupado.length;

    try {
      inputFields.map((a) => {
        if (a.idInventarioTelas != "") {
          if (a.cantidad != a.cantidadOriginal) {
            axios
              .patch(
                `${URL_INVENTARIOS_TELAS}/${a.idInventarioTelas}`,
                {
                  cantidad:
                    parseFloat(a.cantidadOriginal) - parseFloat(a.cantidad),
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                axios
                  .post(
                    `${URL_INVENTARIOS_TELAS}`,
                    {
                      cantidad: a.cantidad,
                      captura: 1,
                      produccion: "No",
                      telas: a.idTela,
                      colores: a.colores,
                      compras: a.compras,
                      telasOC: a.telasOC,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then((data) => {
                    totalRollos = totalRollos - 1;
                    QRGen(
                      data.data._id,
                      a.telaCodigo,
                      a.telaNombre,
                      a.telaColor,
                      a.cantidad,
                      a.unidad
                    );
                    if (totalRollos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Devolucion",
                            detalle: `Orden Corte ${selectedOrdenCorteDev}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          } else {
            axios
              .patch(
                `${URL_INVENTARIOS_TELAS}/devolucionCorte/${a.idInventarioTelas}`,
                {},
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Devolucion",
                        detalle: `Orden Corte ${selectedOrdenCorteDev}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
                setValidaBoton(true);
              });
          }
        } else {
          totalRollos = totalRollos - 1;
          if (totalRollos == 0) {
            axios
              .post(
                URL_LOGS,
                {
                  tipo: "Crear Devolucion",
                  detalle: `Orden Corte ${selectedOrdenCorteDev}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                Swal.fire("Good job!", "Creado con exito", "success");
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
                setValidaBoton(true);
              });
          }
        }
      });

      // Pegamos a Devoluciones Acumulado

      cantidadAgrupado.map((w) => {
        if (w.idTela != "") {
          axios
            .patch(
              `${URL_INVENTARIOS_TELAS}DevolucionCorte2`,
              {
                ordenesCorte: selectedOrdenCorteDev,
                total_cantidad: w.cantidad,
                idColor: w.colores,
                idTela: w.idTela,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then(() => {
              totalRollos = totalRollos - 1;
              if (totalRollos == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Devolucion",
                      detalle: `Orden Corte ${selectedOrdenCorteDev}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        } else {
          totalRollos = totalRollos - 1;
          if (totalRollos == 0) {
            axios
              .post(
                URL_LOGS,
                {
                  tipo: "Crear Devolucion",
                  detalle: `Orden Corte ${selectedOrdenCorteDev}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                Swal.fire("Good job!", "Creado con exito", "success");
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
                setValidaBoton(true);
              });
          }
        }
      });

      // Terminamos de post a devoluciones acumulado
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  function QRGen(id, codigo, nombre, color, cantidad, unidad) {
    const doc = new jsPDF("p", "mm", [102, 80]);
    let alturaLogo = 30;
    let alturaImagen = 60;
    let alturaTexto1 = 43;
    let alturaTexto2 = 48;
    let alturaTexto3 = 53;
    let alturaTexto4 = 58;
    let alturaTexto5 = 68;
    let alturaLogo2 = 35;

    let codigoQR = id;
    var base64 = qrcode.toDataURL(codigoQR);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 70, alturaLogo, 35, 10, null, null, 90);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 40, alturaLogo2, 60, 25, null, null, 90);
    var img3 = new Image();
    img3.src = base64;
    doc.addImage(img3, "png", 0, alturaImagen, 40, 40);

    doc.setFontSize(11);
    doc.text(`Cod ${codigo}`, alturaTexto1, 90, null, 90);
    doc.text(`Mod ${nombre}`, alturaTexto2, 90, null, 90);
    doc.text(`Color ${color}`, alturaTexto3, 90, null, 90);
    doc.text(`ID ${id}`, alturaTexto4, 90, null, 90);
    doc.setFontSize(18);
    doc.text(`Cant. ${cantidad} ${unidad}`, alturaTexto5, 90, null, 90);
    // doc.save(`QR.pdf`);
    window.open(doc.output("bloburl"), "_blank");
  }

  let totalCantidadMt = 0;
  let totalCantidadKg = 0;

  async function PDFTelas(numero, fecha, pedido, id, total_piezas, cliente, articulo, patronista, idPedido) {
    try {
      let observacionesTela = "NA";
  
      // Observaciones de telas
      const observacionesResponse = await axios.get(`${URL_TELAS_ORDENES_CORTE}/ordenCorte/${id}`, {
        headers: { Authorization: `Bearer: ${localStorage.getItem("app_token")}` },
      });
  
      const allObservacionesTela = observacionesResponse.data;
      const observTela = allObservacionesTela
        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
        .map((a) => ({ observaciones: a.observaciones }))
        .filter((el) => el != null);
  
      if (observTela.length > 0) {
        observacionesTela = observTela[0].observaciones;
      }
  
      // Obtener los artículos de la orden de corte
      const articulosResponse = await axios.get(`${URL_ARTICULOS_ORDENES_CORTE}/orden/${id}`, {
        headers: { Authorization: `Bearer: ${localStorage.getItem("app_token")}` },
      });
  
      const allArticulosOrdenCorte = articulosResponse.data;
      let colorTallaCantidad = {};
  
      allArticulosOrdenCorte.forEach((a) => {
        const color = a.articulosPedido[0]?.colores[0]?.name;
        const talla = a.articulosPedido[0]?.tallas[0]?.name;
        const cantidad = a.cantidad;
  
        if (!colorTallaCantidad[talla]) {
          colorTallaCantidad[talla] = {};
        }
        if (!colorTallaCantidad[talla][color]) {
          colorTallaCantidad[talla][color] = 0;
        }
        colorTallaCantidad[talla][color] += cantidad;
      });
  
      const tallas = Object.keys(colorTallaCantidad).sort();
      const colores = [
        ...new Set(allArticulosOrdenCorte.map((a) => a.articulosPedido[0]?.colores[0]?.name)),
      ].sort();
  
      // Obtener el consumo de telas
const consumoResponse = await axios.get(`${URL_TELAS_ORDENES_CORTES_ACUMULADOS}/ordenCorte/${id}`, {
  headers: { Authorization: `Bearer: ${localStorage.getItem("app_token")}` },
});

const allconsumosTela = consumoResponse.data;
const consumoTela = allconsumosTela
  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
  .map((a) => ({
    consumo: a.consumo,
    piezas: a.piezas,
    telaNombre: a.telas[0].codigo + " / " + a.telas[0].nombre,
    colorNombre: a.colores[0].name,
  }))
  .filter((el) => el != null);

let dataFinalConsumo = Object.values(consumoTela);

// Obtener el inventario de telas
const inventarioResponse = await axios.get(`${URL_INVENTARIOS_TELAS}/ordenCorte/${id}`, {
  headers: { Authorization: `Bearer: ${localStorage.getItem("app_token")}` },
});

const allInventariosOrdenCorte = inventarioResponse.data.data;
let totalRollos = inventarioResponse.data.totalRollos;
let totalCantidadMt = 0;
let totalCantidadKg = 0;

const arrayTabla = allInventariosOrdenCorte.map((a) => {
  if (a.unidad == "Metros") {
    totalCantidadMt += a.cantidad;
  } else {
    totalCantidadKg += a.cantidad;
  }
  return {
    id: a._id,
    articulo: a.articulo,
    proveedor: a.proveedor,
    factura: a.factura,
    cantidad: a.cantidad,
    cantidadSobrante: a.cantidadSobrante,
    unidad: a.unidad,
    idTela: a.idTela,
    telaNombre: a.articulo,
    colorNombre: a.colorNombre,
    idColor: a.idColor,
    cantidadString: a.cantidadString,
    rollos: a.rollos,
    precio: a.precio,
  };
});

const dataFinal = arrayTabla.filter((el) => el != null);

// Combinar los datos de consumo y piezas con dataFinal acumulando consumos
const combinedData = dataFinal.map((item) => {
  const matchingConsumos = dataFinalConsumo.filter(
    (consumoItem) =>
      consumoItem.telaNombre === item.telaNombre &&
      consumoItem.colorNombre === item.colorNombre
  );

  // Sumar consumos y piezas cuando coinciden los nombres
  const totalConsumo = matchingConsumos.reduce((sum, consumo) => sum + (consumo.consumo || 0), 0);
  const totalPiezas = matchingConsumos.reduce((sum, consumo) => sum + (consumo.piezas || 0), 0);

  return {
    ...item,
    consumo: totalConsumo,
    piezas: totalPiezas,
  };
});
  
      const data = combinedData
        .filter((a) => a.cantidad > 0)
        .sort((a, b) => (a.articulo < b.articulo ? 1 : -1))
        .map((a) => [
          a.proveedor,
          a.articulo,
          a.colorNombre,
          "$" + new Intl.NumberFormat("en-US").format(a.precio),
          a.factura,
          new Intl.NumberFormat("en-US").format(a.cantidad) + " " + a.unidad,
          new Intl.NumberFormat("en-US").format(a.consumo),
          new Intl.NumberFormat("en-US").format(a.piezas),
          new Intl.NumberFormat("en-US").format(a.cantidadSobrante),
          a.cantidadString,
          a.rollos
        ]);
  
      const dataPDF = Object.values(data).filter((el) => el != null);
  
      // Generación del PDF
      const doc = new jsPDF("landscape");
      var img = new Image();
      img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
      doc.addImage(img, "png", 230, 30, 45, 15);
      var img2 = new Image();
      img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
      doc.addImage(img2, "png", 120, 7, 60, 25);
      doc.text(`Salida Tela OC # ${numero}`, 20, 20);
      doc.setFontSize(12);
      doc.text(`Fecha OC: ${fecha}`, 20, 25);
      doc.text(
        `Cantidad: ${totalCantidadMt.toFixed(2)} Mts / ${totalCantidadKg.toFixed(2)} Kgs / ${totalRollos} Rollos`,
        20,
        30
      );
      doc.text(`Observaciones: ${observacionesTela}`, 20, 35);
      doc.text(`Articulo: ${articulo}`, 20, 40);
  
      doc.text(`Pedido: ${pedido}`, 180, 20);
      doc.text(`Cliente: ${cliente}`, 180, 25);
      doc.text(`Patronista: ${patronista}`, 180, 30);
      doc.text(`Piezas: ${total_piezas}`, 180, 35);
  
      // Crear una variable para almacenar todas las filas de datos
      const bodyData = [];
  
      // Iterar sobre las tallas
      tallas.forEach((talla) => {
        let rowData = [talla];
        let totalTalla = 0;
  
        // Iterar sobre los colores
        colores.forEach((color) => {
          const cantidad = colorTallaCantidad[talla][color] || 0;
          rowData.push(cantidad);
          totalTalla += cantidad;
        });
  
        // Agregar el total por talla al final de la fila
        rowData.push(totalTalla);
  
        // Agregar la fila de datos al conjunto de datos del cuerpo
        bodyData.push(rowData);
      });
  
      // Calcular y agregar totales por color en una fila al final de la tabla
      const totalesColorRow = ["Total"];
      let totalGeneral = 0; // Inicializar el total general
      colores.forEach((color) => {
        let totalColor = 0;
        tallas.forEach((talla) => {
          totalColor += colorTallaCantidad[talla][color] || 0;
        });
        totalesColorRow.push(totalColor);
        totalGeneral += totalColor; // Sumar al total general
      });
  
      // Agregar el total general al final de la fila de totales
      totalesColorRow.push(totalGeneral);
  
      // Agregar totalesColorRow al final de bodyData
      bodyData.push(totalesColorRow);
  
      // Generar la tabla con todos los datos del cuerpo y los estilos definidos fuera del bucle
      doc.autoTable({
        head: [["Tallas", ...colores, "Total"]],
        body: bodyData, // Utilizar el conjunto de datos del cuerpo
        startY: 45,
        styles: {
          fontSize: 10,
          cellPadding: 0.7,
          valign: "middle",
          halign: "center",
        }, // Estilos generales de las celdas
        headStyles: {
          fillColor: [41, 128, 186],
          textColor: [255, 255, 255],
          fontStyle: "bold",
          halign: "center",
        }, // Estilos para el encabezado
      });
  
      doc.autoTable({
        head: [
          [
            "Proveedor",
            "Articulo",
            "Color",
            "Precio",
            "Factura",
            "Cantidad",
            "Consumo",
            "Piezas",
            "Sobrante",
            "Rollos",
            "Total Rollos"
          ],
        ],
        body: dataPDF,
        startY: doc.autoTable.previous.finalY + 13,
        foot: [[]],
        showFoot: "lastPage",
      });
  
      window.open(doc.output("bloburl"), "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  }
  

  async function PDFTelasDetalles(numero, fecha, pedido, id, total_piezas, cliente, articulo, patronista) {
    await axios
      .get(`${URL_INVENTARIOS_TELAS}/ordenCorteDetalle/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosSurtido = response.data;
        let arrayTabla = allArticulosSurtido
          .map((a) => {
            if(a.cantidad > 0){
            return {
              id: a._id,
              articulo: a.articulo,
              proveedor: a.proveedor,
              factura: a.factura,
              cantidad: (a.cantidad).toFixed(2),
              cantidadSobrante: a.cantidadSobrante,
              unidad: a.unidad,
              idTela: a.idTela,
              telaNombre: a.articulo,
              colorNombre: a.colorNombre,
              idColor: a.idColor,
              cantidadString: a.cant
            }
          }
          })
          .filter(function (el) {
            return el != null;
          });
                let dataFinal = Object.values(arrayTabla);

                const data = dataFinal.map((a) => {
                  return [
                    a.id,
                    a.telaNombre,
                    a.colorNombre,
                    new Intl.NumberFormat("en-US").format(a.cantidad) + " " + a.unidad,
                  ];
                });

                const dataPDF = Object.values(data);
                const dataPDFLimpia = dataPDF.filter(function (el) {
                  return el != null;
                });
                const doc = new jsPDF("landscape");
                var img = new Image();
                img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
                doc.addImage(img, "png", 230, 30, 45, 15);
                var img2 = new Image();
                img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
                doc.addImage(img2, "png", 120, 7, 60, 25);
                doc.text(`Salida Tela OC # ${numero}`, 20, 20);
                doc.setFontSize(12);
                doc.text(`Fecha OC: ${fecha}`, 20, 25);
                doc.text(`Cantidad: ${(totalCantidadMt).toFixed(2)} Mts ${(totalCantidadKg.toFixed(2))} Kgs`, 20, 30);
                doc.text(`Observaciones: ${observacionesTelasPDF}`, 20, 35);
                doc.text(`Articulo: ${articulo}`, 20, 40);

                doc.text(`Pedido: ${pedido}`, 180, 20);
                doc.text(`Cliente: ${cliente}`, 180, 25);
                doc.text(`Patronista: ${patronista}`, 180, 30);
                doc.text(`Piezas: ${(total_piezas)}`, 180, 35);
                doc.autoTable({
                  head: [
                    [
                      "id",
                      "Tela",
                      "Color",
                      "Cantidad",
                    ],
                  ],
                  body: dataPDFLimpia,
                  startY: 60,
                  showFoot: "lastPage",
                });

                // doc.save(`Nota-${numero}.pdf`);
        window.open(doc.output("bloburl"), "_blank");
            //   }
            // });
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function BuscaRollo(id, event) {
    if (event.target.value.length >= 24) {
      if (inventarios.filter((e) => e._id == event.target.value)
      .length > 0)  {
      if (
        (inputFields.filter((e) => e.idInventarioTelas == event.target.value)
          .length > 0) 
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El rollo ya fue escaneado!",
          showConfirmButton: false,
        });
        const newInputFields = inputFields.map((i) => {
          if (id === i.id) {
            i.idInventarioTelas = "";
          }
          return i;
        });
        setInputFields(newInputFields);
      } else {
        inventarios.map((a) => {
          if (a._id == event.target.value) {
            let idInventarioTelas = a._id;
            let telas = a.telas[0].nombre;
            let idTela = a.telas[0]._id;
            let unidad = a.telas[0].unidad;
            let color = a.colores[0].name;
            let idColor = a.colores[0]._id;
            let cantidad = a.cantidad;
            let telaNombre = a.telas[0].nombre + " / " + a.colores[0].name
            
            handleChangeInputTelas(
              id,
              idInventarioTelas,
              telas,
              idTela,
              unidad,
              color,
              idColor,
              cantidad,
              telaNombre
            );
          }
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El rollo ya fue cortado previamente!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.idInventarioTelas = "";
        }
        return i;
      });
      setInputFields(newInputFields);
    }
    } 
    // else {
    //   const newInputFields = inputFields.map((i) => {
    //     if (id === i.id) {
    //       i.idInventarioTelas = event.target.value;
    //     }
    //     return i;
    //   });
    //   setInputFields(newInputFields);
    // }
  }

  const handleChangeInputTelas = (
    id,
    idInventarioTelas,
    telas,
    idTela,
    unidad,
    color,
    idColor,
    cantidad,
    telaNombre
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.idInventarioTelas = idInventarioTelas;
        i.telas = telas;
        i.idTela = idTela;
        i.unidad = unidad;
        i.colores = color;
        i.idColor = idColor;
        i.cantidad = cantidad;
        i.telaNombre = telaNombre;
      }
      return i;
    });

    ActualizaTotalesSurt();
    setInputFields(newInputFields);
    handleAddFields();
  };

  function ActualizaTotalesSurt() {
    let mts = inputFields.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = inputFields.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);

    let agrupado = inputFields.reduce(function (groups, item) {
      const val = item["idArticulo"] + item["idColor"] + item["idTela"];
      groups[val] = groups[val] || {
        idArticulo: item.idArticulo,
        idColor: item.idColor,
        idTela: item.idTela,
        cantidad: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].telas = item.telas;
      groups[val].idTela = item.idTela;
      groups[val].idArticulo = item.idArticulo;
      groups[val].unidad = item.unidad;
      groups[val].idColor = item.idColor;
      return groups;
    }, []);

    let CA = Object.values(agrupado);
    setCantidadAgrupado(CA);
    let tr = 0;
    inputFields.map((a) => {
      if (a.idArticulo != "") {
        return (tr = tr + 1);
      }
    });
    setRollosTotales(tr);
  }

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];

    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        idInventarioTelas: "",
        telas: "",
        idTela: "",
        unidad: "",
        colores: "",
        idColor: "",
        cantidad: 0,
        idArticulo: ultimo.idArticulo,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let mts = values.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = values.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);
  };

  const handleChangeInputArticulo = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.idArticulo = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  const saveTelas = async (event) => {
    event.preventDefault();
    if (fechaTelas != "" && observacionesTelas != "") {
      setValidaBoton(false);
      toggleProgreso();

      let totalRollos = inputFields.length + cantidadAgrupado.length;
      try {
        await axios
          .post(
            URL_TELAS_ORDENES_CORTE,
            {
              fecha: fechaTelas,
              ordenesCorte: selectedOrdenCorte,
              observaciones: observacionesTelas,
              totalMetros,
              totalKilos,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {
            inputFields.map((a) => {
              if (a.idTela != "") {
                axios
                  .patch(
                    `${URL_INVENTARIOS_TELAS}/ordenesCorte/${a.idInventarioTelas}`,
                    {
                      ordenesCorte: selectedOrdenCorte,
                      produccion: "Si",
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalRollos = totalRollos - 1;
                    if (totalRollos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Telas Ordenes Corte",
                            detalle: `Ordenes Corte ${selectedOrdenCorteNumero}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  });
              } else {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Telas Ordenes Corte",
                        detalle: `Ordenes Corte ${selectedOrdenCorteNumero}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              }
            });

            // Pegamos a Apartados Acumulado

            cantidadAgrupado.map((w) => {
              axios
                .post(
                  `${URL_TELAS_ORDENES_CORTES_ACUMULADOS}`,
                  {
                    telasOrdenesCorte: data.data._id,
                    articulos: w.idArticulo,
                    telas: w.idTela,
                    colores: w.idColor,
                    cantidad: w.cantidad,
                    ordenesCorte: selectedOrdenCorte,
                    fecha: fechaTelas
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Telas Ordenes Corte",
                          detalle: `Ordenes Corte ${selectedOrdenCorteNumero}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            });

            // Terminamos de post a apartados acumulado
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
    }
  };

  const saveTelasConsumo = async (event) => {
    event.preventDefault();
    if (fechaTelas != "" && observacionesTelas != "") {
      setValidaBoton(false);
      toggleProgreso();

      let totalRollos = inputFieldsAnteriorConsumo.length
      try {
      
        inputFieldsAnteriorConsumo.map((w) => {
              axios
                .patch(
                  `${URL_TELAS_ORDENES_CORTES_ACUMULADOS}/${w.id}`,
                  {
                    consumo: w.consumo,
                    piezas: w.piezas,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Agregar Consumo y Piezas",
                          detalle: `Ordenes Corte ${selectedOrdenCorteNumero}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            });

      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
    }
  };

  function cortado(id) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se marcará como cortado!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Marcar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_ORDENES_CORTE}/${id}`,
            {
              cortado: "Si",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            const newComments = comments.map((i) => {
              if (id === i._id) {
                i.cortado = "Si";
              }
              return i;
            });
            setComments(newComments);

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se Cerró",
              showConfirmButton: false,
              timer: 1000,
            });

            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function noCortado(id) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se marcará como no cortado!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Marcar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_ORDENES_CORTE}/${id}`,
            {
              cortado: "No",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            const newComments = comments.map((i) => {
              if (id === i._id) {
                i.cortado = "No";
              }
              return i;
            });
            setComments(newComments);

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se Abrió",
              showConfirmButton: false,
              timer: 1000,
            });

            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  async function jalaEdit(id, numero){
    setIdEdit(id)

    await axios
    .get(`${URL_ARTICULOS_ORDENES_CORTE}/orden/${id}`, {
        headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
    }).then((response) => {
      let allArticulos = response.data;
      let array = allArticulos.map((a) => {
        return {
          _id: a._id,
          cantidad: a.cantidad,
          cortado: a.cortado,
          pendiente_cortar: a.pendiente_cortar,
          articulo: a.articulosPedido[0].articulos[0].codigo,
          idArticulo: a.articulosPedido[0].articulos[0]._id,
          color: a.articulosPedido[0].colores[0].name,
          idColor: a.articulosPedido[0].colores[0]._id,
          talla: a.articulosPedido[0].tallas[0].name,
          idTalla: a.articulosPedido[0].tallas[0]._id,
        };
      })
      let data = Object.values(array);

      setArticulosEdit(data);

      let totalMts = data.map((c) => parseFloat(c.cantidad));
      let TM = totalMts.reduce((t, total, index) => t + total, 0);
      setTotalPiezas(TM);
      toggleEdit()
    }).catch((err) => {
        console.log(err);
    })

  }
  
  const handleChangeInputCantidadEdit = (id, event) => {
    const newArticulosEdit = articulosEdit.map((i) => {
      if (id === i._id) {
        i.cantidad = parseFloat(event.target.value);
      }
      return i;
    });
    setArticulosEdit(newArticulosEdit);

    ActualizaTotales();
  };

  function ActualizaTotales() {
    let totalMts = articulosEdit.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalPiezas(TM);
  }

 async function saveEdit() {
   let tot = articulosEdit.length;
   await axios
     .patch(
       `${URL_ORDENES_CORTE}/${idEdit}`,
       {
         cantidad: total_piezas,
       },
       {
         headers: {
           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
         },
       }
     )
     .then(() => {
       articulosEdit.map((a) => {
         axios
           .patch(
             `${URL_ARTICULOS_ORDENES_CORTE}/${a._id}`,
             {
               cantidad: a.cantidad,
             },
             {
               headers: {
                 Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
               },
             }
           )
           .then(() => {
             tot = tot - 1;
             if (tot == 0) {
               Swal.fire("Good job!", "Creado con exito", "success");
               setTimeout(() => {
                 window.location.reload();
               }, 1000);
             }
           })
           .catch((error) => {
             console.log(error);
           });
       });
     })
     .catch((err) => {
       console.log(err);
     });
 }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_produccion ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.menu_produccion ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/OrdenesCorteCreate"
                >
                  Nueva Orden
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nueva Orden
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuProduccion"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Ordenes de Corte</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Ordenes de Corte</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedCliente}
                  onChange={(e) => {
                    setSelectedCliente(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {clientes
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArticulo}
                  onChange={(e) => {
                    setSelectedArticulo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {articulos
                    .sort((a, b) =>
                      a.codigoCliente > b.codigoCliente ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>
                          Cod: {a.codigoCliente} / Ref: {a.codigo} / Desc:{" "}
                          {a.descripcion}
                        </option>
                      );
                    })}
                </Input>
              </td>
            </tr>

            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr style={ a.is_active== "No" ? { backgroundColor: "#ed8c8c" }: a.pedidoEditado =="Si" ? { backgroundColor: "#ffff00" } : undefined}>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.cliente}</td>
                      <td>{a.articulo}</td>
                      <td>{a.observacionesPedido}</td>
                      <td>{a.fechaCancelacion}</td>
                      <td>{a.total_piezas}</td>
                      <td>{a.pedido}</td>
                      <td>
                        {a.is_active == "Si" ? (
                        <>
                          <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            onClick={(e) =>
                              PDFOC(
                                a.numero,
                                a.fecha,
                                a.pedido,
                                a.idPedidos,
                                a.articulo,
                                a.idArticulo,
                                a.total_piezas,
                                a.observaciones,
                                a._id,
                                a.cliente,
                                a.patronista
                              )
                            }
                          >
                            <i class="far fa-file-pdf"></i>
                          </Button>
                          <Button
                            color="info"
                            id="Editar"
                            size="sm"
                            onClick={(e) => requisicion(a._id, a.numero)}
                          >
                            <i class="fas fa-tag"></i>
                          </Button>
                          <Button
                            color="info"
                            id="Editar"
                            size="sm"
                            onClick={(e) => jalaEdit(a._id, a.numero)}
                          >
                            <i class="fas fa-cart-plus"></i>
                          </Button>
                          {a.cortado == "No" ? (
                            <span>
                              <Button
                                color="danger"
                                id="Cerrar"
                                onClick={(e) => cortado(a._id)}
                                size="sm"
                              >
                                <i class="fas fa-cut"></i>
                              </Button>
                            </span>
                          ) : (
                            <span>
                              <Button
                                color="primary"
                                onClick={(e) => noCortado(a._id)}
                                size="sm"
                              >
                                <i class="fas fa-cut"></i>
                              </Button>
                            </span>
                          )}
                           <BajaMultiple
                              idStatus={a._id}
                              is_active={a.is_active}
                              URL_BAJA={process.env.REACT_APP_URL_ORDENES_CORTE}
                              hijo={`${process.env.REACT_APP_URL_ARTICULOS_ORDENES_CORTE}/baja`}
                            />
                        </>
                        ) : (
                          <BajaMultiple
                             idStatus={a._id}
                             is_active={a.is_active}
                             URL_BAJA={process.env.REACT_APP_URL_ORDENES_CORTE}
                             hijo={`${process.env.REACT_APP_URL_ARTICULOS_ORDENES_CORTE}/baja`}
                           />)}
                      </td>
                      <td>
                        {a.is_active == "Si" ? (
                          <>
                           <Button
                          color="info"
                          id="Telas"
                          size="sm"
                          onClick={(e) => telasCortesConsumo(a._id, a.numero)}
                        >
                          <i class="fas fa-ruler-combined"></i>
                        </Button>
                           <Button
                          color="info"
                          id="Telas"
                          size="sm"
                          onClick={(e) => telasCortes(a._id, a.numero)}
                        >
                          <i class="fas fa-cart-plus"></i>
                        </Button>
                        <Button
                          color="success"
                          id="TelasDev"
                          size="sm"
                          onClick={(e) => telasCortesDev(a._id, a.numero)}
                        >
                          <i class="fas fa-undo-alt"></i>
                        </Button>
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          onClick={(e) =>
                            PDFTelas(a.numero, a.fecha, a.pedido, a._id, a.total_piezas, a.cliente, a.articulo, a.patronista, a.idPedido )
                          }
                        >
                          <i class="far fa-file-pdf"></i>
                        </Button>

                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          onClick={(e) =>
                            PDFTelasDetalles(a.numero, a.fecha, a.pedido, a._id, a.total_piezas, a.cliente, a.articulo, a.patronista )
                          }
                        >
                          <i class="fas fa-tape"></i>
                        </Button>
                          </>
                        ): undefined}
                       
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            Este proceso puede tardar varios minutos.
            <br />
            Por favor no cierre ni refresque su navegador.
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal size="xxl" isOpen={modalTelas} toggle={toggleTelas}>
        <ModalHeader toggle={toggleTelas}>
          <h4>
            Entrega de Tela de la Orden de Corte {selectedOrdenCorteNumero}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={saveTelas}>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fechaTelas}
                  required
                  onChange={(e) => {
                    setFechaTelas(e.target.value);
                  }}
                />
              </Col>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observacionesTelas}
                  required
                  onChange={(e) => {
                    setObservacionesTelas(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
            {inputFieldsAnterior != "" ? (
              <>
                <Label>Tela Asignada</Label>
                <Row>
                  <Col md={2}>
                    <Label className="mr-sm-2">Articulo</Label>
                  </Col>
                  <Col md={3}>
                    <Label className="mr-sm-2">Tela</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Color</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Unidad</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cantidad</Label>
                  </Col>
                </Row>

                {inputFieldsAnterior.map((inputFieldAnt) => (
                  <div key={inputFieldAnt.id}>
                    <Row>
                      <Col md={2}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="text"
                          value={inputFieldAnt.articulo}
                          disabled
                        />
                      </Col>
                      <Col md={3}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="text"
                          value={inputFieldAnt.telaNombre}
                          disabled
                        />
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="text"
                          value={inputFieldAnt.color}
                          disabled
                        />
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="text"
                          value={inputFieldAnt.unidad}
                          disabled
                        />
                      </Col>

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="text"
                          value={inputFieldAnt.cantidad}
                          disabled
                        />
                      </Col>
                    </Row>
                  </div>
                ))}
              </>
            ) : undefined}
            <br />

            <Label>Asignar Tela</Label>
            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Articulo</Label>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Codigo</Label>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Tela</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Unidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="select"
                      value={inputField.idArticulo}
                      onChange={(event) => {
                        handleChangeInputArticulo(inputField.id, event);
                      }}
                      tabindex="-1"
                    >
                      <option value="">Selecciona un Articulo</option>
                      {articulosUnicos
                        .sort((a, b) =>
                          a.articuloNombre > b.articuloNombre ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a.idArticulo}>
                              {a.articuloNombre}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="idInventarioTelas"
                      type="string"
                      value={inputField.idInventarioTelas}
                      onChange={(event) => {
                        BuscaRollo(inputField.id, event);
                      }}
                    ></Input>
                  </Col>
                      <Col md={3}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="text"
                          value={inputField.telaNombre}
                          disabled
                        />
                      </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="unidad"
                      type="string"
                      value={inputField.unidad}
                      disabled
                    ></Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="string"
                      value={inputField.cantidad}
                      disabled
                    ></Input>
                  </Col>

                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES {new Intl.NumberFormat("en-US").format(totalKilos)}{" "}
                  kg. / {new Intl.NumberFormat("en-US").format(totalMetros)}{" "}
                  mts. / {rollosTotales} Rollos
                </h4>
              </Col>
            </Row>
            <br />

            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>

      <Modal size="xxl" isOpen={modalTelasConsumo} toggle={toggleTelasConsumo}>
        <ModalHeader toggle={toggleTelasConsumo}>
          <h4>
            Agregar Consumo {selectedOrdenCorteNumero}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={saveTelasConsumo}>
                <Label>Tela Asignada</Label>
                <Row>
                  <Col md={2}>
                    <Label className="mr-sm-2">Articulo</Label>
                  </Col>
                  <Col md={3}>
                    <Label className="mr-sm-2">Tela</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Color</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Unidad</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cantidad</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Consumo</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Piezas</Label>
                  </Col>
                </Row>

                {inputFieldsAnteriorConsumo.map((inputFieldAnt) => (
                  <div key={inputFieldAnt.id}>
                    <Row>
                      <Col md={2}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="text"
                          value={inputFieldAnt.articulo}
                          disabled
                        />
                      </Col>
                      <Col md={3}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="text"
                          value={inputFieldAnt.telaNombre}
                          disabled
                        />
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="text"
                          value={inputFieldAnt.color}
                          disabled
                        />
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="text"
                          value={inputFieldAnt.unidad}
                          disabled
                        />
                      </Col>

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="text"
                          value={inputFieldAnt.cantidad}
                          disabled
                        />
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="consumo"
                          type="number"
                          value={inputFieldAnt.consumo}
                          onChange={(event) =>
                            handleChangeInput(inputFieldAnt.id, event)
                          }
                        />
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="piezas"
                          type="number"
                          value={inputFieldAnt.piezas}
                          
                          onChange={(event) =>
                            handleChangeInput(inputFieldAnt.id, event)
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                ))}

            

            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>

      <Modal size="xxl" isOpen={modalTelasDev} toggle={toggleTelasDev}>
        <ModalHeader toggle={toggleTelasDev}>
          <h4>
            Devolucion de Tela de la Orden de Corte{" "}
            {selectedOrdenCorteNumeroDev}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={saveTelasDev}>
            {/* <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fechaTelas}
                  required
                  onChange={(e) => {
                    setFechaTelas(e.target.value);
                  }}
                />
              </Col>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observacionesTelas}
                  required
                  onChange={(e) => {
                    setObservacionesTelas(e.target.value);
                  }}
                />
              </Col>
            </Row> */}
            {/* <br />

            <Label>Devolver Tela</Label> */}
            <Row>
              {/* <Col md={2}>
                <Label className="mr-sm-2">Articulo</Label>
              </Col> */}
              <Col md={3}>
                <Label className="mr-sm-2">Codigo</Label>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Tela</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Unidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad Original</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Cant a Devolver</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  {/* <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="select"
                      value={inputField.idArticulo}
                      onChange={(event) => {
                        handleChangeInputArticulo(inputField.id, event);
                      }}
                      tabindex="-1"
                    >
                      <option value="">Selecciona un Articulo</option>
                      {articulosUnicos
                        .sort((a, b) =>
                          a.articuloNombre > b.articuloNombre ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a.idArticulo}>
                              {a.articuloNombre}
                            </option>
                          );
                        })}
                    </Input>
                  </Col> */}
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="idInventarioTelas"
                      type="string"
                      value={inputField.idInventarioTelas}
                      onChange={(event) => {
                        BuscaRolloDev(inputField.id, event);
                      }}
                    ></Input>
                  </Col>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="tela"
                      type="text"
                      value={inputField.telas}
                      disabled
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="unidad"
                      type="string"
                      value={inputField.unidad}
                      disabled
                    ></Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="string"
                      value={inputField.cantidadOriginal}
                      disabled
                    ></Input>
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="string"
                      value={inputField.cantidad}
                      required
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    ></Input>
                  </Col>

                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES {new Intl.NumberFormat("en-US").format(totalKilos)}{" "}
                  kg. / {new Intl.NumberFormat("en-US").format(totalMetros)}{" "}
                  mts. / {rollosTotalesDev} Rollos
                </h4>
              </Col>
            </Row>
            <br />

            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>

      <Modal size="lg" isOpen={modalExplosion} toggle={toggleExplosion}>
        <ModalHeader toggle={toggleExplosion}>
          <h4>Explosion</h4>
        </ModalHeader>
        <ModalBody>
          <Table size="sm" striped borderless className="table-responsive-xl">
            <tr>
              <th>Articulo</th>
              <th>Material</th>
              <th>Color</th>
              <th>Cantidad</th>
              <th>Total Cant</th>
            </tr>
            {explosion.map((a) => (
              <tr>
                <td>{a.articulosCodigo}</td>
                <td>{a.material}</td>
                <td>{a.coloresName}</td>
                <td>{a.cantidad}</td>
                <td>{a.cantidadTotal}</td>
              </tr>
            ))}
          </Table>
        </ModalBody>
      </Modal>

      <Modal size="xxl" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar</h4>
        </ModalHeader>
        <ModalBody>
         
        <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Articulo</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Color</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Talla</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
            </Row>
            {articulosEdit.map((inputField) => (
              <div key={inputField._id}>
                <Row>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="articulo"
                      type="string"
                      value={inputField.articulo}
                      disabled
                    ></Input>
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="color"
                      type="string"
                      value={inputField.color}
                      disabled
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="talla"
                      type="string"
                      value={inputField.talla}
                      disabled
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="tela"
                      type="string"
                      value={inputField.cantidad}
                      onChange={(event) =>
                        handleChangeInputCantidadEdit(
                          inputField._id,
                          event
                        )
                      }
                    ></Input>
                  </Col>
                </Row>
               
              </div>
            ))}
             <Row>
              <Col md={5}></Col>
              <Col md={1}>
                <Label>Total Piezas</Label>
              </Col>
                  <Col md={1}>
                    <Label>{total_piezas}</Label>
                    </Col>
                </Row>
                {validaBoton ? (
              <Button type="submit" className="btn btn-success" onClick={saveEdit}>
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
        </ModalBody>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoOrdenesCorte;
