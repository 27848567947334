import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultiple from "../../StatusMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";

import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function ListadoPedidos() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_EXPLOSION = process.env.REACT_APP_URL_EXPLOSION;
  const URL_EXPLOSION_GLOBAL = process.env.REACT_APP_URL_EXPLOSION_GLOBAL;
  const URL_TELAS = process.env.REACT_APP_URL_TELAS;
  const URL_HABILITACION = process.env.REACT_APP_URL_HABILITACION;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_TALLAS = process.env.REACT_APP_URL_TALLAS;
  const URL_CATALOGO_PROCESOS = process.env.REACT_APP_URL_CATALOGO_PROCESOS;
  const URL_EMPRESAS = process.env.REACT_APP_URL_EMPRESAS;
  const URL_COMPRADORES = process.env.REACT_APP_URL_COMPRADORES;
  // const URL_MARCAS = process.env.REACT_APP_URL_MARCAS;
  const URL_COLECCIONES = process.env.REACT_APP_URL_COLECCIONES;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 90);

  let valida = new Date();
  valida.setDate(valida.getDate() + 8);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");
  let validador = moment(valida).format("YYYY-MM-DD");

  const [pedidos, setPedidos] = useState([]);

  const [articulos, setArticulos] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [empresas, setEmpresas] = useState([]);
  const [selectedEmpresa, setSelectedEmpresa] = useState("");
  const [compradores, setCompradores] = useState([]);
  const [selectedComprador, setSelectedComprador] = useState("");
  // const [marcas, setMarcas] = useState([]);
  // const [selectedMarca, setSelectedMarca] = useState("");

  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [telas, setTelas] = useState([]);
  const [habilitacion, setHabilitacion] = useState([]);

  const [selectedArticulo, setSelectedArticulo] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [fecha, setFecha] = useState("");
  const [fecha_cancelacion, setFechaCancelacion] = useState("");
  const [fecha_confirmacion, setFechaConfirmacion] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState("");
  const [total_kilos, setTotalUnidaKilos] = useState("");
  const [total_metros, setTotalUnidaMetros] = useState("");
  const [numero_pedido, setNumeroPedido] = useState("");

  const [idEdit, setIdEdit] = useState("");
  const [selectedClienteEdit, setSelectedClienteEdit] = useState("");
  const [selectedEmpresaEdit, setSelectedEmpresaEdit] = useState("");
  const [selectedCompradorEdit, setSelectedCompradorEdit] = useState("");
  const [selectedColaboradorEdit, setSelectedColaboradorEdit] = useState("");
  const [selectedPatronistaEdit, setSelectedPatronistaEdit] = useState("");
  const [selectedMuestristaEdit, setSelectedMuestristaEdit] = useState("");
  const [selectedPloterEdit, setSelectedPloterEdit] = useState("");
  const [idArticuloEdit, setIdArticuloEdit] = useState("");
  const [pedidoCliente, setPedidoCliente] = useState("");

  const [articulosPedido, setArticulosPedido] = useState([]);
  const [idArticuloPedido, setIdArticuloPedido] = useState("");
  const [modalEditArticulos, setModalEditArticulos] = useState(false);
  const toggleEditArticulos = () => setModalEditArticulos(!modalEditArticulos);
  const [total_general_edit, setTotalGeneralEdit] = useState(0);
  const [total_kilos_edit, setTotalKilosEdit] = useState(0);
  const [total_metros_edit, setTotalMetrosEdit] = useState(0);
  const [total_cantidad_edit, setTotalCantidadEdit] = useState(0);

  const [codigo, setCodigo] = useState("");
  const [descripcion, setDescripcion] = useState("");

  const [colores, setColores] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");
  const [tallas, setTallas] = useState([]);
  const [selectedTalla, setSelectedTalla] = useState("");
  const [catalogoProcesos, setCatalogoProcesos] = useState([]);

  const [modalExplosion, setModalExplosion] = useState(false);
  const toggleExplosion = () => setModalExplosion(!modalExplosion);
  const [explosionExistentes, setExplosionExistentes] = useState([]);
  const [codigoExplosion, setCodigoExplosion] = useState("");
  const [idExplosion, setIdExplosion] = useState("");
  const [descripcionExplosion, setDescripcionExplosion] = useState("");

  const [modalExplosionGlobal, setModalExplosionGlobal] = useState(false);
  const toggleExplosionGlobal = () =>
    setModalExplosionGlobal(!modalExplosionGlobal);
  const [explosionGlobalExistente, setExplosionGlobalExistente] = useState([]);

  const [idColoresArticulos, setIdColoresArticulos] = useState("");
  const [modalColores, setModalColores] = useState(false);
  const toggleColores = () => setModalColores(!modalColores);
  const [arrayColores, setArrayColores] = useState([]);

  const [idTallasArticulos, setIdTallasArticulos] = useState("");
  const [modalTallas, setModalTallas] = useState(false);
  const toggleTallas = () => setModalTallas(!modalTallas);
  const [arrayTallas, setArrayTallas] = useState([]);

  const [idCatalogoProcesosArticulos, setIdCatalogoProcesosArticulos] =
    useState("");
  const [modalCatalogoProcesos, setModalCatalogoProcesos] = useState(false);
  const toggleCatalogoProcesos = () =>
    setModalCatalogoProcesos(!modalCatalogoProcesos);
  const [arrayCatalogoProcesos, setArrayCatalogoProcesos] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(true);

  const [idEditArticuloEdit, setIdEditArticuloEdit] = useState("");
  const [codigoEdit, setCodigoEdit] = useState("");
  const [codigoClienteEdit, setCodigoClienteEdit] = useState("");
  const [nombreEdit, setNombreEdit] = useState("");
  const [descripcionEdit, setDescripcionEdit] = useState("");
  const [precioEtiquetaEdit, setPrecioEtiquetaEdit] = useState("");
  const [ventaEdit, setVentaEdit] = useState("");
  const [observacionesEdit, setObservacionesEdit] = useState("");
  const [observacionesProduccionEdit, setObservacionesProduccionEdit] =
    useState("");
  // const [selectedMarcaEdit, setSelectedMarcaEdit] = useState("");
  const [selectedColeccionEdit, setSelectedColeccionEdit] = useState("");
  const [colecciones, setColecciones] = useState([]);
  const [modalEditInfoArticulo, setModalEditInfoArticulo] = useState(false);
  const toggleEditInfoArticulo = () =>
    setModalEditInfoArticulo(!modalEditInfoArticulo);
  const [selectedPendFechaConf, setSelectedPendFechaConf] = useState("");

  const [modalPrecosteo, setModalPrecosteo] = useState(false);
  const togglePrecosteo = () => setModalPrecosteo(!modalPrecosteo);
  const [precosteoTelas, setPrecosteoTelas] = useState([]);
  const [precosteoHab, setPrecosteoHab] = useState([]);

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      resurtido: "",
      unidad: 0,
      colores: "",
      arrayColoresArticulo: [],
      tallas: "",
      arrayTallasArticulo: [],
      cantidad: 0,
      surtido: 0,
      precio: 0,
      precioEtiqueta: 0,
      descuento: 0,
      total: 0,
      codigoBarras: 0,
    },
  ]);
  const [inputFieldsTela, setInputFieldsTela] = useState([
    {
      id: uuidv4(),
      idTela: "",
      cantidad: 0,
      observaciones: "NA",
    },
  ]);

  const [inputFieldsHab, setInputFieldsHab] = useState([
    {
      id: uuidv4(),
      idHab: "",
      cantidad: 0,
      observaciones: "NA",
    },
  ]);

  const [inputFieldsTelaGlobal, setInputFieldsTelaGlobal] = useState([
    {
      id: uuidv4(),
      idTela: "",
      idColor: "",
      cantidad: 0,
      cantidadPrenda: 0,
      observaciones: "NA",
    },
  ]);

  const [explosion, setExplosion] = useState([]);
  const [explosionHab, setExplosionHab] = useState([]);
  const [modalExplosiones, setModalExplosiones] = useState(false);
  const toggleExplosiones = () => setModalExplosiones(!modalExplosiones);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [activo, setActivo] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedEstado, setSelectedEstado] = useState("");
  const [selectedEstadoEdit, setSelectedEstadoEdit] = useState("");
  const [fechaEstadoEdit, setFechaEstadoEdit] = useState("");

  const [modalStatus, setModalStatus] = useState(false);
  const toggleStatus = () => setModalStatus(!modalStatus);

  const [modalAutorizaciones, setModalAutorizaciones] = useState(false);

  const [file, setFile] = useState();
  const [photo, setPhoto] = useState();
  const URL_FILEPOST = process.env.REACT_APP_URL_UPPROFILE;

  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(`${URL_PEDIDOS}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPedidos = response.data;
        // Array para pagination
        let arrayTabla = allPedidos
          .sort((a, b) => (a.idPedido > b.idPedido ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idPedido,
              activo: a.is_active,
              fecha: a.fecha,
              articulos:
                "Cod: " +
                a.articulos[0].codigoCliente +
                " / Ref: " +
                a.articulos[0].codigo +
                " / Desc: " +
                a.articulos[0].descripcion,
              modelo: a.articulos[0].codigo,
              idArticulo: a.articulos[0]._id,
              codigo: a.articulos[0].codigo,
              codigoCliente: a.articulos[0].codigoCliente,
              descripcion: a.articulos[0].descripcion,
              subDepartamentos: a.articulos[0].subDepartamentos[0].name,
              venta: a.articulos[0].venta,
              precioEtiqueta: a.articulos[0].precioEtiqueta,
              colores: a.articulos[0].colores,
              tallas: a.articulos[0].tallas,
              catalogoProcesos: a.articulos[0].catalogoProcesos,
              observacionesArticulo: a.articulos[0].observaciones,
              observacionesProduccion: a.articulos[0].observacionesProduccion,
              patronista:
                a.articulos[0].patronista[0].nombre +
                " " +
                a.articulos[0].patronista[0].apellido,
              idPatronista: a.articulos[0].patronista[0]._id,
              muestrista:
                a.articulos[0].muestrista[0].nombre +
                " " +
                a.articulos[0].muestrista[0].apellido,
              idMuestrista: a.articulos[0].muestrista[0]._id,
              ploter:
                a.articulos[0].ploter[0].nombre +
                " " +
                a.articulos[0].ploter[0].apellido,
              idPloter: a.articulos[0].ploter[0]._id,
              colaboradores:
                a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
              idColaboradores: a.colaboradores[0]._id,
              fecha_cancelacion: a.fecha_cancelacion,
              fecha_confirmacion: a.fecha_confirmacion,
              observaciones: a.observaciones,
              total_general: a.total_general,
              precioPrenda:
                parseFloat(a.total_general) / parseFloat(a.total_piezas),
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              total_kilos: a.total_kilos,
              total_metros: a.total_metros,
              status: a.status,
              estado: a.estado,
              fechaEstado: a.fechaEstado,
              autorizado: a.autorizado,
              cantidad: parseFloat(a.total_piezas),
              surtido: parseFloat(a.surtido),
              pendiente_surtir: parseFloat(a.pendiente_surtir),
              codigos: a.codigos,
              ficha: a.ficha,
              auditoria: a.auditoria,
              muestraFit: a.muestraFit,
              muestraConfirmacion: a.muestraConfirmacion,
              etiquetas: a.etiquetas,
              fechaEstCodigos: a.fechaEstCodigos,
              fechaRealCodigos: a.fechaRealCodigos,
              fechaEstFicha: a.fechaEstFicha,
              fechaRealFicha: a.fechaRealFicha,
              fechaEstAuditoria: a.fechaEstAuditoria,
              fechaRealAuditoria: a.fechaRealAuditoria,
              fechaEstMuestraFit: a.fechaEstMuestraFit,
              fechaRealMuestraFit: a.fechaRealMuestraFit,
              fechaEstMuestraConfirmacion: a.fechaEstMuestraConfirmacion,
              fechaRealMuestraConfirmacion: a.fechaRealMuestraConfirmacion,
              fechaEstEtiquetas: a.fechaEstEtiquetas,
              fechaRealEtiquetas: a.fechaRealEtiquetas,
              autorizacionCostos: a.autorizacionCostos,
              autorizacionFit: a.autorizacionFit,
              autorizacionDiseno: a.autorizacionDiseno,
              autorizacionCalidad: a.autorizacionCalidad,
              autorizacionPloterDiseno: a.autorizacionPloterDiseno,
              autorizacionPloter: a.autorizacionPloter,
              autorizacionSurtir: a.autorizacionSurtir,
              statusTela: a.statusTela,
              statusHabilitacion: a.statusHabilitacion,
              empresas: a.empresas[0].name,
              idEmpresas: a.empresas[0]._id,
              compradores: a.compradores[0].name,
              idCompradores: a.compradores[0]._id,
              // marcas: a.articulos[0].marcas[0].name,
              // idMarcas: a.articulos[0].marcas[0]._id,
              coleccion: a.articulos[0].colecciones[0].name,
              idColeccion: a.articulos[0].colecciones[0]._id,

              fotoArticulo: a.articulos[0].fotoArticulo,
              fotoDiseno: a.articulos[0].fotoDiseno,
              pedidoCliente: a.pedidoCliente,

              resurtido: a.resurtido,
              pendFechaConf: a.pendFechaConf,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);

        let agrupado = data.reduce(function (groups, item) {
          const val = item["_id"];
          groups[val] = groups[val] || {
            _id: item._id,
            surtido: 0,
            pendiente_surtir: 0,
            cantidad: 0,
          };
          groups[val].surtido += item.surtido;
          groups[val].pendiente_surtir += item.pendiente_surtir;
          groups[val].cantidad += item.cantidad;
          groups[val]._id = item._id;
          groups[val].numero = item.numero;
          groups[val].activo = item.activo;
          groups[val].fecha = item.fecha;
          groups[val].articulos = item.articulos;
          groups[val].modelo = item.modelo;
          groups[val].idArticulo = item.idArticulo;
          groups[val].codigo = item.codigo;
          groups[val].descripcion = item.descripcion;
          groups[val].subDepartamentos = item.subDepartamentos;
          groups[val].colores = item.colores;
          groups[val].tallas = item.tallas;
          groups[val].catalogoProcesos = item.catalogoProcesos;
          groups[val].patronista = item.patronista;
          groups[val].idPatronista = item.idPatronista;
          groups[val].muestrista = item.muestrista;
          groups[val].idMuestrista = item.idMuestrista;
          groups[val].ploter = item.ploter;
          groups[val].idPloter = item.idPloter;
          groups[val].colaboradores = item.colaboradores;
          groups[val].idColaboradores = item.idColaboradores;
          groups[val].fecha_cancelacion = item.fecha_cancelacion;
          groups[val].fecha_confirmacion = item.fecha_confirmacion;
          groups[val].total_general = item.total_general;
          groups[val].precioPrenda = item.precioPrenda;
          groups[val].clientes = item.clientes;
          groups[val].idClientes = item.idClientes;
          groups[val].status = item.status;
          groups[val].estado = item.estado;
          groups[val].fechaEstado = item.fechaEstado;
          groups[val].autorizado = item.autorizado;
          groups[val].observaciones = item.observaciones;
          groups[val].codigos = item.codigos;
          groups[val].ficha = item.ficha;
          groups[val].auditoria = item.auditoria;
          groups[val].muestraFit = item.muestraFit;
          groups[val].muestraConfirmacion = item.muestraConfirmacion;
          groups[val].etiquetas = item.etiquetas;
          groups[val].fechaEstCodigos = item.fechaEstCodigos;
          groups[val].fechaRealCodigos = item.fechaRealCodigos;
          groups[val].fechaEstFicha = item.fechaEstFicha;
          groups[val].fechaRealFicha = item.fechaRealFicha;
          groups[val].fechaEstAuditoria = item.fechaEstAuditoria;
          groups[val].fechaRealAuditoria = item.fechaRealAuditoria;
          groups[val].fechaEstMuestraFit = item.fechaEstMuestraFit;
          groups[val].fechaRealMuestraFit = item.fechaRealMuestraFit;
          groups[val].fechaEstMuestraConfirmacion =
            item.fechaEstMuestraConfirmacion;
          groups[val].fechaRealMuestraConfirmacion =
            item.fechaRealMuestraConfirmacion;
          groups[val].fechaEstEtiquetas = item.fechaEstEtiquetas;
          groups[val].fechaRealEtiquetas = item.fechaRealEtiquetas;
          groups[val].autorizacionCostos = item.autorizacionCostos;
          groups[val].autorizacionFit = item.autorizacionFit;
          groups[val].autorizacionDiseno = item.autorizacionDiseno;
          groups[val].autorizacionCalidad = item.autorizacionCalidad;
          groups[val].autorizacionPloter = item.autorizacionPloter;
          groups[val].autorizacionPloterDiseno = item.autorizacionPloterDiseno;
          groups[val].autorizacionSurtir = item.autorizacionSurtir;
          groups[val].statusTela = item.statusTela;
          groups[val].statusHabilitacion = item.statusHabilitacion;
          groups[val].empresas = item.empresas;
          groups[val].idEmpresas = item.idEmpresas;
          groups[val].compradores = item.compradores;
          groups[val].idCompradores = item.idCompradores;
          // groups[val].marcas = item.marcas;
          // groups[val].idMarcas = item.idMarcas;
          groups[val].codigoCliente = item.codigoCliente;
          groups[val].venta = item.venta;
          groups[val].precioEtiqueta = item.precioEtiqueta;
          groups[val].observacionesArticulo = item.observacionesArticulo;
          groups[val].observacionesProduccion = item.observacionesProduccion;
          groups[val].coleccion = item.coleccion;
          groups[val].idColeccion = item.idColeccion;

          groups[val].fotoArticulo = item.fotoArticulo;
          groups[val].fotoDiseno = item.fotoDiseno;
          groups[val].pedidoCliente = item.pedidoCliente;
          groups[val].resurtido = item.resurtido;
          groups[val].pendFechaConf = item.pendFechaConf;
          return groups;
        }, []);

        let dataFinal = Object.values(agrupado);

        setPedidos(dataFinal);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLECCIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColecciones = res.data;
        setColecciones(allColecciones);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_EMPRESAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allEmpresas = response.data;
        setEmpresas(allEmpresas);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COMPRADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCompradores = response.data;
        setCompradores(allCompradores);
      })
      .catch((err) => {
        console.log(err);
      });

    // axios
    // .get(URL_MARCAS, {
    //   headers: {
    //     Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //   },
    // })
    // .then((response) => {
    //   let allMarcas = response.data;
    //   setMarcas(allMarcas);
    // })
    // .catch((err) => {
    //   console.log(err);
    // });

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_TELAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTelas = res.data;
        setTelas(allTelas);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_HABILITACION, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allHabilitacion = res.data;
        setHabilitacion(allHabilitacion);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColores = response.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_TALLAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allTallas = response.data;
        setTallas(allTallas);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CATALOGO_PROCESOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCatalogoProcesos = response.data;
        setCatalogoProcesos(allCatalogoProcesos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);

  const ListaPedidos = comments.sort((a, b) =>
    a.idPedido > b.idPedido ? 1 : -1
  );

  function PDFTabla() {
    const data = ListaPedidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.colaboradores,
          a.articulo,
          a.fecha_cancelacion,
          a.fecha_confirmacion,
          a.clientes,
          a.total_kilos,
          a.total_metros,
          "$" + new Intl.NumberFormat("en-US").format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Pedidos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Articulo",
          "Fecha Cancelacion",
          "Fecha Confirmacion",
          "Cliente",
          "Kilos",
          "Metros",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    // doc.save(`Pedidos.pdf`);
    window.open(doc.output("bloburl"), "_blank");
  }

  function excel() {
    const dataExcel = ListaPedidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return {
          Activo: a.activo,
          Numero: a.numero,
          Fecha: a.fecha,
          Vendedor: a.colaboradores,
          Modelo: a.modelo,
          Articulo: a.articulos,
          Cancelacion: a.fecha_cancelacion,
          Confirmacion: a.fecha_confirmacion,
          Cliente: a.clientes,
          Cantidad: a.cantidad,
          Total: a.total_general,
          PendFechaConf: a.pendFechaConf,
          Estado: a.estado,
          FechaEstado: a.fechaEstado
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoPedidos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoPedidos",
        sheetFilter: [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Modelo",
          "Articulo",
          "Cancelacion",
          "Confirmacion",
          "Cliente",
          "Cantidad",
          "Total",
          "PendFechaConf",
          "Estado",
          "FechaEstado",
        ],
        sheetHeader: [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Modelo",
          "Articulo",
          "Cancelacion",
          "Confirmacion",
          "Cliente",
          "Cantidad",
          "Total",
          "PendFechaConf",
          "Estado",
          "FechaEstado",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaPedidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.colaboradores,
          a.articulos,
          a.fecha_cancelacion,
          a.fecha_confirmacion,
          a.clientes,
          a.total_kilos,
          a.total_metros,
          "$" + new Intl.NumberFormat("en-US").format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Ordenes de Compra`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Articulo",
          "Fecha Cancelacion",
          "Fecha Confirmacion",
          "Cliente",
          "Kilos",
          "Metros",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Pedidos",
          email: mailTo,
          fileName: "ListadoPedidos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Pedidos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          // window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(
    fecha,
    idColaboradores,
    fecha_cancelacion,
    fecha_confirmacion,
    idClientes,
    idEmpresas,
    idComprador,
    total_kilos,
    total_metros,
    total_general,
    observaciones,
    numero_pedido,
    idEdit,
    idMuestrista,
    idPatronista,
    idPloter,
    idArticulo,
    pedidoCliente,
    estadoEdit,
    fechaEstadoEdit
  ) {
    setFecha(fecha);
    setSelectedColaboradorEdit(idColaboradores);
    setFechaCancelacion(fecha_cancelacion);
    setFechaConfirmacion(fecha_confirmacion);
    setSelectedClienteEdit(idClientes);
    setSelectedEmpresaEdit(idEmpresas);
    setSelectedCompradorEdit(idComprador);
    setTotalUnidaKilos(total_kilos);
    setTotalUnidaMetros(total_metros);
    setTotalGeneral(total_general);
    setObservaciones(observaciones);
    setNumeroPedido(numero_pedido);
    setIdEdit(idEdit);
    setSelectedPatronistaEdit(idPatronista);
    setSelectedMuestristaEdit(idMuestrista);
    setSelectedPloterEdit(idPloter);
    setIdArticuloEdit(idArticulo);
    setPedidoCliente(pedidoCliente);
    setSelectedEstadoEdit(estadoEdit);
    setFechaEstadoEdit(fechaEstadoEdit);
    toggleEdit();
  }

  async function PDFOC(
    fecha,
    colaboradores,
    compradores,
    fecha_cancelacion,
    fecha_confirmacion,
    idClientes,
    clientes,
    pedidoCliente,
    total_kilos,
    total_metros,
    total_general,
    observaciones,
    numero,
    idPDFOC,
    cantidad,
    precio,
    total,
    estado,
    articulos
  ) {
    axios
      .get(`${URL_ARTICULOS_PEDIDO}/pedidos/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data;
        let colorTallaCantidad = {};

        allArticulosPedido.forEach((a) => {
          const color = a.colores[0].name;
          const talla = a.tallas[0].name;
          const cantidad = a.is_active === 'No' ? 0 : a.cantidad;

          if (!colorTallaCantidad[talla]) {
            colorTallaCantidad[talla] = {};
          }
          if (!colorTallaCantidad[talla][color]) {
            colorTallaCantidad[talla][color] = 0;
          }
          colorTallaCantidad[talla][color] += cantidad;
        });

        const tallas = Object.keys(colorTallaCantidad).sort();
        const colores = [
          ...new Set(allArticulosPedido.map((a) => a.colores[0].name)),
        ].sort();

        axios
          .get(`${URL_EXPLOSION_GLOBAL}Pedido/${idPDFOC}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((response) => {
            let allExplosion = response.data;
            let arrayTablaEx = allExplosion
              .map((a) => {
                if (a.cantidad > 0) {
                  return {
                    id: a._id,
                    tela: a.telas[0].nombre,
                    color: a.colores[0].name,
                    cantidad: a.cantidad,
                    unidad: a.telas[0].unidad,
                    cantidadPrenda: a.cantidadPrenda,
                    observaciones: a.observaciones,
                  };
                }
              })
              .filter(function (el) {
                return el != null;
              });

            let dataFinalEx = Object.values(arrayTablaEx);

            const dataEx = dataFinalEx.map((a) => {
              return [
                a.tela,
                a.color,
                new Intl.NumberFormat("en-US").format(a.cantidad) +
                  " " +
                  a.unidad,
                new Intl.NumberFormat("en-US").format(a.cantidadPrenda),
                a.observaciones,
              ];
            });
            const dataPDFEX = Object.values(dataEx);
            const dataPDFLimpiaEX = dataPDFEX.filter(function (el) {
              return el != null;
            });

            const doc = new jsPDF("landscape");
            var img = new Image();
            img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
            doc.addImage(img, "png", 230, 30, 45, 15);
            var img2 = new Image();
            img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
            doc.addImage(img2, "png", 120, 15, 60, 25);
            doc.text(`Pedido # ${numero}`, 20, 20);
            doc.setFontSize(12);
            doc.text(`Fecha ${fecha}`, 20, 25);
            doc.text(`Cliente ${clientes}`, 20, 30);
            doc.text(`Pedido Cliente ${pedidoCliente}`, 20, 35);
            doc.text(`Vendedor ${colaboradores}`, 20, 40);
            doc.text(`Comprador ${compradores}`, 20, 45);
            doc.text(
              `Cancelacion ${fecha_cancelacion}, Confirmacion ${fecha_confirmacion}`,
              20,
              50
            );
            doc.setFont(undefined, "bold");
            doc.text(`Observaciones ${observaciones}`, 20, 55);
            doc.text(`Articulo ${articulos}`, 20, 60);

            // Crear una variable para almacenar todas las filas de datos
            const bodyData = [];

            // Iterar sobre las tallas
            tallas.forEach((talla) => {
              let rowData = [talla];
              let totalTalla = 0;

              // Iterar sobre los colores
              colores.forEach((color) => {
                const cantidad = colorTallaCantidad[talla][color] || 0;
                rowData.push(cantidad);
                totalTalla += cantidad;
              });

              // Agregar el total por talla al final de la fila
              rowData.push(totalTalla);

              // Agregar la fila de datos al conjunto de datos del cuerpo
              bodyData.push(rowData);
            });

            // Calcular y agregar totales por color en una fila al final de la tabla
            const totalesColorRow = ["Total"];
            colores.forEach((color) => {
              let totalColor = 0;
              tallas.forEach((talla) => {
                totalColor += colorTallaCantidad[talla][color] || 0;
              });
              totalesColorRow.push(totalColor);
            });

            // Agregar totalesColorRow al final de bodyData
            bodyData.push(totalesColorRow);

            // Generar la tabla con todos los datos del cuerpo y los estilos definidos fuera del bucle
            doc.autoTable({
              head: [["Tallas", ...colores, "Total"]],
              body: bodyData, // Utilizar el conjunto de datos del cuerpo
              startY: 65,
              styles: {
                fontSize: 10,
                cellPadding: 0.7,
                valign: "middle",
                halign: "center",
              }, // Estilos generales de las celdas
              headStyles: {
                fillColor: [41, 128, 186],
                textColor: [255, 255, 255],
                fontStyle: "bold",
                halign: "center",
              }, // Estilos para el encabezado
            });

            doc.text(
              `Cantidad: ${new Intl.NumberFormat("en-US").format(
                cantidad
              )} Precio: ${new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(precio)} Total: ${new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(total)}`,
              15,
              doc.autoTable.previous.finalY + 10
            );
            doc.text(`Tela`, 15, doc.autoTable.previous.finalY + 20);
            doc.autoTable({
              head: [
                [
                  "Tela",
                  "Color",
                  "Cantidad",
                  "Consumo Prenda",
                  "Observaciones",
                ],
              ],
              body: dataPDFLimpiaEX,
              startY: doc.autoTable.previous.finalY + 25,
            });
            window.open(doc.output("bloburl"), "_blank");
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function editPedidos(event) {
    event.preventDefault();
    const URL_PEDIDOS_EDIT = `${process.env.REACT_APP_URL_PEDIDOS}/${idEdit}`;
    if (file == null) {
      axios
        .patch(
          URL_PEDIDOS_EDIT,
          {
            fecha,
            colaboradores: selectedColaboradorEdit,
            fecha_cancelacion,
            fecha_confirmacion,
            clientes: selectedClienteEdit,
            empresas: selectedEmpresaEdit,
            compradores: selectedCompradorEdit,
            total_general,
            observaciones,
            pedidoCliente,
            estado: selectedEstadoEdit,
            fechaEstado: fechaEstadoEdit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          axios
            .patch(
              `${URL_ARTICULOS}/${idArticuloEdit}`,
              {
                patronista: selectedPatronistaEdit,
                muestrista: selectedMuestristaEdit,
                ploter: selectedPloterEdit,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )

            .then(() => {
              axios.post(
                URL_LOGS,
                {
                  tipo: "Editar Pedido",
                  detalle: `${numero_pedido} ${fecha}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              );

              toggleEdit();
              Swal.fire("Good job!", "Actualizado con exito", "success");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } else {
      axios
        .patch(
          URL_PEDIDOS_EDIT,
          {
            fecha,
            colaboradores: selectedColaboradorEdit,
            fecha_cancelacion,
            fecha_confirmacion,
            clientes: selectedClienteEdit,
            empresas: selectedEmpresaEdit,
            empresas: selectedEmpresaEdit,
            total_general,
            observaciones,
            estado: selectedEstadoEdit,
            fechaEstado: fechaEstadoEdit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("articulo", idArticuloEdit);

          axios
            .post(URL_FILEPOST, formData, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then(() => {
              axios
                .patch(
                  `${URL_ARTICULOS}/${idArticuloEdit}`,
                  {
                    patronista: selectedPatronistaEdit,
                    muestrista: selectedMuestristaEdit,
                    ploter: selectedPloterEdit,
                    fotoArticulo: "Si",
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )

                .then(() => {
                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Editar Pedido",
                      detalle: `${numero_pedido} ${fecha}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );

                  toggleEdit();
                  Swal.fire("Good job!", "Actualizado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
        });
    }
  }

  const headers = [
    // { name: "Activo", field: "activo", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Resurtido", field: "resurtido", sortable: true },
    { name: "Fecha Cita Venta", field: "fecha", sortable: false },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Empresa", field: "empresas", sortable: true },
    { name: "Comprador", field: "compradores", sortable: true },
    { name: "Vendedor", field: "colaboradores", sortable: false },
    { name: "Articulo", field: "articulo", sortable: false },
    // { name: "Marca", field: "marcas", sortable: false },
    // { name: "Colores", field: "colores", sortable: false },
    { name: "Fecha Cancelacion", field: "fecha_cancelacion", sortable: true },
    { name: "Fecha Confirmacion", field: "fecha_confirmacion", sortable: true },
    { name: "Pend Fecha Conf", field: "pendFechaConf", sortable: false },
    { name: "Cantidad", field: "cantidad", sortable: true },
    { name: "Surtido", field: "surtido", sortable: true },
    { name: "Pendiente", field: "pendiente_surtir", sortable: true },
    { name: "Precio", field: "precioPrenda", sortable: true },
    { name: "Total", field: "total_general", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
    { name: "Info", field: "info", sortable: false },
    { name: "Editar", field: "editar", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          // comment.activo.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.resurtido.toLowerCase().includes(search.toLowerCase()) ||
          comment.empresas.toLowerCase().includes(search.toLowerCase()) ||
          comment.compradores.toLowerCase().includes(search.toLowerCase()) ||
          // comment.marcas.toLowerCase().includes(search.toLowerCase()) ||
          comment.colaboradores.toLowerCase().includes(search.toLowerCase()) ||
          comment.articulos.toLowerCase().includes(search.toLowerCase()) ||
          comment.fecha.toLowerCase().includes(search.toLowerCase()) ||
          comment.fecha_cancelacion
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.fecha_confirmacion
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.pendFechaConf.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search) ||
          comment.surtido.toString().includes(search) ||
          comment.pendiente_surtir.toString().includes(search) ||
          comment.precioPrenda.toString().includes(search) ||
          comment.total_general.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedEmpresa) {
      computedComments = computedComments.filter((e) =>
        e.idEmpresas.includes(selectedEmpresa)
      );
    }
    if (selectedComprador) {
      computedComments = computedComments.filter((e) =>
        e.idCompradores.includes(selectedComprador)
      );
    }
    // if (selectedMarca) {
    //   computedComments = computedComments.filter((e) =>
    //     e.idMarcas.includes(selectedMarca)
    //   );
    // }
    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idColaboradores.includes(selectedColaborador)
      );
    }

    if (selectedArticulo) {
      computedComments = computedComments.filter((e) =>
        e.idArticulo.includes(selectedArticulo)
      );
    }
    if (selectedStatus) {
      computedComments = computedComments.filter((e) =>
        e.status.includes(selectedStatus)
      );
    }
    if (selectedEstado) {
      computedComments = computedComments.filter((e) =>
        e.estado.includes(selectedEstado)
      );
    }
    if (selectedPendFechaConf) {
      computedComments = computedComments.filter((e) =>
        e.pendFechaConf.includes(selectedPendFechaConf)
      );
    }
    // if (activo) {
    //   computedComments = computedComments.filter((e) =>
    //     e.activo.includes(activo)
    //   );
    // }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cantidad" &&
      sorting.field != "surtido" &&
      sorting.field != "pendiente_surtir" &&
      sorting.field != "precioPrenda" &&
      sorting.field != "total_general" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "surtido" ||
        sorting.field == "pendiente_surtir" ||
        sorting.field == "precioPrenda" ||
        sorting.field == "total_general" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "surtido" ||
        sorting.field == "pendiente_surtir" ||
        sorting.field == "precioPrenda" ||
        sorting.field == "total_general" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedEmpresa,
    selectedComprador,
    // selectedMarca,
    selectedColaborador,
    selectedArticulo,
    // activo,
    selectedFechaInicio,
    selectedFechaFin,
    selectedStatus,
    selectedEstado,
    selectedPendFechaConf,
  ]);

  function EditArticulos(idOC, numero_pedido, idArticulo) {
    setIdArticuloPedido(idArticulo);
    setInputFields([
      {
        id: uuidv4(),
        articulos: "",
        resurtido: "",
        unidad: 0,
        colores: "",
        arrayColoresArticulo: [],
        tallas: "",
        arrayTallasArticulo: [],
        cantidad: 0,
        surtido: 0,
        precio: 0,
        precioEtiqueta: 0,
        descuento: 0,
        total: 0,
        codigoBarras: 0,
      },
    ]);
    setIdEdit(idOC);
    setNumeroPedido(numero_pedido);
    axios
      .get(`${URL_ARTICULOS_PEDIDO}/pedidos/${idOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = allArticulosPedido
          .map((a) => {
            return {
              id: a._id,
              activo: a.is_active,
              articulos: a.articulos[0]._id,
              unidad: a.articulos[0].unidad,
              colores: a.colores[0]._id,
              tallas: a.tallas[0]._id,
              arrayColoresArticulo: a.articulos[0].colores,
              arrayTallasArticulo: a.articulos[0].tallas,
              cantidad: a.cantidad,
              surtido: a.surtido,
              precio: a.precio,
              precioEtiqueta: a.precioEtiqueta,
              descuento: a.descuento,
              total: a.total,
              pendiente_surtir: a.pendiente_surtir,
              resurtido: a.resurtido,
              codigoBarras: parseFloat(a.codigoBarras),
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setArticulosPedido(dataFinal);

        let totalMts = dataFinal.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalCantidadEdit(TM);
        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneralEdit(TG);
      })
      .catch((err) => {
        console.log(err);
      });
    toggleEditArticulos();
  }

  const handleChangeInputExistentes = (id, event) => {
    const newArticulosPedido = articulosPedido.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(
          2
        );
        i.pendiente_surtir = i.cantidad - i.surtido;
      }
      return i;
    });
    setArticulosPedido(newArticulosPedido);
    ActualizaTotales();
  };

  function BuscaArticuloExistentes(id, event) {
    articulos.map((a) => {
      if (a._id == event.target.value) {
        let idArticulo = a._id;
        let unidadArticulo = a.unidad;
        let coloresArticulo = a.colores;
        let tallasArticulo = a.tallas;
        handleChangeInputExistentesArticulo(
          id,
          idArticulo,
          unidadArticulo,
          coloresArticulo,
          tallasArticulo
        );
      }
    });
  }

  const handleChangeInputExistentesArticulo = (
    id,
    idArticulo,
    unidadArticulo,
    coloresArticulo,
    tallasArticulo
  ) => {
    const newArticulosPedido = articulosPedido.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.unidad = unidadArticulo;
        i.arrayColoresArticulo = coloresArticulo;
        i.arrayTallasArticulo = tallasArticulo;
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(
          2
        );
        i.pendiente_surtir = i.cantidad - i.surtido;
      }
      return i;
    });
    setArticulosPedido(newArticulosPedido);
    ActualizaTotales();
  };

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        resurtido: ultimo.resurtido,
        articulos: ultimo.articulos,
        unidad: ultimo.unidad,
        colores: "",
        arrayColoresArticulo: ultimo.arrayColoresArticulo,
        tallas: "",
        arrayTallasArticulo: ultimo.arrayTallasArticulo,
        cantidad: 0,
        precio: ultimo.precio,
        precioEtiqueta: ultimo.precioEtiqueta,
        descuento: ultimo.descuento,
        total: 0,
        codigoBarras: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let cantidadExistentes = articulosPedido.map((c) => parseFloat(c.cantidad));
    let cantidadNuevos = values.map((c) => parseFloat(c.cantidad));
    let cantidad = cantidadExistentes.concat(cantidadNuevos);
    let TC = cantidad.reduce((t, total, index) => t + total, 0);
    setTotalCantidadEdit(TC);

    let importesExistentes = articulosPedido.map((c) => parseFloat(c.total));
    let importesNuevos = values.map((c) => parseFloat(c.total));
    let importes = importesExistentes.concat(importesNuevos);
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneralEdit(TG);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(
          2
        );
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    articulos.map((a) => {
      if (a._id == event.target.value) {
        let idArticulo = a._id;
        let unidadArticulo = a.unidad;
        let coloresArticulo = a.colores;
        let tallasArticulo = a.tallas;
        handleChangeInputArticulo(
          id,
          idArticulo,
          unidadArticulo,
          coloresArticulo,
          tallasArticulo
        );
      }
    });
  }

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    unidadArticulo,
    coloresArticulo,
    tallasArticulo
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.unidad = unidadArticulo;
        i.arrayColoresArticulo = coloresArticulo;
        i.arrayTallasArticulo = tallasArticulo;
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(
          2
        );
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let cantidadExistentes = articulosPedido.map((c) => parseFloat(c.cantidad));
    let cantidadNuevos = inputFields.map((c) => parseFloat(c.cantidad));
    let cantidad = cantidadExistentes.concat(cantidadNuevos);
    let TC = cantidad.reduce((t, total, index) => t + total, 0);
    setTotalCantidadEdit(TC);

    let importesExistentes = articulosPedido.map((c) => parseFloat(c.total));
    let importesNuevos = inputFields.map((c) => parseFloat(c.total));
    let importes = importesExistentes.concat(importesNuevos);
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneralEdit(TG);
  }

  const savePedido = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();
    let totalPedido = inputFields.length + articulosPedido.length;
    const URL_PEDIDOS_EDIT = `${process.env.REACT_APP_URL_PEDIDOS}/${idEdit}`;
    try {
      await axios
        .patch(
          URL_PEDIDOS_EDIT,
          {
            total_piezas: total_cantidad_edit,
            pendiente_surtir: total_cantidad_edit,
            total_general: total_general_edit,
            editado: "Si",
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          inputFields.map((a) => {
            if (a.articulos != "" && a.colores != "" && a.cantidad != 0) {
              axios
                .post(
                  URL_ARTICULOS_PEDIDO,
                  {
                    pedidos: idEdit,
                    articulos: a.articulos,
                    colores: a.colores,
                    tallas: a.tallas,
                    cantidad: parseFloat(a.cantidad),
                    precio: parseFloat(a.precio),
                    descuento: parseFloat(a.descuento),
                    total: parseFloat(a.total),
                    surtido: 0,
                    pendiente_surtir: parseFloat(a.cantidad),
                    pendienteCortar: parseFloat(a.cantidad),
                    codigoBarras: parseFloat(a.codigoBarras),
                    resurtido: "No",
                    cortado: 0,
                    precioEtiqueta: a.precioEtiqueta,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalPedido = totalPedido - 1;
                  if (totalPedido == 0) {
                    axios.post(
                      URL_LOGS,
                      {
                        tipo: "Editar Pedido",
                        detalle: `${numero_pedido}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    );
                    Swal.fire("Good job!", "Creado con exito", `success`);
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }
                });
            } else {
              totalPedido = totalPedido - 1;
              if (totalPedido == 0) {
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Editar Pedido",
                    detalle: `${numero_pedido}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                Swal.fire("Good job!", "Creado con exito", `success`);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            }
          });
          articulosPedido.map((a) => {
            axios
              .patch(
                `${URL_ARTICULOS_PEDIDO}/${a.id}`,
                {
                  articulos: a.articulos,
                  colores: a.colores,
                  tallas: a.tallas,
                  cantidad: parseFloat(a.cantidad),
                  precio: parseFloat(a.precio),
                  precioEtiqueta: parseFloat(a.precioEtiqueta),
                  descuento: parseFloat(a.descuento),
                  total: parseFloat(a.total),
                  pendiente_surtir: parseFloat(a.pendiente_surtir),
                  codigoBarras: parseFloat(a.codigoBarras),
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalPedido = totalPedido - 1;
                if (totalPedido == 0) {
                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Editar Pedido",
                      detalle: `${numero_pedido}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );
                  Swal.fire("Good job!", "Creado con exito", `success`);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              });
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  function abierto(id) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se Abrirá!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Abrir!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_PEDIDOS}/${id}`,
            {
              status: "Abierto",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            axios
              .patch(
                `${URL_ARTICULOS_PEDIDO}/baja/${id}`,
                {
                  is_active: "Si",
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                const newComments = comments.map((i) => {
                  if (id === i._id) {
                    i.status = "Abierto";
                  }
                  return i;
                });
                setComments(newComments);

                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Se Abrió",
                  showConfirmButton: false,
                  timer: 2000,
                });
                // setTimeout(() => {
                //   // window.location.reload();
                // }, 1000);
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function cerrar(id) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se Cerrará!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cerrar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_PEDIDOS}/${id}`,
            {
              status: "Cerrado",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            axios
              .patch(
                `${URL_ARTICULOS_PEDIDO}/baja/${id}`,
                {
                  is_active: "No",
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                const newComments = comments.map((i) => {
                  if (id === i._id) {
                    i.status = "Cerrado";
                  }
                  return i;
                });
                setComments(newComments);

                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Se Cerró",
                  showConfirmButton: false,
                  timer: 2000,
                });

                // setTimeout(() => {
                //   // window.location.reload();
                // }, 1000);
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  async function requisicion(id, numero) {
    setExplosion([]);
    setExplosionHab([]);
    toggleProgreso();
    setIdEdit(id);
    setNumeroPedido(numero);
    axios
      .get(`${URL_ARTICULOS_PEDIDO}/pedidos/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then(async (response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = await allArticulosPedido
          .map((a) => {
            return {
              id: a._id,
              activo: a.is_active,
              articulos: a.articulos[0]._id,
              articulosCodigo: a.articulos[0].codigo,
              colores: a.colores[0]._id,
              coloresName: a.colores[0].name,
              tallas: a.tallas[0]._id,
              tallasName: a.tallas[0].name,
              cantidad: a.cantidad,
              surtido: a.surtido,
              pendiente_surtir: a.pendiente_surtir,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);

        let agrupado = data.reduce(function (groups, item) {
          const val = item["articulos"];
          groups[val] = groups[val] || {
            articulos: item.articulos,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].articulos = item.articulos;
          groups[val].articulosCodigo = item.articulosCodigo;
          return groups;
        }, []);

        let dataFinal = Object.values(agrupado);

        let agrupadoColores = data.reduce(function (groups, item) {
          const val = item["articulos"] + item["colores"];
          groups[val] = groups[val] || {
            articulos: item.articulos,
            colores: item.colores,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].articulos = item.articulos;
          groups[val].articulosCodigo = item.articulosCodigo;
          groups[val].colores = item.colores;
          groups[val].coloresName = item.coloresName;
          return groups;
        }, []);

        let dataFinalColores = Object.values(agrupadoColores);

        let temp2 = [];
        let temp3 = [];

        let exp = await dataFinal.map((a) => {
          axios
            .get(`${URL_EXPLOSION}Articulo/${a.articulos}`, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then((response) => {
              let allExplosion = response.data;
              let arrayExp = allExplosion
                .map((b) => {
                  if (b.habilitacion != "") {
                    temp2.push({
                      // articulos: a.articulos,
                      // articulosCodigo: a.articulosCodigo,
                      material: b.habilitacion[0].nombre,
                      cantidad: b.cantidad * a.cantidad,
                      colores: "NA",
                      coloresName: "NA",
                      tipo: "Habilitacion",
                      observaciones: b.observaciones,
                    });
                  }
                })
                .filter(function (el) {
                  return el != null;
                });
            })
            .catch((err) => {
              console.log(err);
            });
        });

        let expColores = await dataFinalColores.map((a) => {
          axios
            .get(`${URL_EXPLOSION}Articulo/${a.articulos}`, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then((response) => {
              let allExplosion = response.data;
              let arrayExp = allExplosion
                .map((b) => {
                  if (b.telas != "") {
                    temp3.push({
                      articulos: a.articulos,
                      articulosCodigo: a.articulosCodigo,
                      material:
                        b.telas[0].nombre +
                        " / " +
                        b.telas[0].tiposTelas[0].name,
                      cantidad: b.cantidad * a.cantidad,
                      colores: a.colores,
                      coloresName: a.coloresName,
                      tipo: "Tela",
                      observaciones: b.observaciones,
                    });
                  }
                })
                .filter(function (el) {
                  return el != null;
                });
            })
            .catch((err) => {
              console.log(err);
            });
        });
        setExplosion(temp3);
        setExplosionHab(temp2);
        setTimeout(() => {
          setModalProgreso(false);
          toggleExplosion();
        }, 3500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function requisicionGlobal(id, idArticulo, numero) {
    setInputFieldsTelaGlobal([
      {
        id: uuidv4(),
        idTela: "",
        idColor: "",
        cantidad: 0,
        cantidadPrenda: 0,
        observaciones: "NA",
      },
    ]);
    setExplosionGlobalExistente([]);
    setIdEdit(id);
    setIdExplosion(idArticulo);
    setNumeroPedido(numero);

    axios
      .get(`${URL_EXPLOSION_GLOBAL}Pedido/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allExplosion = response.data;
        let arrayExp = allExplosion
          .map((a) => {
            return {
              _id: a._id,
              idTela: a.telas[0]._id,
              tela: a.telas[0].nombre,
              idColor: a.colores[0]._id,
              color: a.colores[0].name,
              cantidad: a.cantidad,
              cantidadPrenda: a.cantidadPrenda,
              observaciones: a.observaciones,
            };
          })
          .filter(function (el) {
            return el != null;
          });
        setExplosionGlobalExistente(arrayExp);
      })
      .catch((err) => {
        console.log(err);
      });

    toggleExplosionGlobal();
  }

  const handleChangeInputExistenteGlobal = (id, event) => {
    const newExplosionGlobalExistentes = explosionGlobalExistente.map((i) => {
      if (id === i._id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setExplosionGlobalExistente(newExplosionGlobalExistentes);
  };

  const handleChangeInputTelaGlobal = (id, event) => {
    const newInputFieldsTela = inputFieldsTelaGlobal.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsTelaGlobal(newInputFieldsTela);
  };

  const handleRemoveFieldsTelasGlobal = (id) => {
    const values = [...inputFieldsTelaGlobal];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsTelaGlobal(values);
  };

  const handleAddFieldsTelasGlobal = () => {
    setInputFieldsTelaGlobal([
      ...inputFieldsTelaGlobal,
      {
        id: uuidv4(),
        idTela: "",
        idColor: "",
        cantidad: 0,
        cantidadPrenda: 0,
        observaciones: "NA",
      },
    ]);
  };

  function saveExplosionGlobal() {
    setValidaBoton(false);
    toggleProgreso();

    let totalExplosion =
      inputFieldsTelaGlobal.length + explosionGlobalExistente.length;

    inputFieldsTelaGlobal.map((a) => {
      if ((a.idTela != "") & (a.cantidad > 0)) {
        axios
          .post(
            URL_EXPLOSION_GLOBAL,
            {
              articulos: idExplosion,
              pedidos: idEdit,
              telas: a.idTela,
              colores: a.idColor,
              cantidad: a.cantidad,
              cantidadPrenda: a.cantidadPrenda,
              tipo: "Tela",
              observaciones: a.observaciones,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            totalExplosion = totalExplosion - 1;
            if (totalExplosion == 0) {
              axios
                .post(
                  URL_LOGS,
                  {
                    tipo: "Agregar Explosion",
                    detalle: `${idExplosion} ${a.idTela}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Actualizado con Exito",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  setTimeout(() => {
                    setModalExplosionGlobal(false);
                    setModalProgreso(false);
                    setValidaBoton(true);
                  }, 1000);
                });
            }
          });
      } else {
        totalExplosion = totalExplosion - 1;
      }
    });

    explosionGlobalExistente.map((a) => {
      axios
        .patch(
          `${URL_EXPLOSION_GLOBAL}/${a._id}`,
          {
            cantidad: a.cantidad,
            cantidadPrenda: a.cantidadPrenda,
            observaciones: a.observaciones,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          totalExplosion = totalExplosion - 1;
          if (totalExplosion == 0) {
            axios
              .post(
                URL_LOGS,
                {
                  tipo: "Editar Explosion",
                  detalle: `${idExplosion}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Actualizado con Exito",
                  showConfirmButton: false,
                  timer: 2000,
                });
                setTimeout(() => {
                  setModalExplosionGlobal(false);
                  setModalProgreso(false);
                  setValidaBoton(true);
                }, 1000);
              });
          }
        });
    });
  }

  function jalaFoto(idEdit) {
    setPhoto("");
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${idEdit}`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        if (data.data.version) {
          setPhoto(data.data.version);
        }
      })
      .catch((err) => console.log(err));

    setIdEdit(idEdit);
    toggleFoto();
  }

  async function coloresInfo(idArticulo, codigo, descripcion, coloresInfo) {
    setArrayColores([]);
    await axios
      .get(`${URL_ARTICULOS}/${idArticulo}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;

        let ids = allClientes.colores.map(function (e) {
          return e._id;
        });
        let coloresDany = Object.values(ids);
        setArrayColores(coloresDany);

        setIdColoresArticulos(idArticulo);
        setCodigo(codigo);
        setDescripcion(descripcion);

        toggleColores();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function coloresSeleccionados(checked, idColor) {
    if (checked) {
      arrayColores.push(idColor);
      console.log(arrayColores);
    } else {
      const index = arrayColores.indexOf(idColor);
      if (index > -1) {
        arrayColores.splice(index, 1);
        console.log(arrayColores);
      }
    }
  }

  function guardarColores() {
    const URL_ARTICULOS_COLORES = `${process.env.REACT_APP_URL_ARTICULOS}/${idColoresArticulos}`;
    axios
      .patch(
        URL_ARTICULOS_COLORES,
        {
          colores: arrayColores,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Colores Articulo",
            detalle: `${codigo}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Actualizado con Exito",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          setModalColores(false);
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function tallasInfo(idArticulo, codigo, descripcion, tallasInfo) {
    setArrayTallas([]);
    await axios
      .get(`${URL_ARTICULOS}/${idArticulo}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;

        let ids = allClientes.tallas.map(function (e) {
          return e._id;
        });
        let tallasDany = Object.values(ids);
        setArrayTallas(tallasDany);

        setIdTallasArticulos(idArticulo);
        setCodigo(codigo);
        setDescripcion(descripcion);

        toggleTallas();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function tallasSeleccionados(checked, idColor) {
    if (checked) {
      arrayTallas.push(idColor);
      console.log(arrayTallas);
    } else {
      const index = arrayTallas.indexOf(idColor);
      if (index > -1) {
        arrayTallas.splice(index, 1);
        console.log(arrayTallas);
      }
    }
  }

  function guardarTallas() {
    const URL_ARTICULOS_TALLAS = `${process.env.REACT_APP_URL_ARTICULOS}/${idTallasArticulos}`;
    axios
      .patch(
        URL_ARTICULOS_TALLAS,
        {
          tallas: arrayTallas,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Tallas Articulo",
            detalle: `${codigo}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Actualizado con Exito",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          setModalTallas(false);
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function catalogoProcesosInfo(
    idArticulo,
    codigo,
    descripcion,
    catalogoProcesosInfo
  ) {
    setArrayCatalogoProcesos([]);

    await axios
      .get(`${URL_ARTICULOS}/${idArticulo}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;

        let ids = allClientes.catalogoProcesos.map(function (e) {
          return e._id;
        });
        let catalogoProcesosDany = Object.values(ids);
        setArrayCatalogoProcesos(catalogoProcesosDany);

        setIdCatalogoProcesosArticulos(idArticulo);
        setCodigo(codigo);
        setDescripcion(descripcion);

        toggleCatalogoProcesos();
      })
      .catch((err) => {
        console.log(err);
      });

    // let ids = catalogoProcesosInfo.map(function (e) {
    //   return e._id;
    // });
    // let catalogoProcesosDany = Object.values(ids);

    // setIdCatalogoProcesosArticulos(idCatalogoProcesos);
    // setCodigo(codigo);
    // setDescripcion(descripcion);
    // setArrayCatalogoProcesos(catalogoProcesosDany);
    // toggleCatalogoProcesos();
  }

  function catalogoProcesosSeleccionados(checked, idCatalogoProceso) {
    if (checked) {
      arrayCatalogoProcesos.push(idCatalogoProceso);
      console.log(arrayCatalogoProcesos);
    } else {
      const index = arrayCatalogoProcesos.indexOf(idCatalogoProceso);
      if (index > -1) {
        arrayCatalogoProcesos.splice(index, 1);
        console.log(arrayCatalogoProcesos);
      }
    }
  }

  function guardarCatalogoProcesos() {
    const URL_ARTICULOS_CATALOGO_PROCESOS = `${process.env.REACT_APP_URL_ARTICULOS}/${idCatalogoProcesosArticulos}`;
    axios
      .patch(
        URL_ARTICULOS_CATALOGO_PROCESOS,
        {
          catalogoProcesos: arrayCatalogoProcesos,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "CatalogoProcesos Articulo",
            detalle: `${codigo}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Actualizado con Exito",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          setModalCatalogoProcesos(false);
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function explosiones(id, codigo, descripcion) {
    setIdExplosion(id);
    setCodigoExplosion(codigo);
    setDescripcionExplosion(descripcion);

    setInputFieldsHab([
      {
        id: uuidv4(),
        idHab: "",
        cantidad: 0,
        observaciones: "NA",
      },
    ]);
    setInputFieldsTela([
      {
        id: uuidv4(),
        idTela: "",
        cantidad: 0,
        observaciones: "NA",
      },
    ]);

    axios
      .get(`${URL_EXPLOSION}Articulo/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allExplosionExistentes = res.data;
        setExplosionExistentes(allExplosionExistentes);
      })
      .catch((err) => {
        console.log(err);
      });

    toggleExplosiones();
  }

  const handleRemoveFieldsTelas = (id) => {
    const values = [...inputFieldsTela];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsTela(values);
  };

  const handleRemoveFieldsHab = (id) => {
    const values = [...inputFieldsHab];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsHab(values);
  };

  const handleAddFieldsTelas = () => {
    setInputFieldsTela([
      ...inputFieldsTela,
      {
        id: uuidv4(),
        idTela: "",
        cantidad: 0,
        observaciones: "NA",
      },
    ]);
  };

  const handleAddFieldsHab = () => {
    setInputFieldsHab([
      ...inputFieldsHab,
      {
        id: uuidv4(),
        idHab: "",
        cantidad: 0,
        observaciones: "NA",
      },
    ]);
  };

  const handleChangeInputTela = (id, event) => {
    const newInputFieldsTela = inputFieldsTela.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsTela(newInputFieldsTela);
  };

  const handleChangeInputHab = (id, event) => {
    const newInputFieldsHab = inputFieldsHab.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsHab(newInputFieldsHab);
  };

  const handleChangeInputExistente = (id, event) => {
    const newExplosionExistentes = explosionExistentes.map((i) => {
      if (id === i._id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setExplosionExistentes(newExplosionExistentes);
  };

  function saveExplosion() {
    setValidaBoton(false);
    toggleProgreso();

    let totalExplosion =
      inputFieldsHab.length +
      inputFieldsTela.length +
      explosionExistentes.length;

    inputFieldsTela.map((a) => {
      if ((a.idTela != "") & (a.cantidad > 0)) {
        axios
          .post(
            URL_EXPLOSION,
            {
              articulos: idExplosion,
              telas: a.idTela,
              cantidad: a.cantidad,
              tipo: "Tela",
              observaciones: a.observaciones,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            totalExplosion = totalExplosion - 1;
            if (totalExplosion == 0) {
              axios
                .post(
                  URL_LOGS,
                  {
                    tipo: "Agregar Explosion",
                    detalle: `${idExplosion} ${a.idTela}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Actualizado con Exito",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  setTimeout(() => {
                    setModalExplosiones(false);
                    setModalProgreso(false);
                    setValidaBoton(true);
                  }, 1000);
                });
            }
          });
      } else {
        totalExplosion = totalExplosion - 1;
      }
    });

    inputFieldsHab.map((a) => {
      if ((a.idHab != "") & (a.cantidad > 0)) {
        axios
          .post(
            URL_EXPLOSION,
            {
              articulos: idExplosion,
              habilitacion: a.idHab,
              cantidad: a.cantidad,
              tipo: "Habilitacion",
              observaciones: a.observaciones,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            totalExplosion = totalExplosion - 1;
            if (totalExplosion == 0) {
              axios
                .post(
                  URL_LOGS,
                  {
                    tipo: "Agregar Explosion",
                    detalle: `${idExplosion} ${a.idHab}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Actualizado con Exito",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  setTimeout(() => {
                    setModalExplosiones(false);
                    setModalProgreso(false);
                    setValidaBoton(true);
                  }, 1000);
                });
            }
          });
      } else {
        totalExplosion = totalExplosion - 1;
      }
    });

    explosionExistentes.map((a) => {
      axios
        .patch(
          `${URL_EXPLOSION}/${a._id}`,
          {
            cantidad: a.cantidad,
            observaciones: a.observaciones,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          totalExplosion = totalExplosion - 1;
          if (totalExplosion == 0) {
            axios
              .post(
                URL_LOGS,
                {
                  tipo: "Editar Explosion",
                  detalle: `${idExplosion}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Actualizado con Exito",
                  showConfirmButton: false,
                  timer: 2000,
                });
                setTimeout(() => {
                  setModalExplosiones(false);
                  setModalProgreso(false);
                  setValidaBoton(true);
                }, 1000);
              });
          }
        });
    });
  }

  function jalaInfoArticulo(
    codigo,
    codigoCliente,
    descripcion,
    venta,
    precioEtiqueta,
    observaciones,
    observacionesProduccion,
    // idMarca,
    idColeccion,
    idEdit
  ) {
    // console.log(idMarca)
    setCodigoEdit(codigo);
    setCodigoClienteEdit(codigoCliente);
    setDescripcionEdit(descripcion);
    setVentaEdit(venta);
    setPrecioEtiquetaEdit(precioEtiqueta);
    setObservacionesEdit(observaciones);
    setObservacionesProduccionEdit(observacionesProduccion);
    // setSelectedMarcaEdit(idMarca);
    setSelectedColeccionEdit(idColeccion);
    setIdEditArticuloEdit(idEdit);
    toggleEditInfoArticulo();
  }

  function editArticulos(event) {
    event.preventDefault();
    const URL_ARTICULOS_EDIT = `${process.env.REACT_APP_URL_ARTICULOS}/${idEditArticuloEdit}`;
    axios
      .patch(
        URL_ARTICULOS_EDIT,
        {
          codigo: codigoEdit,
          codigoCliente: codigoClienteEdit,
          descripcion: descripcionEdit,
          venta: ventaEdit,
          precioEtiqueta: precioEtiquetaEdit,
          observaciones: observacionesEdit,
          observacionesProduccion: observacionesProduccionEdit,
          // marcas: selectedMarcaEdit,
          colecciones: selectedColeccionEdit,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Articulo",
            detalle: `${codigo}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );

        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }


  function desarrollo(){
    axios
      .get(
        `${URL_PEDIDOS}Desarrollo`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
}


async function precosteo(id, numero, idPedido, cantidadPedido) {
  setExplosion([]);
  toggleProgreso();
  setIdEdit(id);
  setNumeroPedido(numero);

  axios
  .get(`${URL_EXPLOSION_GLOBAL}Pedido/${idPedido}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allExplosion = response.data;
    let arrayExp = allExplosion
      .map((a) => {
          return{
            _id: a._id,
            idTela: a.telas[0]._id,
            tela: a.telas[0].nombre,
            idColor: a.colores[0]._id,
            color: a.colores[0].name,
            cantidad: a.cantidad,
            cantidadPrenda: a.cantidadPrenda,
            costo: a.telas[0].costo,
            total: a.telas[0].costo * a.cantidad,
            totalPrenda: (a.telas[0].costo * a.cantidad) / cantidadPedido
          };
      })
      .filter(function (el) {
        return el != null;
      });
      setPrecosteoTelas(arrayExp);
  })
  .catch((err) => {
    console.log(err);
  });

  axios
  .get(`${URL_EXPLOSION}Articulo/${id}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allExplosion = response.data;
    let arrayExpHab = allExplosion
      .map((a) => {
        if(a.tipo == "Habilitacion")
          return{
            _id: a._id,
            idHabilitacion: a.habilitacion[0]._id,
            habilitacion: a.habilitacion[0].nombre + " " + a.habilitacion[0].descripcion,
            cantidad: a.cantidad,
            costo: a.habilitacion[0].costo,
            total: (a.habilitacion[0].costo * a.cantidad) * cantidadPedido,
            totalPrenda: a.habilitacion[0].costo * a.cantidad
          };
      })
      .filter(function (el) {
        return el != null;
      });
      setPrecosteoHab(arrayExpHab);
  })
  .catch((err) => {
    console.log(err);
  });

      setTimeout(() => {
        setModalProgreso(false);
        togglePrecosteo();
      }, 3500);
}



let totalPrecosteoTela = 0
let totalPrecosteoHab = 0
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_pedidos ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.pedidos_create ? (
                <>
                  <Button
                    size="sm"
                    type="submit"
                    className="btn btn-success"
                    href="/ArticulosCreate"
                  >
                    Nuevo Pedido
                  </Button>

                  <Button
                    size="sm"
                    type="submit"
                    className="btn btn-success"
                    href="/PedidosCreate"
                  >
                    Pedido Resurtido
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    size="sm"
                    type="submit"
                    className="btn btn-success"
                    disabled
                  >
                    Nuevo Pedido
                  </Button>

                  <Button
                    size="sm"
                    type="submit"
                    className="btn btn-success"
                    disabled
                  >
                    Pedido Resurtido
                  </Button>
                </>
              )}
              <Button
                size="sm"
                href="/MenuVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                {/* <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={desarrollo}
                >
                  Desarrollo
                </Button> */}
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Pedidos</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Pedidos</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedCliente}
                  onChange={(e) => {
                    setSelectedCliente(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {clientes
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedEmpresa}
                  onChange={(e) => {
                    setSelectedEmpresa(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {empresas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedComprador}
                  onChange={(e) => {
                    setSelectedComprador(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {compradores
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedColaborador}
                  onChange={(e) => {
                    setSelectedColaborador(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {colaboradores
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArticulo}
                  onChange={(e) => {
                    setSelectedArticulo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulos
                    .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                    .map((a) => {
                      return (
                        <option value={a._id}>
                          {a.codigo} {a.codigoCliente} {a.descripcion}
                        </option>
                      );
                    })}
                </Input>
              </td>
              {/* <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedMarca}
                  onChange={(e) => {
                    setSelectedMarca(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {marcas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td> */}
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedPendFechaConf}
                  onChange={(e) => {
                    setSelectedPendFechaConf(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedStatus}
                  required
                  onChange={(e) => {
                    setSelectedStatus(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                <option value="">Selecciona</option>
                <option value="Abierto">Abierto</option>
                <option value="Cerrado">Cerrado</option>
                </Input>
              </td>

              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedEstado}
                  required
                  onChange={(e) => {
                    setSelectedEstado(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                   <option value="">Selecciona</option>
                  <option value="Pendiente">Pendiente</option>
                  <option value="Confirmado">Confirmado</option>
                  <option value="Revision">Revision</option>
                  <option value="Rechazado">Rechazado</option>
                </Input>
              </td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                return (
                    <tr>
                    <td style={ a.pedidoEditado =="Si" ? { backgroundColor: "#ffff00" } : undefined}>{a.numero}</td>
                    <td>{a.resurtido}</td>
                    <td>{a.fecha}</td>
                    <td>{a.clientes}</td>
                    <td>{a.empresas}</td>
                    <td>{a.compradores}</td>
                    <td>{a.colaboradores}</td>
                    <td>{a.articulos}</td>
                    {/* <td>{a.marcas}</td> */}
                    {/* <td>{a.colores.map((c)=>{
                        return c.name
                      })}</td> */}
                    <td>{a.fecha_cancelacion}</td>
                    <td>{a.fecha_confirmacion}</td>
                    <td>{a.pendFechaConf}</td>
                    <td>{new Intl.NumberFormat("en-US").format(a.cantidad)}</td>
                    <td>{new Intl.NumberFormat("en-US").format(a.surtido)}</td>
                    <td>
                      {new Intl.NumberFormat("en-US").format(
                        a.pendiente_surtir
                      )}
                    </td>
                    <td>
                      {"$" +
                        new Intl.NumberFormat("en-US").format(a.precioPrenda)}
                    </td>
                    <td>
                      {"$" +
                        new Intl.NumberFormat("en-US").format(a.total_general)}
                    </td>

                    <td>
                      {user.articulos_create ? (
                        <div>
                          <Button
                            color="info"
                            id="Editar"
                            size="sm"
                            onClick={(e) =>
                              precosteo(
                                a.idArticulo,
                                a.numero,
                                a._id,
                                a.cantidad
                              )
                            }
                          >
                            <i class="fas fa-dollar-sign"></i>
                          </Button>
                          <Button
                            color="success"
                            id="Colores"
                            size="sm"
                            onClick={(e) =>
                              coloresInfo(
                                a.idArticulo,
                                a.codigo,
                                a.descripcion,
                                a.colores
                              )
                            }
                          >
                            <i class="fas fa-palette"></i>
                          </Button>
                          <Button
                            color="info"
                            id="Tallas"
                            size="sm"
                            onClick={(e) =>
                              tallasInfo(
                                a.idArticulo,
                                a.codigo,
                                a.descripcion,
                                a.tallas
                              )
                            }
                          >
                            <i class="fas fa-ruler-horizontal"></i>
                          </Button>
                          <Button
                            color="success"
                            id="Editar"
                            size="sm"
                            onClick={(e) =>
                              explosiones(a.idArticulo, a.codigo, a.descripcion)
                            }
                          >
                            <i class="fas fa-tag"></i>
                          </Button>
                          <Button
                            color="danger"
                            id="CatalogoProcesos"
                            size="sm"
                            onClick={(e) =>
                              catalogoProcesosInfo(
                                a.idArticulo,
                                a.codigo,
                                a.descripcion,
                                a.catalogoProcesos
                              )
                            }
                          >
                            <i class="fas fa-tshirt"></i>
                          </Button>
                          <Button
                            color="info"
                            id="Editar"
                            size="sm"
                            onClick={(e) =>
                              jalaInfoArticulo(
                                a.codigo,
                                a.codigoCliente,
                                a.descripcion,
                                a.venta,
                                a.precioEtiqueta,
                                a.observacionesArticulo,
                                a.observacionesProduccion,
                                // a.idMarcas,
                                a.idColeccion,
                                a.idArticulo
                              )
                            }
                          >
                            <i class="far fa-edit"></i>
                          </Button>
                        </div>
                      ) : undefined}
                    </td>
                    <td>
                      {user.pedidos_create ? (
                        <div>
                          <Button
                            color="danger"
                            id="Editar"
                            size="sm"
                            onClick={(e) =>
                              requisicionGlobal(a._id, a.idArticulo, a.numero)
                            }
                          >
                            <i class="fas fa-tape"></i>
                            {/* <i class="fas fa-tag"></i> */}
                          </Button>
                          <Button
                            color="info"
                            id="Editar"
                            size="sm"
                            onClick={(e) => requisicion(a._id, a.numero)}
                          >
                            <i class="fas fa-tag"></i>
                          </Button>

                          <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            onClick={(e) =>
                              PDFOC(
                                a.fecha,
                                a.colaboradores,
                                a.compradores,
                                a.fecha_cancelacion,
                                a.fecha_confirmacion,
                                a.idClientes,
                                a.clientes,
                                a.pedidoCliente,
                                a.total_kilos,
                                a.total_metros,
                                a.total_general,
                                a.observaciones,
                                a.numero,
                                a._id,
                                a.cantidad,
                                a.precioPrenda,
                                a.total_general,
                                a.estado,
                                a.articulos
                              )
                            }
                          >
                            <i class="far fa-file-pdf"></i>
                          </Button>

                          {/* <BajaMultiple
                              idStatus={a._id}
                              is_active={a.activo}
                              URL_BAJA={process.env.REACT_APP_URL_PEDIDOS}
                              hijo={`${process.env.REACT_APP_URL_ARTICULOS_PEDIDO}/baja`}
                            /> */}
                          {/* <StatusMultiple
                              idStatus={a._id}
                              status={a.status}
                              URL_STATUS={process.env.REACT_APP_URL_PEDIDOS}
                              hijo={`${process.env.REACT_APP_URL_ARTICULOS_PEDIDO}/baja`}
                            /> */}
                        </div>
                      ) : undefined}
                    </td>
                    <td style={ a.estado== "Rechazado" ? { backgroundColor: "#f28b82" }: a.estado== "Revision" ? { backgroundColor: "#fff475" }: a.estado =="Confirmado" ? { backgroundColor: "#a8d5ba" } : undefined}>
                      {a.status == "Abierto" && a.activo == "Si" ? (
                        <div>
                          <Button
                            color="info"
                            id="Editar"
                            size="sm"
                            onClick={(e) =>
                              jalaInfo(
                                a.fecha,
                                a.idColaboradores,
                                a.fecha_cancelacion,
                                a.fecha_confirmacion,
                                a.idClientes,
                                a.idEmpresas,
                                a.idCompradores,
                                a.total_kilos,
                                a.total_metros,
                                a.total_general,
                                a.observaciones,
                                a.numero,
                                a._id,
                                a.idMuestrista,
                                a.idPatronista,
                                a.idPloter,
                                a.idArticulo,
                                a.pedidoCliente,
                                a.estado,
                                a.fechaEstado
                              )
                            }
                          >
                            <i class="far fa-edit"></i>
                          </Button>
                          <Button
                            color="info"
                            id="Editar"
                            size="sm"
                            onClick={(e) =>
                              EditArticulos(a._id, a.numero, a.idArticulo)
                            }
                          >
                            <i class="fas fa-cart-plus"></i>
                          </Button>
                          {a.fotoArticulo == "No" ? (
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              // onClick={(e) => jalaFoto(a.idArticulo)}
                              disabled
                            >
                              <i class="fas fa-camera"></i>
                            </Button>
                          ) : (
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) => jalaFoto(a.idArticulo)}
                            >
                              <i class="fas fa-camera"></i>
                            </Button>
                          )}
                          <Button
                            color="primary"
                            id="Cerrar"
                            onClick={(e) => cerrar(a._id)}
                            size="sm"
                          >
                            <i class="fas fa-lock-open"></i>
                          </Button>
                        </div>
                      ) : (
                        <div>
                          <Button color="info" id="Editar" size="sm" disabled>
                            <i class="far fa-edit"></i>
                          </Button>
                          <Button color="info" id="Editar" size="sm" disabled>
                            <i class="fas fa-cart-plus"></i>
                          </Button>
                          <Button
                            color="danger"
                            onClick={(e) => abierto(a._id)}
                            size="sm"
                          >
                            <i class="fas fa-lock"></i>
                          </Button>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Pedido {numero_pedido}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={4}>
              <Label>Fecha</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                onChange={(e) => {
                  setFecha(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label className="mr-sm-2">Cliente</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedClienteEdit}
                required
                onChange={(e) => {
                  setSelectedClienteEdit(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {clientes
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
            <Col md={4}>
              <Label className="mr-sm-2">Comprador</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedCompradorEdit}
                required
                onChange={(e) => {
                  setSelectedCompradorEdit(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {compradores
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Label>Vendedor</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedColaboradorEdit}
                required
                onChange={(e) => {
                  setSelectedColaboradorEdit(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
            </Col>
            <Col md={4}>
              <Label className="mr-sm-2">Empresa</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedEmpresaEdit}
                required
                onChange={(e) => {
                  setSelectedEmpresaEdit(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {empresas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>

            <Col md={4}>
              <Label className="mr-sm-2">Pedido Cliente</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={pedidoCliente}
                required
                onChange={(e) => {
                  setPedidoCliente(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {empresas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Label>Fecha Cancelacion</Label>
              <Input
                type="date"
                placeholder="Fecha Cancelacion"
                value={fecha_cancelacion}
                required
                onChange={(e) => {
                  setFechaCancelacion(e.target.value);
                  let inicio = new Date(e.target.value);
                  let bla = inicio.setDate(inicio.getDate() - 41);
                  setFechaConfirmacion(moment(bla).format("YYYY-MM-DD"));
                }}
              />
            </Col>
            <Col md={4}>
              <Label>Fecha Confirmacion</Label>
              <Input
                type="date"
                placeholder="Fecha Confirmacion"
                value={fecha_confirmacion}
                required
                onChange={(e) => {
                  setFechaConfirmacion(e.target.value);
                }}
              />
            </Col>
</Row>

          <Row>
            <Col md={4}>
              <Label>Estado</Label>
              <Input
                type="select"
                placeholder="Estado"
                value={selectedEstadoEdit}
                required
                onChange={(e) => {
                  setSelectedEstadoEdit(e.target.value);
                }}
                >
               <option value="Pendiente">Pendiente</option>
               <option value="Confirmado">Confirmado</option>
               <option value="Revision">Revision</option>
               <option value="Rechazado">Rechazado</option>
             </Input>
            </Col>

            <Col md={4}>
              <Label>Fecha Estado</Label>
              <Input
                type="date"
                placeholder="FechaEstado"
                value={fechaEstadoEdit}
                required
                onChange={(e) => {
                  setFechaEstadoEdit(e.target.value);
                }}
                />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones Diseño
              </Label>
              <Input
                type="textarea"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
            <Col md={6}>
              <Label>Foto</Label>
              <Input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editPedidos}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        size="xxl"
        isOpen={modalEditArticulos}
        toggle={toggleEditArticulos}
      >
        <ModalHeader toggle={toggleEditArticulos}>
          <h4>Editar Articulos Pedido {numero_pedido}</h4>
          <h4>
            TOTALES {new Intl.NumberFormat("en-US").format(total_cantidad_edit)}{" "}
            pzas. /{" "}
            {"$" + new Intl.NumberFormat("en-US").format(total_general_edit)}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={savePedido}>
            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Articulos</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Colores</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Tallas</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Codigo Barras</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Surtido</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio Venta</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio Etiqueta</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Descuento</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Activo</Label>
              </Col>
            </Row>

            {articulosPedido
              .sort(
                (a, b) =>
                  a.colores.localeCompare(b.colores) ||
                  a.tallas.localeCompare(b.tallas)
              )
              .map((ao) => (
                <div key={ao.id}>
                  <Row>
                    {ao.surtido == 0 ? (
                      <>
                        <Col md={2}>
                          <Input
                            name="articulos"
                            type="select"
                            value={ao.articulos}
                            required
                            onChange={(event) => {
                              BuscaArticuloExistentes(ao.id, event);
                            }}
                          >
                            <option value="">Selecciona</option>
                            {articulos
                              .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                              .map((a) => {
                                return (
                                  <option value={a._id}>
                                    {a.codigo} / {a.codigoCliente} /{" "}
                                    {a.descripcion}
                                  </option>
                                );
                              })}
                          </Input>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col md={2}>
                          <Input
                            name="articulos"
                            type="select"
                            value={ao.articulos}
                            required
                            disabled
                          >
                            <option value="">Selecciona</option>
                            {articulos
                              .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                              .map((a) => {
                                return (
                                  <option value={a._id}>
                                    {a.codigo} / {a.nombre}
                                  </option>
                                );
                              })}
                          </Input>
                        </Col>
                      </>
                    )}

                    {ao.surtido == 0 ? (
                      <>
                        <Col md={1}>
                          <Input
                            name="colores"
                            type="select"
                            value={ao.colores}
                            required
                            onChange={(event) =>
                              handleChangeInputExistentes(ao.id, event)
                            }
                          >
                            <option value="">Selecciona</option>
                            {ao.arrayColoresArticulo
                              .sort((a, b) => (a.name > b.name ? 1 : -1))
                              .map((a) => {
                                return <option value={a._id}>{a.name}</option>;
                              })}
                          </Input>
                        </Col>
                        <Col md={1}>
                          <Input
                            name="tallas"
                            type="select"
                            value={ao.tallas}
                            required
                            onChange={(event) =>
                              handleChangeInputExistentes(ao.id, event)
                            }
                          >
                            <option value="">Selecciona</option>
                            {ao.arrayTallasArticulo
                              .sort((a, b) => (a.name > b.name ? 1 : -1))
                              .map((a) => {
                                return <option value={a._id}>{a.name}</option>;
                              })}
                          </Input>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col md={1}>
                          <Input
                            name="colores"
                            type="select"
                            value={ao.colores}
                            required
                            disabled
                          >
                            <option value="">Selecciona</option>
                            {ao.arrayColoresArticulo
                              .sort((a, b) => (a.name > b.name ? 1 : -1))
                              .map((a) => {
                                return <option value={a._id}>{a.name}</option>;
                              })}
                          </Input>
                        </Col>
                        <Col md={1}>
                          <Input
                            name="tallas"
                            type="select"
                            value={ao.tallas}
                            required
                            disabled
                          >
                            <option value="">Selecciona</option>
                            {ao.arrayTallasArticulo
                              .sort((a, b) => (a.name > b.name ? 1 : -1))
                              .map((a) => {
                                return <option value={a._id}>{a.name}</option>;
                              })}
                          </Input>
                        </Col>
                      </>
                    )}

                    <Col md={1}>
                      <Input
                        name="codigoBarras"
                        type="number"
                        placeholder="CodigoBarras"
                        value={ao.codigoBarras}
                        onChange={(event) =>
                          handleChangeInputExistentes(ao.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={ao.cantidad}
                        required
                        onChange={(event) =>
                          handleChangeInputExistentes(ao.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        name="surtido"
                        type="number"
                        placeholder="surtido"
                        value={ao.surtido.toFixed(2)}
                        disabled
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        name="precio"
                        type="number"
                        placeholder="Precio"
                        value={ao.precio}
                        required
                        onChange={(event) =>
                          handleChangeInputExistentes(ao.id, event)
                        }
                      />
                    </Col>
                    <Col md={1}>
                      <Input
                        name="precioEtiqueta"
                        type="number"
                        placeholder="PrecioEtiqueta"
                        value={ao.precioEtiqueta}
                        required
                        onChange={(event) =>
                          handleChangeInputExistentes(ao.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="descuento"
                        type="number"
                        placeholder="Descuento"
                        value={ao.descuento}
                        required
                        onChange={(event) =>
                          handleChangeInputExistentes(ao.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="total"
                        type="number"
                        placeholder="Total"
                        value={ao.total}
                        disabled
                        onChange={(event) =>
                          handleChangeInputExistentes(ao.id, event)
                        }
                      />
                    </Col>
                    <Col md={1}>
                      <Baja
                        idStatus={ao.id}
                        is_active={ao.activo}
                        URL_BAJA={process.env.REACT_APP_URL_ARTICULOS_PEDIDO}
                      />
                    </Col>
                  </Row>
                </div>
              ))}

            {/* Agregar mas articulos */}

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={2}>
                    <Input
                      // bsSize="sm"
                      name="articulos"
                      type="select"
                      value={inputField.articulos}
                      required
                      onChange={(event) => {
                        BuscaArticulo(inputField.id, event);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {articulos
                        .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                        .map((a) => {
                          if (idArticuloPedido == a._id) {
                            return (
                              <option value={a._id}>
                                {a.codigo} / {a.codigoCliente} / {a.descripcion}
                              </option>
                            );
                          }
                        })}
                    </Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="colores"
                      type="select"
                      value={inputField.colores}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    >
                      <option value="0">Selecciona</option>
                      {inputField.arrayColoresArticulo
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="tallas"
                      type="select"
                      value={inputField.tallas}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    >
                      <option value="0">Selecciona</option>
                      {inputField.arrayTallasArticulo
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      name="codigoBarras"
                      type="number"
                      placeholder="CodigoBarras"
                      value={inputField.codigoBarras}
                      required
                      onChange={(event) =>
                        handleChangeInputExistentes(inputField.id, event)
                      }
                    />
                  </Col>

                  {inputField.colores == "" ? (
                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={inputField.cantidad}
                        disabled
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>
                  ) : (
                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={inputField.cantidad}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>
                  )}

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="surtido"
                      type="number"
                      placeholder="0"
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="precio"
                      type="number"
                      placeholder="Precio"
                      value={inputField.precio}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="precioEtiqueta"
                      type="number"
                      placeholder="PrecioEtiqueta"
                      value={inputField.precioEtiqueta}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="descuento"
                      type="number"
                      placeholder="Descuento"
                      value={inputField.descuento}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="total"
                      type="number"
                      placeholder="Total"
                      value={inputField.total}
                      disabled
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <Row>
              <Col md={6}>
                <h4 id="logoutBoton">
                  TOTALES{" "}
                  {new Intl.NumberFormat("en-US").format(total_cantidad_edit)}{" "}
                  pzas. /{" "}
                  {"$" +
                    new Intl.NumberFormat("en-US").format(total_general_edit)}
                </h4>
              </Col>
            </Row>
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
          </Form>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            Este proceso puede tardar varios minutos.
            <br />
            Por favor no cierre ni refresque su navegador.
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal size="xl" isOpen={modalExplosion} toggle={toggleExplosion}>
        <ModalHeader toggle={toggleExplosion}>
          <h4>Explosion</h4>
        </ModalHeader>
        <ModalBody>
          <h5>Tela</h5>
          <Table size="sm" striped borderless className="table-responsive-xl">
            <tr>
              <th>Articulo</th>
              <th>Material</th>
              <th>Color</th>
              <th>Cantidad</th>
              <th>Observaciones</th>
            </tr>
            {explosion.map((a) => (
              <tr>
                <td>{a.articulosCodigo}</td>
                <td>{a.material}</td>
                <td>{a.coloresName}</td>
                <td>{a.cantidad}</td>
                <td>{a.observaciones}</td>
              </tr>
            ))}
          </Table>
          <br />

          <h5>Habilitacion</h5>
          <Table size="sm" striped borderless className="table-responsive-xl">
            <tr>
              {/* <th>Articulo</th> */}
              <th>Material</th>
              <th>Cantidad</th>
              <th>Observaciones</th>
            </tr>
            {explosionHab.map((a) => (
              <tr>
                {/* <td>{a.articulosCodigo}</td> */}
                <td>{a.material}</td>
                <td>{a.cantidad}</td>
                <td>{a.observaciones}</td>
              </tr>
            ))}
          </Table>
        </ModalBody>
      </Modal>

      <Modal
        size="xxl"
        isOpen={modalExplosionGlobal}
        toggle={toggleExplosionGlobal}
      >
        <ModalHeader toggle={toggleExplosionGlobal}>
          <h4>Explosion Global Tela</h4>
        </ModalHeader>
        <ModalBody>
          <Table size="sm" striped borderless className="table-responsive-xl">
            <Row>
              <Col md={3}>Tela</Col>
              <Col md={2}>Color</Col>
              <Col md={1}>Metros Totales</Col>
              <Col md={1}>Consumo Por Prenda</Col>
              <Col md={3}>Observaciones</Col>
            </Row>
            {explosionGlobalExistente.map((a) => (
              <div key={a._id}>
                <Row>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="telas"
                      type="select"
                      value={a.idTela}
                      required
                      disabled
                    >
                      <option value="">Selecciona</option>
                      {telas
                        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.nombre} / {a.tiposTelas[0].name}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="colores"
                      type="select"
                      value={a.idColor}
                      required
                      disabled
                    >
                      <option value="">Selecciona</option>
                      {colores
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      value={a.cantidad}
                      onChange={(event) => {
                        handleChangeInputExistenteGlobal(a._id, event);
                      }}
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidadPrenda"
                      type="number"
                      value={a.cantidadPrenda}
                      onChange={(event) => {
                        handleChangeInputExistenteGlobal(a._id, event);
                      }}
                    ></Input>
                  </Col>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="observaciones"
                      type="text"
                      value={a.observaciones}
                      onChange={(event) => {
                        handleChangeInputExistenteGlobal(a._id, event);
                      }}
                    ></Input>
                  </Col>
                </Row>
              </div>
            ))}
            {inputFieldsTelaGlobal.map((a) => (
              <div key={a.id}>
                <Row>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="idTela"
                      type="select"
                      value={a.idTela}
                      required
                      onChange={(event) => {
                        handleChangeInputTelaGlobal(a.id, event);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {telas
                        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.nombre} / {a.tiposTelas[0].name}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="idColor"
                      type="select"
                      value={a.idColor}
                      required
                      onChange={(event) => {
                        handleChangeInputTelaGlobal(a.id, event);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {colores
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      value={a.cantidad}
                      onChange={(event) => {
                        handleChangeInputTelaGlobal(a.id, event);
                      }}
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidadPrenda"
                      type="number"
                      value={a.cantidadPrenda}
                      onChange={(event) => {
                        handleChangeInputTelaGlobal(a.id, event);
                      }}
                    ></Input>
                  </Col>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="observaciones"
                      type="text"
                      value={a.observaciones}
                      onChange={(event) => {
                        handleChangeInputTelaGlobal(a.id, event);
                      }}
                    ></Input>
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFieldsTelaGlobal.length === 1}
                      onClick={() => handleRemoveFieldsTelasGlobal(a.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFieldsTelasGlobal}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
          </Table>
          <br />
        </ModalBody>

        <ModalFooter>
          {validaBoton ? (
            <Button
              type="submit"
              className="btn btn-success"
              onClick={saveExplosionGlobal}
            >
              Guardar
            </Button>
          ) : (
            <Button type="submit" className="btn btn-success" disabled>
              Guardar
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <Modal size="xl" isOpen={modalFoto} toggle={toggleFoto}>
        <ModalHeader toggle={toggleFoto}>
          <h4>Foto</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            {photo ? (
              <h6 style={{ textAlign: "center" }}>
                <img
                  // loading="lazy"
                  src={`${process.env.REACT_APP_URL_FOTO_CLOUDINARY}/image/upload/v${photo}/saparnasaArticulos/${idEdit}`}
                  alt=""
                />
              </h6>
            ) : (
              <h4>No se ha subido el Pedido del Cliente....</h4>
            )}
          </Row>
        </ModalBody>
      </Modal>

      <Modal size="xl" isOpen={modalExplosiones} toggle={toggleExplosiones}>
        <ModalHeader toggle={toggleExplosiones}>
          <h4>
            Explosion Modelo {codigoExplosion} / {descripcionExplosion}
          </h4>
        </ModalHeader>
        <ModalBody>
          {/* <Row>
            <Col md={4}>
              <Label>Tela</Label>
            </Col>
            <Col md={3}>
              <Label>Consumo X Prenda</Label>
            </Col>
            <Col md={3}>
              <Label>Observaciones</Label>
            </Col>
          </Row> */}
          {/* {explosionExistentes.map((a) =>
            a.tipo == "Tela" ? (
              <div key={a._id}>
                <Row>
                  <Col md={4}>
                    <Input
                      bsSize="sm"
                      name="telas"
                      type="select"
                      value={a.telas[0]._id}
                      required
                      disabled
                    >
                      <option value="">Selecciona</option>
                      {telas
                        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.nombre} / {a.tiposTelas[0].name}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      value={a.cantidad}
                      onChange={(event) => {
                        handleChangeInputExistente(a._id, event);
                      }}
                    ></Input>
                  </Col>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="observaciones"
                      type="text"
                      value={a.observaciones}
                      onChange={(event) => {
                        handleChangeInputExistente(a._id, event);
                      }}
                    ></Input>
                  </Col>
                </Row>
              </div>
            ) : undefined
          )}
          {inputFieldsTela.map((a) => (
            <div key={a.id}>
              <Row>
                <Col md={4}>
                  <Input
                    bsSize="sm"
                    name="idTela"
                    type="select"
                    value={a.idTela}
                    required
                    onChange={(event) => {
                      handleChangeInputTela(a.id, event);
                    }}
                  >
                    <option value="">Selecciona</option>
                    {telas
                      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.nombre} / {a.tiposTelas[0].name}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
                <Col md={3}>
                  <Input
                    bsSize="sm"
                    name="cantidad"
                    type="number"
                    value={a.cantidad}
                    onChange={(event) => {
                      handleChangeInputTela(a.id, event);
                    }}
                  ></Input>
                </Col>
                <Col md={3}>
                  <Input
                    bsSize="sm"
                    name="observaciones"
                    type="text"
                    value={a.observaciones}
                    onChange={(event) => {
                      handleChangeInputTela(a.id, event);
                    }}
                  ></Input>
                </Col>
                <Col>
                  <Button
                    size="sm"
                    className="btn"
                    color="danger"
                    disabled={inputFieldsTela.length === 1}
                    onClick={() => handleRemoveFieldsTelas(a.id)}
                    tabindex="-1"
                  >
                    <i class="fas fa-minus"></i>
                  </Button>
                  <Button
                    size="sm"
                    className="btn"
                    color="info"
                    onClick={handleAddFieldsTelas}
                    tabindex="-1"
                  >
                    <i class="fas fa-plus"></i>
                  </Button>
                </Col>
              </Row>
            </div>
          ))} */}
          <br />
          <Row>
            <Col md={4}>
              <Label>Habilitacion</Label>
            </Col>
            <Col md={3}>
              <Label>Consumo X Prenda</Label>
            </Col>
            <Col md={3}>
              <Label>Observaciones</Label>
            </Col>
          </Row>
          {explosionExistentes.map((a) =>
            a.tipo == "Habilitacion" ? (
              <div key={a._id}>
                <Row>
                  <Col md={4}>
                    <Input
                      bsSize="sm"
                      name="habilitacion"
                      type="select"
                      value={a.habilitacion[0]._id}
                      required
                      disabled
                    >
                      <option value="">Selecciona</option>
                      {habilitacion
                        .sort((a, b) =>
                          a.descripcion > b.descripcion ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.descripcion} {a.nombre}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      value={a.cantidad}
                      onChange={(event) => {
                        handleChangeInputExistente(a._id, event);
                      }}
                    ></Input>
                  </Col>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="observaciones"
                      type="text"
                      value={a.observaciones}
                      onChange={(event) => {
                        handleChangeInputExistente(a._id, event);
                      }}
                    ></Input>
                  </Col>
                </Row>
              </div>
            ) : undefined
          )}
          {inputFieldsHab.map((a) => (
            <div key={a.id}>
              <Row>
                <Col md={4}>
                  <Input
                    bsSize="sm"
                    name="idHab"
                    type="select"
                    value={a.idHab}
                    required
                    onChange={(event) => {
                      handleChangeInputHab(a.id, event);
                    }}
                  >
                    <option value="">Selecciona</option>
                    {habilitacion
                      .sort((a, b) => (a.descripcion > b.descripcion ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.descripcion} {a.nombre}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
                <Col md={3}>
                  <Input
                    bsSize="sm"
                    name="cantidad"
                    type="number"
                    value={a.cantidad}
                    onChange={(event) => {
                      handleChangeInputHab(a.id, event);
                    }}
                  ></Input>
                </Col>
                <Col md={3}>
                  <Input
                    bsSize="sm"
                    name="observaciones"
                    type="text"
                    value={a.observaciones}
                    onChange={(event) => {
                      handleChangeInputHab(a.id, event);
                    }}
                  ></Input>
                </Col>
                <Col>
                  <Button
                    size="sm"
                    className="btn"
                    color="danger"
                    disabled={inputFieldsHab.length === 1}
                    onClick={() => handleRemoveFieldsHab(a.id)}
                    tabindex="-1"
                  >
                    <i class="fas fa-minus"></i>
                  </Button>
                  <Button
                    size="sm"
                    className="btn"
                    color="info"
                    onClick={handleAddFieldsHab}
                    tabindex="-1"
                  >
                    <i class="fas fa-plus"></i>
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
        </ModalBody>
        <ModalFooter>
          {validaBoton ? (
            <Button
              type="submit"
              className="btn btn-success"
              onClick={saveExplosion}
            >
              Guardar
            </Button>
          ) : (
            <Button type="submit" className="btn btn-success" disabled>
              Guardar
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <Modal size="lg" isOpen={modalColores} toggle={toggleColores}>
        <ModalHeader toggle={toggleColores}>
          <h4>
            Colores del Articulo {codigo} / {descripcion}
          </h4>
        </ModalHeader>
        <ModalBody>
          {colores
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((a, index) => {
              if (!arrayColores.includes(a._id)) {
                return (
                  <table>
                    <td>
                      <Input
                        type="checkbox"
                        key={index}
                        onChange={(e) => {
                          coloresSeleccionados(e.currentTarget.checked, a._id);
                        }}
                        className="subMenu"
                      />
                      <h5 className="subMenuTitulo">{a.name}</h5>
                    </td>
                  </table>
                );
              } else {
                return <h5 className="subMenuTitulo">{a.name}</h5>;
              }
            })}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={guardarColores}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="lg" isOpen={modalTallas} toggle={toggleTallas}>
        <ModalHeader toggle={toggleTallas}>
          <h4>
            Tallas del Articulo {codigo} / {descripcion}
          </h4>
        </ModalHeader>
        <ModalBody>
          {tallas
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((a, index) => {
              if (!arrayTallas.includes(a._id)) {
                return (
                  <table>
                    <td>
                      <Input
                        type="checkbox"
                        key={index}
                        onChange={(e) => {
                          tallasSeleccionados(e.currentTarget.checked, a._id);
                        }}
                        className="subMenu"
                      />
                      <h5 className="subMenuTitulo">{a.name}</h5>
                    </td>
                  </table>
                );
              } else {
                return <h5 className="subMenuTitulo">{a.name}</h5>;
              }
            })}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={guardarTallas}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        size="lg"
        isOpen={modalCatalogoProcesos}
        toggle={toggleCatalogoProcesos}
      >
        <ModalHeader toggle={toggleCatalogoProcesos}>
          <h4>
            Procesos del Articulo {codigo} / {descripcion}
          </h4>
        </ModalHeader>
        <ModalBody>
          {catalogoProcesos
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((a, index) => {
              if (!arrayCatalogoProcesos.includes(a._id)) {
                return (
                  <table>
                    <td>
                      <Input
                        type="checkbox"
                        key={index}
                        onChange={(e) => {
                          catalogoProcesosSeleccionados(
                            e.currentTarget.checked,
                            a._id
                          );
                        }}
                        className="subMenu"
                      />
                      <h5 className="subMenuTitulo">{a.name}</h5>
                    </td>
                  </table>
                );
              } else {
                return <h5 className="subMenuTitulo">{a.name}</h5>;
              }
            })}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={guardarCatalogoProcesos}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        size="xl"
        isOpen={modalEditInfoArticulo}
        toggle={toggleEditInfoArticulo}
      >
        <ModalHeader toggle={toggleEditInfoArticulo}>
          <h4>
            Editar Modelo {codigoEdit} / {descripcionEdit}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label>Codigo</Label>
              <Input
                type="text"
                placeholder="Codigo"
                value={codigoEdit}
                required
                onChange={(e) => {
                  setCodigoEdit(e.target.value);
                }}
              />
            </Col>

            <Col md={3}>
              <Label>Descripcion</Label>
              <Input
                type="text"
                placeholder="Descripcion"
                value={descripcionEdit}
                required
                onChange={(e) => {
                  setDescripcionEdit(e.target.value);
                }}
              />
            </Col>

            {/* <Col md={3}>
                <Label className="mr-sm-2">Marca</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedMarcaEdit}
                  required
                  onChange={(e) => {
                    setSelectedMarcaEdit(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {marcas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col> */}

            <Col md={3}>
              <Label className="mr-sm-2">Coleccion</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedColeccionEdit}
                required
                onChange={(e) => {
                  setSelectedColeccionEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona</option>
                {colecciones
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label>CodigoCliente</Label>
              <Input
                type="text"
                placeholder="CodigoCliente"
                value={codigoClienteEdit}
                required
                onChange={(e) => {
                  setCodigoClienteEdit(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row></Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editArticulos}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>


      <Modal size="xl" isOpen={modalPrecosteo} toggle={togglePrecosteo}>
        <ModalHeader toggle={togglePrecosteo}>
          <h4>Precosteo</h4>
        </ModalHeader>
        <ModalBody>
          {/* <h3>Tela</h3> */}
          <Table size="xl" striped borderless>
            <thead>
            <tr>
              <th>Tela</th>
              <th>Color</th>
              <th>Cantidad</th>
              <th>Costo</th>
              <th>Costo x Prenda</th>
            </tr>
            </thead>
            <tbody>
            {precosteoTelas.map((a) => (
              totalPrecosteoTela = totalPrecosteoTela + a.totalPrenda,
              <tr>
                <td>{a.tela}</td>
                <td>{a.color}</td>
                <td>{a.cantidad}</td>
                <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costo)}</td>
                <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.totalPrenda)}</td>
              </tr>
            ))}
            <br />
            <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="negrita" align="center">
                        Total Tela
                      </td>
                      <td className="negrita">
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalPrecosteoTela)}
                      </td>
                    </tr>
            </tbody>
          </Table>
          {/* <br /> */}

          {/* <h3>Habilitacion</h3> */}
          <Table size="xl" striped borderless>
            <thead>
            <tr>
              <th>Habilitacion</th>
              <th>Cantidad</th>
              <th>Costo</th>
              <th>Costo x Prenda</th>
            </tr>
            </thead>
            <tbody>
            {precosteoHab.map((a) => (
              totalPrecosteoHab = totalPrecosteoHab + a.totalPrenda,
              <tr>
                <td>{a.habilitacion}</td>
                <td>{a.cantidad}</td>
                <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costo)}</td>
                <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.totalPrenda)}</td>
              </tr>
            ))}
            <tr>
                      <td></td>
                      <td></td>
                      <td className="negrita" align="center">
                        Total Habilitacion
                      </td>
                      <td className="negrita">
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalPrecosteoHab)}
                      </td>
                      <td></td>
                    </tr>

                    <tr>
                      <td></td>
                      <td></td>
                      <td className="negrita" align="center">
                        Total General
                      </td>
                      <td className="negrita">
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalPrecosteoTela + totalPrecosteoHab)}
                      </td>
                      <td></td>
                    </tr>
            </tbody>
          </Table>


        </ModalBody>
      </Modal>
      {loader}
    </>
  );
}

export default ListadoPedidos;
