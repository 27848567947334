import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { func } from "prop-types";
import qrcode from "qrcode-js";

function ListadoInventariosHabilitacion() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_INVENTARIOS_TELAS = `${process.env.REACT_APP_URL_INVENTARIOS_TELAS}`;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  
  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
 
  const [unidad, setUnidad] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");


  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(`${URL_INVENTARIOS_TELAS}Habilitacion`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInventariosTelas = response.data;
        // Array para pagination
        let arrayTabla = allInventariosTelas
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              cantidad: a.cantidad,
              nombre: a.nombre,
              idHab: a.idHab,
              descripcion: a.descripcion,
              unidad: a.unidad,
              costo: a.costo,
              costoTotal: a.costoTotal,
              observaciones: a.observaciones,
              idCompras: a.idCompras,
              idProveedor: a.idProveedor,
              proveedor: a.proveedor,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);

    
      })

      .catch((err) => {
        console.log(err);
      });
    
    axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

   
  }, []);


  function PDFTabla() {
    const data = comments
    .filter(
      (comment) =>
      comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
      comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
      comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
      comment.cantidad.toString().includes(search) ||
      comment.costo.toString().includes(search) ||
      comment.costoTotal.toString().includes(search) 
    )
    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
    .map((a) => {
      if (
        (unidad == 0 || unidad == a.unidad) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedor)
      ) {
        total_general = parseFloat(total_general) + parseFloat(a.cantidad);
        total_generalCosto =
          parseFloat(total_generalCosto) + parseFloat(a.costoTotal);
        return [
          a.nombre,
          a.unidad,
          new Intl.NumberFormat("en-US").format(a.cantidad),
          "$" + new Intl.NumberFormat("en-US").format(a.costoTotal),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Inventarios Habilitacion`, 20, 40);
    doc.autoTable({

      head: [
        [
          "Nombre",
          "Unidad",
          "Cantidad",
          "Costo Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "Total",
          new Intl.NumberFormat("en-US").format(total_general),
          "$" + new Intl.NumberFormat("en-US").format(total_generalCosto),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`InventariosHabilitacion.pdf`);
  }

  function excel() {

    const dataExcel = comments
      .filter(
        (comment) =>
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.descripcion.toLowerCase().includes(search.toLowerCase()) ||
          comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search) ||
          comment.costo.toString().includes(search) ||
          comment.costoTotal.toString().includes(search) 
      )
      .map((a) => {
        if (
          (unidad == 0 || unidad == a.unidad) &&
          (selectedProveedor == 0 || selectedProveedor == a.idProveedor)
        ) {
          return {
            Nombre: a.nombre,
            Descripcion: a.descripcion,
            Unidad: a.unidad,
            Cantidad: a.cantidad,
            CostoTotal: a.costoTotal,
          };
        }
      });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoInventariosHabilitacion";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoInventariosHabilitacion",
        sheetFilter: [
          "Nombre",
          "Descripcion",
          "Unidad",
          "Cantidad",
          "CostoTotal",
        ],
        sheetHeader: [
          "Nombre",
          "Descripcion",
          "Unidad",
          "Cantidad",
          "CostoTotal",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments
      .filter(
        (comment) =>
        comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
        comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
        comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
        comment.cantidad.toString().includes(search) ||
        comment.costo.toString().includes(search) ||
        comment.costoTotal.toString().includes(search) 
      )
      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
      .map((a) => {
        if (
          (unidad == 0 || unidad == a.unidad) &&
          (selectedProveedor == 0 || selectedProveedor == a.idProveedor)
        ) {
          total_general = parseFloat(total_general) + parseFloat(a.cantidad);
          total_generalCosto =
            parseFloat(total_generalCosto) + parseFloat(a.costoTotal);
          return [
            a.nombre,
            a.unidad,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            "$" + new Intl.NumberFormat("en-US").format(a.costoTotal),
          ];
        }
      });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Inventarios Habilitacion`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Nombre",
          "Unidad",
          "Cantidad",
          "Costo Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,

      foot: [
        [
          "",
          "Total",
          new Intl.NumberFormat("en-US").format(total_general),
          "$" + new Intl.NumberFormat("en-US").format(total_generalCosto),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Inventarios Habilitacion",
          email: mailTo,
          fileName: "ListadoInventariosHabilitacion.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Inventarios Habilitacion.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setMailTo("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }



  const headers = [
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Descripcion", field: "descripcion", sortable: true },
    { name: "Unidad", field: "unidad", sortable: true },
    { name: "Proveedor", field: "proveedor", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: true },
    { name: "Costo", field: "costo", sortable: true },
    { name: "Costo Total", field: "costoTotal", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
        comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
        comment.descripcion.toLowerCase().includes(search.toLowerCase()) ||
        comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
        comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
        comment.cantidad.toString().includes(search) ||
        comment.costo.toString().includes(search) ||
        comment.costoTotal.toString().includes(search) 
      );
    }
    if (unidad) {
      computedComments = computedComments.filter((e) =>
        e.unidad.includes(unidad)
      );
    }
    if (selectedProveedor) {
      computedComments = computedComments.filter((e) =>
        e.idProveedor.includes(selectedProveedor)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cantidad" &&
      sorting.field != "costo" &&
      sorting.field != "costoTotal" &&
      sorting.field != "costo"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cantidad" ||
        sorting.field != "costo" ||
        sorting.field != "costoTotal" ||
        sorting.field != "costo")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cantidad" ||
        sorting.field != "costo" ||
        sorting.field != "costoTotal" ||
        sorting.field != "costo")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    unidad,
    selectedProveedor,
  ]);

 
let total_general = 0
let total_generalCosto = 0
let total = 0
let totalCosto = 0

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_telas ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuCatalogos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
           

            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Inventarios Habilitacion</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
        
          <h3 align="center">Inventario Habilitacion</h3>
          <div className="row">
            <div className="col-md-10">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>{" "}
            <div className="col-md-1 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
             
            
             
              <td></td>
              <td></td>
          
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  placeholder="Unidad"
                  value={unidad}
                  required
                  onChange={(e) => {
                    setUnidad(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Metros">Metros</option>
                  <option value="Kilos">Kilos</option>
                  <option value="Piezas">Piezas</option>
                </Input>
              </td>
            
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedProveedor}
                  onChange={(e) => {
                    setSelectedProveedor(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {proveedores
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}></td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                total = total + parseFloat(a.cantidad);
                totalCosto = totalCosto + parseFloat(a.costoTotal);

                {
                  return (
                    <tr>
                      <td>{a.nombre}</td>
                      <td>{a.descripcion}</td>
                      <td>{a.unidad}</td>
                      <td>{a.proveedor} </td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.cantidad)}
                      </td>
                      <td>
                        {"$" +
                          new Intl.NumberFormat("en-US").format(
                            (
                              parseFloat(a.costoTotal) / parseFloat(a.cantidad)
                            ).toFixed(2)
                          )}
                      </td>
                      <td>
                        {"$" +
                          new Intl.NumberFormat("en-US").format(a.costoTotal)}
                      </td>
                     
                    </tr>
                  );
                }
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita">TOTAL</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(total)}
                </td>
                <td></td>
                <td className="negrita">
                  {"$" + new Intl.NumberFormat("en-US").format(totalCosto)}
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}

   
      {loader}
    </>
  );
}

export default ListadoInventariosHabilitacion;
