import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesInventariosTelas() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_inventarios ?(
      <div className="container">
        <br />
        <br />
          <Row>
          <Col md={4} align="center">
          {user.inventarios ?(
            <Button href="/ListadoInventarios" className="botonesMenu" color="success">
              <i class="fas fa-warehouse fa-7x"></i>
              <br />
              <br />
              Inventario
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-warehouse fa-7x"></i>
          <br />
          <br />
          Inventario
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.inventarios ?(
            <Button href="/ListadoSeguimiento" className="botonesMenu" color="success">
              <i class="fas fa-search fa-7x"></i>
              <br />
              <br />
              Seguimiento
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-search fa-7x"></i>
          <br />
          <br />
          Seguimiento
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menu_traspasos_salida ?(
            <Button href="/ListadoTraspasosSalida" className="botonesMenu" color="success">
              <i class="fas fa-sign-out-alt fa-6x"></i>
              <br />
              <br />
              Traspasos Salida
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-sign-out-alt fa-6x"></i>
          <br />
          <br />
          Traspasos Salida
        </Button> }
          </Col>

        </Row>
        <br/>
        <Row>
        <Col md={4} align="center">
          {user.menu_traspasos_entrada ?(
            <Button href="/ListadoTraspasosEntrada" className="botonesMenu" color="success">
              <i class="fas fa-sign-in-alt fa-6x"></i>
              <br />
              <br />
              Traspasos Entrada
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-sign-in-alt fa-6x"></i>
          <br />
          <br />
          Traspasos Entrada
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.inventarios ?(
            <Button href="/ListadoUbicaciones" className="botonesMenu" color="success">
              <i class="fas fa-thumbtack fa-7x"></i>
              <br />
              <br />
              Ubicaciones por Articulo
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-thumbtack fa-7x"></i>
          <br />
          <br />
          Ubicaciones por Articulo
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.inventarios ?(
            <Button href="/ListadoUbicaciones2" className="botonesMenu" color="success">
              <i class="fas fa-map-marked-alt fa-7x"></i>
              <br />
              <br />
              Ubicaciones
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-map-marked-alt fa-7x"></i>
          <br />
          <br />
          Ubicaciones
        </Button> }
          </Col>
        </Row>
        <br />
        <Row>

        <Col md={4} align="center">
          {user.inventarios ?(
            <Button href="/Areas" className="botonesMenu" color="success">
              <i class="fas fa-warehouse fa-7x"></i>
              <br />
              <br />
              Almacenes
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-warehouse fa-7x"></i>
          <br />
          <br />
          Almacenes
        </Button> }
          </Col>
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesInventariosTelas;
