import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function CompradoresCreate() {
  const { user } = useContext(AuthContext);
  const URL_COMPRADORES = process.env.REACT_APP_URL_COMPRADORES;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const [name, setName] = useState("");
  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);
  const [clientes, setClientes] = useState([]);
  const [value, setValue] = useState("");

  useEffect(() => {
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveCompradores = (event) => {
    event.preventDefault();
    setValidaBoton(false);
    axios
      .post(
        URL_COMPRADORES,
        {
          name,
          clientes: value._id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Crear Comprador",
            detalle: name,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error}`,
        });
        console.log(error);
      });
  };

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  return (
    <>
      <div className="container">
        <h3 align="center">Crea una Comprador nuevo</h3>
        <Form onSubmit={saveCompradores}>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label for="exampleEmail" className="mr-sm-2">
              Comprador
            </Label>
            <Input
              type="text"
              name="email"
              id="exampleEmail"
              placeholder="Nombre del Comprador"
              value={name}
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
                <Label className="mr-sm-2">Cliente</Label>
                <Autocomplete
                  size="small"
                  value={value}
                  onChange={(event, selectedCliente) => {
                    setValue(selectedCliente);
                  }}
                  options={options.sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                  )}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.junta}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecciona"
                      variant="outlined"
                    />
                  )}
                  renderOption={(option) => (
                    <React.Fragment>{option.nombre_comercial}</React.Fragment>
                  )}
                />
          </FormGroup>
          <br />
          <Row className="container">
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <div>
              <SweetAlert
                show={text}
                title="Creado con Exito"
                onConfirm={() => {
                  setText(false);
                }}
              />
            </div>
            <Button color="danger" href="/MenuCatalogos">
              Regresar
            </Button>
            </Row>
        </Form>
      </div>
    </>
  );
}

export default CompradoresCreate;
