import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col, Spinner
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import BajaAltaMultiple from "../../BajaAltaMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";
import qrcode from "qrcode-js";

function ListadoComprasFaltante() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_COMPRAS = process.env.REACT_APP_URL_COMPRAS;
  const URL_COMPRAS_ACUMULADOS = process.env.REACT_APP_URL_COMPRAS_ACUMULADOS;
  const URL_INVENTARIOS_TELAS = process.env.REACT_APP_URL_INVENTARIOS_TELAS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");


  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [activo, setActivo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(`${URL_COMPRAS}Faltante`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCompras = response.data;
        // Array para pagination
        let arrayTabla = allCompras
          .sort((a, b) => (a.idCompras > b.idCompras ? 1 : -1))
          .map((a) => {
            if(a.ordenesCompra[0].tipo == "Tela"){
            return {
              activo: a.is_active,
              _id: a._id,
              numero: a.idCompras,
              fecha: a.fecha,
              observaciones: a.observaciones,
              kilos: a.total_kilos.toFixed(2),
              metros: a.total_metros.toFixed(2),
              piezas: a.total_piezas.toFixed(2),
              faltante: a.total_faltante.toFixed(2),
              factura_proveedor: a.factura_proveedor,
              iduser: a.user[0]._id,
              user: a.user[0].nombre + " " + a.user[0].apellido,
              pais_origen: a.pais_origen,
              idOrdenesCompra: a.ordenesCompra[0]._id,
              numeroOrden: a.ordenesCompra[0].idOrdenCompra,
              fecha_orden_compra: a.ordenesCompra[0].fecha,
              proveedores: a.ordenesCompra[0].proveedores[0].razon_social,
              idProveedores: a.ordenesCompra[0].proveedores[0]._id,
              idOrdenCompra: a.ordenesCompra[0]._id,
              tipo:a.ordenesCompra[0].tipo,
              observacionesOrden: a.ordenesCompra[0].observaciones,
              solicitoBonificacion: a.solicitoBonificacion
            };
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);

        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

   
  }, []);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.proveedores,
          a.factura_proveedor,
          a.pais_origen,
          a.numeroOrden,
          new Intl.NumberFormat("en-US").format(a.kilos),
          new Intl.NumberFormat("en-US").format(a.metros),
          new Intl.NumberFormat("en-US").format(a.piezas),
          a.user,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado de Compras`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Proveedor",
          "Factura Proveedor",
          "Pais Origen",
          "OC",
          "Kilos",
          "Metros",
          "Piezas",
          "Recibio",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Compras.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return {
          Activo: a.activo,
          Numero: a.numero,
          Fecha: a.fecha,
          Proveedores: a.proveedores,
          Factura: a.factura_proveedor,
          Pais: a.pais_origen,
          OC: a.numeroOrden,
          Kilos: a.kilos,
          Metros: a.metros,
          Piezas: a.piezas,
          Recibio: a.user,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoCompras";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoCompras",
        sheetFilter: [
          "Activo",
          "Numero",
          "Fecha",
          "Proveedor",
          "Factura",
          "Pais",
          "OC",
          "Kilos",
          "Metros",
          "Piezas",
          "Recibio",
        ],
        sheetHeader: [
          "Activo",
          "Numero",
          "Fecha",
          "Proveedor",
          "Factura",
          "Pais",
          "OC",
          "Kilos",
          "Metros",
          "Piezas",
          "Recibio",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.proveedores,
          a.factura_proveedor,
          a.pais_origen,
          a.numeroOrden,
          new Intl.NumberFormat("en-US").format(a.kilos),
          new Intl.NumberFormat("en-US").format(a.metros),
          new Intl.NumberFormat("en-US").format(a.piezas),
          a.user,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado de Compras`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Proveedor",
          "Factura Proveedor",
          "Pais Origen",
          "OC",
          "Kilos",
          "Metros",
          "Piezas",
          "Recibio",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado de Compras",
          email: mailTo,
          fileName: "ListadoCompras.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Compras.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }


  async function PDFOC(
    fecha,
    idProveedores,
    proveedores,
    kilos,
    metros,
    piezas,
    observaciones,
    numero,
    tipo,
    idPDFOC
  ) {
      
    await axios
    .get(`${URL_COMPRAS_ACUMULADOS}/compra/${idPDFOC}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allTelasCompra = response.data;
      let arrayTabla = allTelasCompra
      .sort((a, b) => (a.captura > b.captura ? 1 : -1))
        .map((a) => {
          return {
            id: a._id,
            telas:  a.telas[0].nombre,
            idTelas:  a.telas[0]._id,
            unidad: a.telas[0].unidad,
            cantidad: a.cantidad,
            faltante: a.faltante,
            precio: a.telasOC[0].precio,
          };
        })
        .filter(function (el) {
          return el != null;
        });

      let dataFinal = Object.values(arrayTabla);
      let rollos = dataFinal.length

      let agrupado = dataFinal.reduce(function (groups, item) {
        const val = item["idTelas"]
        groups[val] = groups[val] || {
          idTelas: item.idTelas,
          cantidad: 0,
          faltante: 0
        };
        groups[val].cantidad += item.cantidad;
        groups[val].faltante += item.faltante;
        groups[val].telas = item.telas;
        groups[val].unidad = item.unidad;
        groups[val].precio = item.precio;
        return groups;
      }, []);

      let agrupadoValues = Object.values(agrupado);


      let mts = agrupadoValues.filter((e) => e.unidad == "Metros");
      let totalMts = mts.map((c) => parseFloat(c.faltante));
      let TM = totalMts.reduce((t, total, index) => t + total, 0);

      let KG = agrupadoValues.filter((e) => e.unidad == "Kilos");
      let totalKG = KG.map((c) => parseFloat(c.faltante));
      let TK = totalKG.reduce((t, total, index) => t + total, 0);

      let PZ = agrupadoValues.filter((e) => e.unidad == "Piezas");
      let totalPZ = PZ.map((c) => parseFloat(c.faltante));
      let TP = totalPZ.reduce((t, total, index) => t + total, 0);


      let total_importe = 0;
      const data = agrupadoValues.map((a) => {
          total_importe = total_importe + a.precio * a.faltante;
          return [
            a.telas,
            a.unidad,
            new Intl.NumberFormat("en-US").format(a.faltante),
            "$" + new Intl.NumberFormat("en-US").format(a.precio),
            "$" +
              new Intl.NumberFormat("en-US").format(a.precio * a.faltante),
          ]
      });
      const dataPDF = Object.values(data);
      const dataPDFLimpia = dataPDF.filter(function (el) {
        return el != null;
      });
      const doc = new jsPDF("landscape");
      var img = new Image();
      img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
      doc.addImage(img, "png", 230, 30, 45, 15);
      var img2 = new Image();
      img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
      doc.addImage(img2, "png", 120, 15, 60, 25);
      doc.text(`Faltante de Compra # ${numero}`, 20, 20);
      doc.setFontSize(12);
      doc.text(`Fecha ${fecha}`, 20, 25);
      doc.text(`Proveedor ${proveedores}`, 20, 30);
      doc.text(
        `Metros ${new Intl.NumberFormat("en-US").format(TM)} / Kilos ${new Intl.NumberFormat("en-US").format(TK)} / Piezas ${new Intl.NumberFormat("en-US").format(TP)}`,
        20,
        35
      );
      doc.autoTable({
        head: [
          ["Articulo", "Unidad", "Faltante", "Precio", "Total"],
        ],
        body: dataPDFLimpia,
        startY: 45,
        foot: [
          [
            "",
            "",
            "",
            "Totales",
            "$" + new Intl.NumberFormat("en-US").format(total_importe),
          ],
        ],
        showFoot: "lastPage",
      });
      doc.save(`FaltanteCompra-${numero}.pdf`);
    })
    .catch((err) => {
      console.log(err);
    });
  }



  const headers = [
    { name: "Activo", field: "activo", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "OC", field: "numeroOrden", sortable: true },
    { name: "Observaciones OC", field: "observacionesOrden", sortable: true },
    { name: "Proveedor", field: "proveedores", sortable: true },
    { name: "Kilos", field: "kilos", sortable: true },
    { name: "Metros", field: "metros", sortable: true },
    { name: "Piezas", field: "piezas", sortable: true },
    { name: "Faltante", field: "faltante", sortable: true },
    { name: "Recibio", field: "user", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.activo.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedores.toLowerCase().includes(search.toLowerCase()) ||
          comment.user.toLowerCase().includes(search.toLowerCase()) ||
          comment.observacionesOrden.toLowerCase().includes(search.toLowerCase()) ||
          comment.numeroOrden.toString().includes(search)||
          comment.kilos.toString().includes(search)||
          comment.metros.toString().includes(search)||
          comment.piezas.toString().includes(search)||
          comment.faltante.toString().includes(search)||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedProveedor) {
      computedComments = computedComments.filter((e) =>
        e.idProveedores.includes(selectedProveedor)
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field && sorting.field != "kilos" && sorting.field != "metros" && sorting.field != "piezas" && sorting.field != "numeroOrden" && sorting.field != "numero" && sorting.field != "faltante") {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (sorting.field && sorting.order === "asc" && (sorting.field == "kilos" || sorting.field == "metros" || sorting.field == "piezas" || sorting.field == "numeroOrden" || sorting.field == "numero" || sorting.field == "faltante")) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (sorting.field && sorting.order === "desc" && (sorting.field == "kilos" || sorting.field == "metros" || sorting.field == "piezas" || sorting.field == "numeroOrden" || sorting.field == "numero" || sorting.field == "faltante")) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedProveedor,
    activo,
    selectedFechaInicio,
    selectedFechaFin,
  ]);


  function baja(id) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se marcara como No Bonificado",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
        .patch(
          `${URL_COMPRAS}/${id}`,
          {
            solicitoBonificacion: "No",
          },
          {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se Guardo",
              showConfirmButton: false,
              timer: 2000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function alta(id) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se marcara como Bonificado",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_COMPRAS}/${id}`,
            {
              solicitoBonificacion: "Si",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se Guardo",
              showConfirmButton: false,
              timer: 2000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_compras ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {/* {user.compras_create ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ComprasCreate"
                >
                  Nueva Compra
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nueva Compra
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuCompras"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button> */}
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Ordenes de Compra</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Faltantes en Compras</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Proveedores</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedProveedor}
                onChange={(e) => {
                  setSelectedProveedor(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {proveedores
                  .sort((a, b) => (a.razon_social > b.razon_social ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.razon_social}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={activo}
                required
                onChange={(e) => {
                  setActivo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.activo}</td>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.numeroOrden}</td>
                      <td>{a.observacionesOrden}</td>
                      <td>{a.proveedores}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.kilos)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.metros)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.piezas)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.faltante)}</td>
                      <td>{a.user}</td>
                      <td>
                        {user.compras_create ? (
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.fecha,
                                  a.idProveedores,
                                  a.proveedores,
                                  a.kilos,
                                  a.metros,
                                  a.piezas,
                                  a.observaciones,
                                  a.numero,
                                  a.tipo,
                                  a._id
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>
                        ) : (
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="far fa-edit"></i>
                            </Button>
                        )}
                        {a.solicitoBonificacion == "Si"?(
                           <Button
                           color="primary"
                           id="Baja"
                           onClick={e=>baja(a._id)}
                           size="sm"
                         >
                         <i class="far fa-check-circle"></i>
                         </Button>
                        ):(
                          <Button color="danger" onClick={e=>alta(a._id)} size="sm">
                          <i class="far fa-times-circle"></i>
                        </Button>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />


      {loader}
    </>
  );
}

export default ListadoComprasFaltante;
