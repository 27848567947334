import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function ComplementosPagoCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_SERIES = process.env.REACT_APP_URL_SERIES;
  const URL_TIMBRAR = process.env.REACT_APP_URL_TIMBRAR;
  const URL_FACTURAS = process.env.REACT_APP_URL_FACTURAS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_EMPRESAS = process.env.REACT_APP_URL_EMPRESAS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_CUENTAS_CLIENTES = process.env.REACT_APP_URL_CUENTAS_CLIENTES;
  const URL_COMP_PAGO = process.env.REACT_APP_URL_COMP_PAGO;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [fecha, setFecha] = useState(hoy);
  const [observaciones, setObservaciones] = useState("NA");
  
  const [timbresDisponibles, setTimbresDisponibles] = useState(0);
  
  const [total, setTotal] = useState(0);

  const [facturas, setFacturas] = useState([]);

  const [clientes, setClientes] = useState([]);
  // const [cuentasClientes, setCuentasClientes] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  // const [selectedCuentaCliente, setSelectedCuentaCliente] = useState("");
  const [selectedEmpresa, setSelectedEmpresa] = useState("");
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [formaPagoSAT, setFormaPagoSAT] = useState("");


  const [cpEmpresa, setCpEmpresa] = useState("");
  const [rfcEmpresa, setRfcEmpresa] = useState("");
  const [nombreEmpresa, setNombreEmpresa] = useState("");
  const [regFiscalEmpresa, setRegFiscalEmpresa] = useState("");


  const [serie, setSerie] = useState("P")

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
    .get(`${URL_CLIENTES}`, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(`${URL_SERIES}Timbres`, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allTimbres = res.data;
          setTimbresDisponibles(allTimbres[0].folio);
        })
        .catch((err) => {
          console.log(err);
        });
     
      axios
      .get(`${URL_EMPRESAS}`, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allEmpresas = res.data;
          setEmpresas(allEmpresas);
        })
        .catch((err) => {
          console.log(err);
        });
        axios
        .get(`${URL_COLABORADORES}`, { 
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((res) => {
            const allColaboradores = res.data;
            setColaboradores(allColaboradores);
          })
          .catch((err) => {
            console.log(err);
          });
  }, []);

  const saveComplemento = async (event) => {
    event.preventDefault();
    if(timbresDisponibles > 0){
    Swal.fire({
      title: "Estas seguro?",
      text: "Se guardará el complemento!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, guardar!",
    }).then(async (result) => {
    if (
      result.isConfirmed &&
      total > 0
    ) {
      setValidaBoton(false);
      toggleProgreso();
     
      let totalArticulos =
        inputFields.length 
      try {
        await axios
          .post(
            URL_COMP_PAGO,
            {
              fecha,
              clientes: selectedCliente,
              // cuentasClientes: selectedCuentaCliente,
              colaboradores: selectedColaborador,
              empresas: selectedEmpresa,
              observaciones,

              timbre:"No",
              cfdiType:"P",

              serie,
              expeditionPlace: cpEmpresa,
              
              rfcEmpresa,
              nombreEmpresa,
              regFiscalEmpresa,
              cfdiUse:"CP01",

              paymentForm: formaPagoSAT,

              total,
              currency: "MXN",

            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {

            inputFields.map((w) => {
              axios
                .patch(
                  `${URL_FACTURAS}Pago/${w.facturas}`,
                  {
                    compPago: data.data._id,
                    abonos: w.impPagar,
                    fecha
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {

                    axios.post(`${URL_TIMBRAR}CompPago`,{
                      compPago: data.data._id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    ).then(()=>{

                      axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Complemento de Pago",
                          detalle: `Fecha Complemento de Pago ${fecha} ${data.data.idCompPago}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });

                    }).catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            });

          
          })
          // Catch primer then
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data.message}`,
            });
            console.log(error);
            setValidaBoton(true);
            setModalProgreso(false)
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          // footer: `${error.response.data}`,
        });
        console.log(error);
        setValidaBoton(true);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
      setValidaBoton(true);
    }
  })}else{
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
      footer: `No cuenta con timbres disponibles`,
    });
    setValidaBoton(true);
    setModalProgreso(false)
  }
  };

  
  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        facturas: "",
        idFactura: "",
        total: 0,
        abonos: 0,
        saldo: 0,
        impPagar: 0,
      },
    ]);
  };


  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);

    let din = values.map((c) => parseFloat(c.impPagar));
    let TD = din.reduce((t, total, index) => t + total, 0);
    setTotal(TD);
  };



  function BuscaCliente(event) {
    setSelectedCliente(event.target.value)

    // axios
    // .get(`${URL_CUENTAS_CLIENTES}/cliente/${event.target.value}`, { 
    //     headers: {
    //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //     },
    //   })
    //   .then((res) => {
    //     const allCuentasClientes = res.data;
    //     setCuentasClientes(allCuentasClientes);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    clientes.map((a)=>{
      if(a._id == event.target.value){
      setFormaPagoSAT(a.formaPagoSAT)
    }
    })

    setInputFields(
    [{
      id: uuidv4(),
      facturas: "",
      idFactura: "",
      total: 0,
      abonos: 0,
      saldo: 0,
      impPagar: 0,
    }]
    );
    if(event.target.value != "" && selectedColaborador != "" && selectedEmpresa != ""){
      axios
      .get(`${URL_FACTURAS}CompPago/${event.target.value}/${selectedColaborador}/${selectedEmpresa}`, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allFacturas = res.data;
          let arrayTabla = allFacturas.map((a)=>{
            return {
              id: uuidv4(),
              facturas: a._id,
              idFactura: a.idFactura,
              total: a.total,
              abonos: a.abonos,
              saldo: a.saldo,
              impPagar: a.saldo,
            }
          })
          let dataFinal = Object.values(arrayTabla);
          setFacturas(dataFinal);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function BuscaEmpresa(event) {
    setSelectedEmpresa(event.target.value)

    empresas.map((a)=>{
      if(a._id == event.target.value){
        setCpEmpresa(a.cp)
        setRfcEmpresa(a.rfc)
        setNombreEmpresa(a.razonSocial)
        setRegFiscalEmpresa(a.regimenFiscal)
      }
    })
    setInputFields(
    [{
      id: uuidv4(),
      facturas: "",
      idFactura: "",
      total: 0,
      abonos: 0,
      saldo: 0,
      impPagar: 0,
    }]
    );
    if(event.target.value != "" && selectedColaborador != "" && selectedCliente != ""){
      axios
      .get(`${URL_FACTURAS}CompPago/${selectedCliente}/${selectedColaborador}/${event.target.value}`, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allFacturas = res.data;
          let arrayTabla = allFacturas.map((a)=>{
            return {
              id: uuidv4(),
              facturas: a._id,
              idFactura: a.idFactura,
              total: a.total,
              abonos: a.abonos,
              saldo: a.saldo,
              impPagar: a.saldo,
            }
          })
          let dataFinal = Object.values(arrayTabla);
          setFacturas(dataFinal);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function BuscaColaborador(event) {
    setSelectedColaborador(event.target.value)
    setInputFields(
    [{
      id: uuidv4(),
      facturas: "",
      idFactura: "",
      total: 0,
      abonos: 0,
      saldo: 0,
      impPagar: 0,
    }]
    );
    if(event.target.value != "" && selectedCliente != "" && selectedEmpresa != ""){
      axios
      .get(`${URL_FACTURAS}CompPago/${selectedCliente}/${event.target.value}/${selectedEmpresa}`, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allFacturas = res.data;
          let arrayTabla = allFacturas.map((a)=>{
            return {
              id: uuidv4(),
              facturas: a._id,
              idFactura: a.idFactura,
              total: a.total,
              abonos: a.abonos,
              saldo: a.saldo,
              impPagar: a.saldo,
            }
          })
          let dataFinal = Object.values(arrayTabla);
          setFacturas(dataFinal);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function BuscaFactura(id, event) {
    if (
      inputFields.filter((e) => e.facturas == event.target.value).length >
      0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El articulo ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.facturas = "";
        }
        return i;
      });
      setInputFields(newInputFields);
    } else {
      facturas.map((a) => {
        if (a.facturas == event.target.value) {
          let facturas = a.facturas;
          let idFactura = a.idFactura;
          let total = a.total
          let abonos = a.abonos
          let saldo = a.saldo
          handleChangeInputArticulo(id, facturas, idFactura, total, abonos, saldo);
        }
      });
    }
  }

  const handleChangeInputArticulo = (id, facturas, idFactura, total, abonos, saldo) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.facturas = facturas;
        i.idFactura = idFactura;
        i.abonos = abonos
        i.saldo = saldo
        i.total = total
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };


  function ActualizaTotales() {

    let din = inputFields.map((c) => parseFloat(c.impPagar));
    let TD = din.reduce((t, total, index) => t + total, 0);
    setTotal(TD);

  }

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if(i.saldo >= event.target.value){
          i.impPagar = parseFloat(event.target.value);
        }
        else{
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo!",
            showConfirmButton: false,
          });
        }
      }
      return i;
    });
    setInputFields(newInputFields);

    ActualizaTotales();
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_admin ? (
        <div className="card container col-10">
          <h3 align="center">Nuevo Complemento de Pago</h3>
          <Form onSubmit={saveComplemento}>
          <Row>
            <Col md={2}>
                <Label>Timbres Disponibles</Label>
                <Input
                  type="text"
                  value={timbresDisponibles}
                  required
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  disabled
                />
              </Col>
              <Col md={2}>
                <Label>Empresa</Label>
                    <Input
                      name="empresa"
                      type="select"
                      value={selectedEmpresa}
                      required
                      onChange={(event) => {
                        BuscaEmpresa(event);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {empresas
                        .sort((a, b) =>
                        a.name > b.name ? 1 : -1
                      )
                      .map((a) => {
                        if(a.listoFacturar == "Si" && a.is_active == "Si"){
                          return (
                            <option value={a._id}>{a.name}</option>
                          );}
                      })}
                    </Input>
                  </Col>

                  <Col md={2}>
                <Label>Cliente</Label>
                    <Input
                      type="select"
                      value={selectedCliente}
                      required
                      onChange={(event) => {
                        BuscaCliente(event);
                      }}
                    >
                      <option value="">Selecciona un Cliente</option>
                      {clientes
                        .sort((a, b) =>
                        a.nombre_comercial > b.nombre_comercial ? 1 : -1
                      )
                      .map((a) => {
                          return (
                            <option value={a._id}>{a.nombre_comercial}</option>
                          );
                      })}
                    </Input>
                  </Col>

                  <Col md={2}>
                <Label>Vendedor</Label>
                    <Input
                      type="select"
                      value={selectedColaborador}
                      required
                      onChange={(event) => {
                        BuscaColaborador(event);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {colaboradores
                        .sort((a, b) =>
                        a.nombre > b.nombre ? 1 : -1
                      )
                      .map((a) => {
                          return (
                            <option value={a._id}>{a.nombre} {a.apellido} </option>
                          );
                      })}
                    </Input>
                  </Col>

                  {/* <Col md={2}>
                <Label>Cuenta de Pago</Label>
                    <Input
                      type="select"
                      value={selectedCuentaCliente}
                      required
                      onChange={(event) => {
                        setSelectedCuentaCliente(event.target.value);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {cuentasClientes
                        .sort((a, b) =>
                        a.banco > b.banco ? 1 : -1
                      )
                      .map((a) => {
                          return (
                            <option value={a._id}>{a.banco} {a.cuenta}</option>
                          );
                      })}
                    </Input>
                  </Col> */}
             </Row>

             <Row>
             <Col md={2}>
                <Label>Forma Pago</Label>
                <Input
                  type="select"
                  value={formaPagoSAT}
                  required
                  onChange={(e) => {
                    setFormaPagoSAT(e.target.value);
                  }}
                >
              <option value="">Selecciona</option>
              <option value="01"> 01 Efectivo</option>
              <option value="02"> 02 Cheque nominativo</option>
              <option value="03"> 03 Transferencia electrónica de fondos</option>
              <option value="04"> 04 Tarjeta de crédito</option>
              <option value="05"> 05 Monedero electrónico</option>
              <option value="06"> 06 Dinero electrónico</option>
              <option value="08"> 08 Vales de despensa</option>
              <option value="12"> 12 Dación en pago</option>
              <option value="13"> 13 Pago por subrogación</option>
              <option value="14"> 14 Pago por consignación</option>
              <option value="15"> 15 Condonación</option>
              <option value="17"> 17 Compensación</option>
              <option value="23"> 23 Novación</option>
              <option value="24"> 24 Confusión</option>
              <option value="25"> 25 Remisión de deuda</option>
              <option value="26"> 26 Prescripción o caducidad</option>
              <option value="27"> 27 A satisfacción del acreedor</option>
              <option value="28"> 28 Tarjeta de débito</option>
              <option value="29"> 29 Tarjeta de servicios</option>
              <option value="30"> 30 Aplicación de anticipos</option>
              <option value="99"> 99 Por definir</option>
                </Input>
              </Col>
              <Col md={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>

            {/* Tabla Articulos */}

            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Factura</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Abonos</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Saldo</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Importe a Pagar</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="facturas"
                      type="select"
                      value={inputField.facturas}
                      required
                      onChange={(event) => {
                        BuscaFactura(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {facturas
                      .sort((a, b) => (a.idFactura > b.idFactura ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a.facturas}>
                            {a.idFactura}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="total"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.total}
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="abonos"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.abonos}
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="saldo"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.saldo}
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="impPagar"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.impPagar}
                      onChange={(e) => {
                        handleChangeInput(inputField.id, e);
                      }}
                    />
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <br />
            <br />
            <Row>
           
            <Col md={3}>
            <Label className="mr-sm-2">Total</Label>
            <Input
              type="number"
              value={total}
              disabled
            />
            </Col>
            </Row>

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoComplementosPago"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ComplementosPagoCreate;
