import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import TallasCreate from "./TallasCreate";
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import ExportJsonExcel from "js-export-excel";
import * as XLSX from "xlsx";
import { v4 as uuidv4 } from "uuid";

function ListadoTallas() {
  const { user } = useContext(AuthContext);
  const URL_TALLAS = process.env.REACT_APP_URL_TALLAS;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const [tallas, setTallas] = useState([]);
  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");
  const toggle = () => setModal(!modal);
  const [text, setText] = useState(false);
  const [totalCaptura, setTotalCaptura] = useState(1);

  const [idEdit, setIdEdit] = useState();

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      idTalla: "",
      idBD: "",
    },
  ]);

  useEffect(() => {
    axios
      .get(URL_TALLAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allTallas = response.data;
        setTallas(allTallas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function editTalla(event) {
    event.preventDefault();
    const URL_TALLAS_EDIT = `${process.env.REACT_APP_URL_TALLAS}/${idEdit}`;
    axios
      .patch(
        URL_TALLAS_EDIT,
        {
          name,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Talla",
            detalle: name,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        toggle();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(idEdit, name) {
    setName(name);
    setIdEdit(idEdit);
    toggle();
  }

  function excel() {
    const dataExcel = tallas.map((a) => {
      return {
        Id: a._id,
        Nombre: a.name,
      };
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoTallas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoTallas",
        sheetFilter: ["Id", "Nombre"],
        sheetHeader: ["Id", "Nombre"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  // function importarArchivo(event){
  //   const target = event.target;
  //   const value = target.type === "checkbox" ? target.checked : target.value;
  //   const name = target.name;
  //   let hojas = [];
   
  //   if (name === "file") {
  
  //     let reader = new FileReader();
  //       reader.readAsArrayBuffer(target.files[0]);
  //       reader.onloadend = (e) => {
  //         var data = new Uint8Array(e.target.result);
  //         var workbook = XLSX.read(data, { type: "array" });
  
  //         workbook.SheetNames.forEach(function (sheetName) {
  //           // Here is your object
  //           var XL_row_object = XLSX.utils.sheet_to_json(
  //             workbook.Sheets[sheetName]
  //           );
  //           hojas.push({
  //             data: XL_row_object,
  //             sheetName,
  //           });
  //         });
  
  //         let A1 = hojas[0].data
  
  //         let TC = totalCaptura
  //         A1.map((a)=>{
  //            TC = TC + 1
  //           setTotalCaptura(TC)
  //         inputFields.push({
  //           id: uuidv4(),
  //           idBD: a.Id,
  //           idTalla: a.idTalla
  //         })

  //               inputFields.map(async(a)=>{
  //       await axios
  //       .patch(
  //         `${URL_TALLAS}/${a.idBD}`,
  //         {
  //           idTalla: a.idTalla,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //           },
  //         }
  //       )
  //       .then(() => {
  //         Swal.fire("Good job!", "Creado con exito", "success");
  //       })
  //       .catch((error) => {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Oops...",
  //           text: "Something went wrong!",
  //           footer: `${error.response.data}`,
  //         });
  //         console.log(error);
  //       })
  //     })
  //         })
  
  //       }
  //   }
  // }

  return (
    <>
      <Header />
      <br />
      <br />
      <div className="container">
        {user.menu_articulos ? (
          <div className="row">
            {
              <div className="col-md-6 col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h3 align="center">Tallas</h3>
                    {/* <Row>
                      <Col sm={6}>
                        <Button
                          size="sm"
                          className="btn"
                          color="primary"
                          onClick={excel}
                        >
                          Excel <i class="far fa-file-excel"></i>
                        </Button>
                      </Col>
                      <Col sm={6}>
                        <Input
                          required
                          type="file"
                          name="file"
                          id="file"
                          onChange={(event) => importarArchivo(event)}
                        />
                      </Col>
                    </Row> */}

                    <Table
                      size="sm"
                      striped
                      borderless
                      className="table-responsive-xl"
                    >
                      <thead>
                        <tr>
                          <th className="tituloTabla">Nombre</th>
                          <th className="tituloTabla">Editar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tallas
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                            return (
                              <tr>
                                <td>{a.name}</td>
                                <td>
                                  <Button
                                    color="info"
                                    id="Editar"
                                    size="sm"
                                    onClick={(e) => jalaInfo(a._id, a.name)}
                                  >
                                    <i class="far fa-edit"></i>{" "}
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            }
            <div className="col-md-6 col-sm-6">
              <div className="card">
                <div className="card-body">
                  <TallasCreate />
                </div>
              </div>
            </div>
          </div>
        ) : undefined}
      </div>
      <Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Editar Talla</ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Nombre</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={name}
            required
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editTalla}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ListadoTallas;
