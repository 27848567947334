import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesArticulos() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_articulos ?(
      <div className="container">
        <br />
        <br />
        <Row >
      
          {/* <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/ListadoArticulosPadre" className="botonesMenu" color="success">
              <i class="fas fa-tshirt fa-7x"></i>
              <br />
              <br />
              Articulos Padre
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-tshirt fa-7x"></i>
            <br />
            <br />
            Articulos Padre
          </Button> }
          </Col> */}
          {/* <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/Dibujos" className="botonesMenu" color="success">
              <i class="fas fa-bars fa-7x"></i>
              <br />
              <br />
              Dibujos
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-bars fa-7x"></i>
            <br />
            <br />
            Dibujos
          </Button> }
            </Col> */}
            <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/Marcas" className="botonesMenu" color="success">
              <i class="far fa-copyright fa-7x"></i>
              <br />
              <br />
              Marcas
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="far fa-copyright fa-7x"></i>
            <br />
            <br />
            Marcas
          </Button> }
            </Col>
            <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/Colecciones" className="botonesMenu" color="success">
              <i class="fas fa-calendar-alt fa-7x"></i>
              <br />
              <br />
              Colecciones
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-calendar-alt fa-7x"></i>
            <br />
            <br />
            Colecciones
          </Button> }
            </Col>
          </Row>
          <br />
         <Row>
         <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/ListadoDepartamentos" className="botonesMenu" color="success">
              <i class="fas fa-list-ul fa-7x"></i>
              <br />
              <br />
              Departamentos
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-list-ul fa-7x"></i>
            <br />
            <br />
            Departamentos
          </Button> }
          </Col>
          <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/ListadoSubDepartamentos" className="botonesMenu" color="success">
              <i class="fas fa-list-ul fa-7x"></i>
              <br />
              <br />
              Sub Departamentos
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-list-ul fa-7x"></i>
            <br />
            <br />
            Sub Departamentos
          </Button> }
          </Col>
          <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/ListadoClases" className="botonesMenu" color="success">
              <i class="fas fa-bars fa-7x"></i>
              <br />
              <br />
              Clases
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-bars fa-7x"></i>
            <br />
            <br />
            Clases
          </Button> }
          </Col>
          </Row>
        <br />
          <Row>
          <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/ListadoSubClases" className="botonesMenu" color="success">
              <i class="fas fa-bars fa-7x"></i>
              <br />
              <br />
              Etiquetas
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-bars fa-7x"></i>
            <br />
            <br />
            Etiquetas
          </Button> }
          </Col>
          <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/ListadoTallas" className="botonesMenu" color="success">
              <i class="fas fa-ruler-horizontal fa-7x"></i>
              <br />
              <br />
              Tallas
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-ruler-horizontal fa-7x"></i>
            <br />
            <br />
            Tallas
          </Button> }
            </Col>
            <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/ListadoArticulos" className="botonesMenu" color="success">
              <i class="fas fa-tshirt fa-7x"></i>
              <br />
              <br />
              Articulos
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-tshirt fa-7x"></i>
            <br />
            <br />
            Articulos
          </Button> }
          </Col>
         </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesArticulos;
