import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function ComprasDirectasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_TALLAS = process.env.REACT_APP_URL_TALLAS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_ORDENES_COMPRA_DIRECTAS = process.env.REACT_APP_URL_ORDENES_COMPRA_DIRECTAS;
  const URL_COMPRAS_DIRECTAS = process.env.REACT_APP_URL_COMPRAS_DIRECTAS;
  const URL_ARTICULOS_OC_DIRECTAS = process.env.REACT_APP_URL_ARTICULOS_OC_DIRECTAS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_COMPRAS_DIRECTAS_ACUMULADOS = process.env.REACT_APP_URL_COMPRAS_DIRECTAS_ACUMULADOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [ordenesCompra, setOrdenesCompra] = useState([]);
  const [selectedOrdenCompra, setSelectedOrdenCompra] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [colores, setColores] = useState([]);
  const [tallas, setTallas] = useState([]);
  const [proveedores, setProveedores] = useState([]);

  const [fecha, setFecha] = useState(hoy);
  const [factura_proveedor, setFacturaProveedor] = useState("NA");
  const [observaciones, setObservaciones] = useState("NA");
  const [pais_origen, setPaisOrigen] = useState("Mexico");
  const [total_kilos, setTotalKilos] = useState(0);
  const [total_metros, setTotalMetros] = useState(0);
  const [total_piezas, setTotalPiezas] = useState(0);
  const [total_dinero, setTotalDinero] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [impuestos, setImpuestos] = useState(false);
  const [impuestosString, setImpuestosString] = useState("No");
  const [impuestosPor, setImpuestosPor] = useState(16);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");

  const [articulosOC, setArticulosOC] = useState([]);
  const [articulosOC2, setArticulosOC2] = useState([]);
  const [fecha_orden_compra, setFechaOrdenCompra] = useState("");
  const [proveedorOrden, setProveedorOrden] = useState("");
  const [fecha_compromiso, setFechaCompromiso] = useState("");
  const [total_kilos_orden, setTotalKilosOrden] = useState(0);
  const [total_metros_orden, setTotalMetrosOrden] = useState(0);
  const [total_piezas_orden, setTotalPiezasOrden] = useState(0);

  const [tipo, setTipo] = useState("");
  const [observacionesOrden, setObservacionesOrden] = useState("");

  const [totalCaptura, setTotalCaptura] = useState(1);
  const [cantidadAgrupado, setCantidadAgrupado] = useState([]);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulosOrden: "",
      articulos: "",
      articulosName: "",
      colores: "",
      coloresName: "",
      tallas: "",
      tallasName: "",
      ubicacion: "NA",
      cantidad: 0,
      costo: 0,
      total: 0,
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_ORDENES_COMPRA_DIRECTAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allOrdenesCompra = res.data;
        setOrdenesCompra(allOrdenesCompra);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColores = res.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_TALLAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTallas = res.data;
        setTallas(allTallas);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAreas = response.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveCompraNueva = async (event) => {
    let subtotalGuarda = subtotal
    let ivaGuarda = iva
    event.preventDefault();
    if (
      fecha != "" &&
      observaciones != "" &&
      factura_proveedor != "" &&
      pais_origen != ""&&
      selectedArea != ""
    ) {
      let piezasTemp = total_piezas_orden * 1.1
      let piezasTemp2 = total_piezas_orden * .9
      if (
        (total_piezas > piezasTemp) ||
        (total_piezas < piezasTemp2) 
        ) {
        Swal.fire({
          title: "Hay una diferencia por mas del 10% de la Orden de Compra!",
          text: `Debes de solicitar autorizacion`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Recibir!",
        }).then(async (result) => {
          if (result.isConfirmed) {

            setValidaBoton(false);
            toggleProgreso();

            let totalRollos =
              inputFields.length +
              cantidadAgrupado.length +
              cantidadAgrupado.length;
            try {
              await axios
                .post(
                  URL_COMPRAS_DIRECTAS,
                  {
                    fecha,
                    ordenesCompraDirecta: selectedOrdenCompra,
                    factura_proveedor,
                    observaciones,
                    total_piezas,
                    user: user.id,
                    pais_origen,
                    total_costo: total_dinero.toFixed(2),
                    saldo: total_dinero.toFixed(2),
                    proveedores: proveedorOrden,
                    subtotal: subtotalGuarda,
                    iva: ivaGuarda,
                    documentado: "No"
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then((data) => {
                  inputFields.map((a) => {
                    if (a.cantidad != 0) {
                      axios
                        .post(
                          URL_INVENTARIOS,
                          {
                            articulos: a.articulos,
                            colores: a.colores,
                            tallas: a.tallas,
                            cantidad: parseFloat(a.cantidad),
                            segundas:0,
                            ubicacion: a.ubicacion,
                            areas: selectedArea
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          totalRollos = totalRollos - 1;
                          if (totalRollos == 0) {
                            axios
                              .post(
                                URL_LOGS,
                                {
                                  tipo: "Crear Compra",
                                  detalle: `OC ${selectedOrdenCompra}`,
                                  user: user.id,
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer: ${localStorage.getItem(
                                      "app_token"
                                    )}`,
                                  },
                                }
                              )
                              .then(() => {
                                Swal.fire(
                                  "Good job!",
                                  "Creado con exito",
                                  "success"
                                );
                                setTimeout(() => {
                                  window.location.reload();
                                }, 2000);
                              })
                              .catch((error) => {
                                Swal.fire({
                                  icon: "error",
                                  title: "Oops...",
                                  text: "Something went wrong!",
                                  footer: `${error.response.data}`,
                                });
                                console.log(error);
                                setValidaBoton(true);
                              });
                          }
                        });
                    } else {
                      totalRollos = totalRollos - 1;
                      if (totalRollos == 0) {
                        axios
                          .post(
                            URL_LOGS,
                            {
                              tipo: "Crear Compra",
                              detalle: `OC ${selectedOrdenCompra}`,
                              user: user.id,
                            },
                            {
                              headers: {
                                Authorization: `Bearer: ${localStorage.getItem(
                                  "app_token"
                                )}`,
                              },
                            }
                          )
                          .then(() => {
                            Swal.fire(
                              "Good job!",
                              "Creado con exito",
                              "success"
                            );
                            setTimeout(() => {
                              window.location.reload();
                            }, 2000);
                          })
                          .catch((error) => {
                            Swal.fire({
                              icon: "error",
                              title: "Oops...",
                              text: "Something went wrong!",
                              footer: `${error.response.data}`,
                            });
                            console.log(error);
                            setValidaBoton(true);
                          });
                      }
                    }
                  });
                  inputFields.map((w) => {
                    axios
                      .patch(
                        `${URL_ARTICULOS_OC_DIRECTAS}editRecibido/${w.idArticulosOrden}`,
                        {
                          cantidad: w.cantidad,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        totalRollos = totalRollos - 1;
                        if (totalRollos == 0) {
                          axios
                            .post(
                              URL_LOGS,
                              {
                                tipo: "Crear Compra",
                                detalle: `OC ${selectedOrdenCompra}`,
                                user: user.id,
                              },
                              {
                                headers: {
                                  Authorization: `Bearer: ${localStorage.getItem(
                                    "app_token"
                                  )}`,
                                },
                              }
                            )
                            .then(() => {
                              Swal.fire(
                                "Good job!",
                                "Creado con exito",
                                "success"
                              );
                              setTimeout(() => {
                                window.location.reload();
                              }, 2000);
                            })
                            .catch((error) => {
                              Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Something went wrong!",
                                footer: `${error.response.data}`,
                              });
                              console.log(error);
                              setValidaBoton(true);
                            });
                        }
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                      });
                  });

                  // Pegamos a Compras Acumulado

                  inputFields.map((w) => {
                    axios
                      .post(
                        `${URL_COMPRAS_DIRECTAS_ACUMULADOS}`,
                        {
                          comprasDirectas: data.data._id,
                          articulos: w.articulos,
                          articulosOC: w.idArticulosOrden,
                          colores: w.colores,
                          tallas: w.tallas,
                          cantidad: w.cantidad,
                          ordenesCompraDirecta: selectedOrdenCompra,
                          fecha,
                          proveedores: proveedorOrden,
                          total: w.total,
                          precio: w.precio,
                          faltante: 0
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        totalRollos = totalRollos - 1;
                        if (totalRollos == 0) {
                          axios
                            .post(
                              URL_LOGS,
                              {
                                tipo: "Crear Compra",
                                detalle: `OC ${selectedOrdenCompra}`,
                                user: user.id,
                              },
                              {
                                headers: {
                                  Authorization: `Bearer: ${localStorage.getItem(
                                    "app_token"
                                  )}`,
                                },
                              }
                            )
                            .then(() => {
                              Swal.fire(
                                "Good job!",
                                "Creado con exito",
                                "success"
                              );
                              setTimeout(() => {
                                window.location.reload();
                              }, 2000);
                            })
                            .catch((error) => {
                              Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Something went wrong!",
                                footer: `${error.response.data}`,
                              });
                              console.log(error);
                              setValidaBoton(true);
                            });
                        }
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                      });
                  });

                  // Terminamos de post a compras acumulado
                });
            } catch (error) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            }
          } 
        });
      }
      else{
        setValidaBoton(false);
        toggleProgreso();

        let totalRollos =
          inputFields.length +
          cantidadAgrupado.length +
          cantidadAgrupado.length;
        try {
          await axios
            .post(
              URL_COMPRAS_DIRECTAS,
              {
                fecha,
                    ordenesCompraDirecta: selectedOrdenCompra,
                    factura_proveedor,
                    observaciones,
                    total_piezas,
                    user: user.id,
                    pais_origen,
                    total_costo: total_dinero.toFixed(2),
                    saldo: total_dinero.toFixed(2),
                    proveedores: proveedorOrden,
                    subtotal: subtotalGuarda,
                    iva: ivaGuarda,
                    documentado: "No"
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem(
                    "app_token"
                  )}`,
                },
              }
            )
            .then((data) => {
              inputFields.map((a) => {
                if (a.articulos != "" && a.cantidad != 0) {
                  axios
                    .post(
                      URL_INVENTARIOS,
                      {
                        articulos: a.articulos,
                        colores: a.colores,
                        tallas: a.tallas,
                        cantidad: parseFloat(a.cantidad),
                        segundas:0,
                        ubicacion: a.ubicacion,
                        areas: selectedArea
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      totalRollos = totalRollos - 1;
                      if (totalRollos == 0) {
                        axios
                          .post(
                            URL_LOGS,
                            {
                              tipo: "Crear Compra",
                              detalle: `OC ${selectedOrdenCompra}`,
                              user: user.id,
                            },
                            {
                              headers: {
                                Authorization: `Bearer: ${localStorage.getItem(
                                  "app_token"
                                )}`,
                              },
                            }
                          )
                          .then(() => {
                            Swal.fire(
                              "Good job!",
                              "Creado con exito",
                              "success"
                            );
                            setTimeout(() => {
                              window.location.reload();
                            }, 2000);
                          })
                          .catch((error) => {
                            Swal.fire({
                              icon: "error",
                              title: "Oops...",
                              text: "Something went wrong!",
                              footer: `${error.response.data}`,
                            });
                            console.log(error);
                            setValidaBoton(true);
                          });
                      }
                    });
                } else {
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Compra",
                          detalle: `OC ${selectedOrdenCompra}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire(
                          "Good job!",
                          "Creado con exito",
                          "success"
                        );
                        setTimeout(() => {
                          window.location.reload();
                        }, 2000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                }
              });
              inputFields.map((w) => {
                axios
                  .patch(
                    `${URL_ARTICULOS_OC_DIRECTAS}editRecibido/${w.idArticulosOrden}`,
                    {
                      cantidad: w.cantidad,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalRollos = totalRollos - 1;
                    if (totalRollos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Compra",
                            detalle: `OC ${selectedOrdenCompra}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire(
                            "Good job!",
                            "Creado con exito",
                            "success"
                          );
                          setTimeout(() => {
                            window.location.reload();
                          }, 2000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
              });

              // Pegamos a Compras Acumulado

              inputFields.map((w) => {
                axios
                  .post(
                    `${URL_COMPRAS_DIRECTAS_ACUMULADOS}`,
                    {
                      comprasDirectas: data.data._id,
                      articulos: w.articulos,
                      articulosOC: w.idArticulosOrden,
                      colores: w.colores,
                      tallas: w.tallas,
                      cantidad: w.cantidad,
                      ordenesCompraDirecta: selectedOrdenCompra,
                      fecha,
                      proveedores: proveedorOrden,
                      total: w.total,
                      precio: w.precio,
                      faltante: 0
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalRollos = totalRollos - 1;
                    if (totalRollos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Compra",
                            detalle: `OC ${selectedOrdenCompra}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire(
                            "Good job!",
                            "Creado con exito",
                            "success"
                          );
                          setTimeout(() => {
                            window.location.reload();
                          }, 2000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
              });

              // Terminamos de post a compras acumulado
            });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        } 
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
    }
  };

  // const handleAddFields = () => {
  //   let TC = totalCaptura + 1;
  //   const ultimo = inputFields[inputFields.length - 1];

  //   setTotalCaptura(TC);
  //   setInputFields([
  //     ...inputFields,
  //     {
  //       id: uuidv4(),
  //       articulosOrden: ultimo.articulosOrden,
  //       articulos: ultimo.articulos,
  //       colores: ultimo.colores,
  //       tallas: ultimo.tallas,
  //       ubicacion: ultimo.ubicacion,
  //       cantidad: 0,
  //       costo: ultimo.costo,
  //       total: 0,
  //     },
  //   ]);
  // };


  // const handleRemoveFields = (id) => {
  //   const values = [...inputFields];
  //   values.splice(
  //     values.findIndex((value) => value.id === id),
  //     1
  //   );
  //   setInputFields(values);
  //   let mts = values.filter((e) => e.unidad == "Metros");
  //   let totalMts = mts.map((c) => parseFloat(c.cantidad));
  //   let TM = totalMts.reduce((t, total, index) => t + total, 0);
  //   setTotalMetros(TM);
  //   let KG = values.filter((e) => e.unidad == "Kilos");
  //   let totalKG = KG.map((c) => parseFloat(c.cantidad));
  //   let TK = totalKG.reduce((t, total, index) => t + total, 0);
  //   setTotalKilos(TK);
  //   let PZ = values.filter((e) => e.unidad == "Piezas");
  //   let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
  //   let TP = totalPZ.reduce((t, total, index) => t + total, 0);
  //   setTotalPiezas(TP);


  //   let totalDin = inputFields.map((c) => parseFloat(c.total));
  //   let Din = totalDin.reduce((t, total, index) => t + total, 0);
  //   setSubtotal(Din);

  //   if (impuestos == true) {
  //     let i = parseFloat(Din) * (impuestosPor / 100);
  //     setIva(i);
  //     setTotalDinero(parseFloat(Din) + i);
  //   } else {
  //     setTotalDinero(parseFloat(Din));
  //     setIva(0);
  //   }
  // };


  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        i.total = parseFloat(event.target.value) * parseFloat(i.costo);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

    const handleChangeInputUbicacion = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value
      }
      return i;
    });
    setInputFields(newInputFields);
  };



  const handleChangeInputArticulo = (
    id,
    articulosOrden,
    idArticulo,
    unidadArticulo,
    color,
    costo
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulosOrden = articulosOrden;
        i.articulos = idArticulo;
        i.unidad = unidadArticulo;
        i.colores = color;
        i.costo = costo;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let mts = inputFields.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = inputFields.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);

    let PZ = inputFields.filter((e) => e.unidad == "Piezas");
    let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
    let TP = totalPZ.reduce((t, total, index) => t + total, 0);
    setTotalPiezas(TP);

    let totalDin = inputFields.map((c) => parseFloat(c.total));
    let Din = totalDin.reduce((t, total, index) => t + total, 0);
    setSubtotal(Din);

    if (impuestos == true) {
      let i = parseFloat(Din) * (impuestosPor / 100);
      setIva(i);
      setTotalDinero(parseFloat(Din) + i);
    } else {
      setTotalDinero(parseFloat(Din));
      setIva(0);
    }

    let agrupado = inputFields.reduce(function (groups, item) {
      const val = item["articulos"] + item["colores"];
      groups[val] = groups[val] || {
        articulos: item.articulos,
        colores: item.colores,
        cantidad: 0,
        total: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].total += item.total;
      groups[val].id = item.id;
      groups[val].articulos = item.articulos;
      groups[val].unidad = item.unidad;
      groups[val].colores = item.colores;
      groups[val].articulosOrden = item.articulosOrden;
      return groups;
    }, []);

    let CA = Object.values(agrupado);
    setCantidadAgrupado(CA);
  }

  const calculaIVA = (e) => {
    setImpuestos(e);
    if (e == true) {
      let i = parseFloat(subtotal) * (impuestosPor / 100);
      setIva(i);
      setTotalDinero(parseFloat(subtotal) + i);
    } else {
      setTotalDinero(parseFloat(subtotal));
      setIva(0);
    }
  };

  const calculaPor = (e) => {
    setImpuestosPor(e);
    if (impuestos == true) {
      let i = parseFloat(subtotal) * (e / 100);
      setIva(i);
      setTotalDinero(parseFloat(subtotal) + i);
    } else {
      setTotalDinero(parseFloat(subtotal));
      setIva(0);
    }
  };

 
  function jalaOrden(e) {
    setSelectedOrdenCompra(e);
    setArticulosOC([]);
    setInputFields([
      {
        id: uuidv4(),
      articulosOrden: "",
      articulos: "",
      articulosName: "",
      colores: "",
      coloresName: "",
      tallas: "",
      tallasName: "",
      ubicacion: "NA",
      cantidad: 0,
      costo: 0,
      total: 0,
      },
    ]);

    axios
      .get(`${URL_ARTICULOS_OC_DIRECTAS}/ordenesCompra/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosOC = res.data;
        const arrayArt = allArticulosOC.map((a) => {
          return {
            id: uuidv4(),
            idArticulosOrden: a._id,
            articulos: a.articulos[0]._id,
            articulosName: a.articulos[0].codigo + " - " + a.articulos[0].codigoCliente,
            colores: a.colores[0]._id,
            coloresName: a.colores[0].name,
            tallas: a.tallas[0]._id,
            tallasName: a.tallas[0].name,
            cantidad: a.cantidad,
            precio: a.precio,
            total: a.cantidad * a.precio,
            ubicacion: "NA"
          };
        });
        setArticulosOC(allArticulosOC);
        setInputFields(arrayArt);

        let cant = arrayArt.map((c) => parseFloat(c.cantidad));
        let TC = cant.reduce((t, total, index) => t + total, 0);
        setTotalPiezas(TC);

        let importes = arrayArt.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setSubtotal(TG);
        setTotalDinero(TG)
      })
      .catch((err) => {
        console.log(err);
      });


    ordenesCompra.map((a) => {
      if (a._id == e) {
        setFechaOrdenCompra(a.fecha);
        setFechaCompromiso(a.fecha_compromiso);
        // setTotalKilosOrden(a.total_kilos);
        // setTotalMetrosOrden(a.total_metros);
        // setTotalPiezasOrden(a.total_piezas);
        setProveedorOrden(a.proveedores[0]._id);
        setObservacionesOrden(a.observaciones);
        // setTipo(a.tipo);
        // if(a.fecha_compromiso < hoy){
        //   Swal.fire({
        //     title: "Estas recibiendo una Orden de Compra vencida!",
        //     text: `Debes de solicitar autorizacion`,
        //     icon: "warning",
        //     showCancelButton: true,
        //     confirmButtonColor: "#3085d6",
        //     cancelButtonColor: "#d33",
        //     confirmButtonText: "Si, Recibir!",
        //   })
        // }
      }
    });

  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.compras_create ? (
        <div className="card container col-10">
          <Row>
            <Col md={2}>
              <Button
                size="sm"
                href="/ListadoComprasDirectas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row>
          <h3 align="center">Nueva Compra</h3>
          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Orden de Compra</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedOrdenCompra}
                required
                onChange={(e) => jalaOrden(e.target.value)}
              >
                <option value="0">Selecciona una Orden</option>
                {ordenesCompra
                  .sort((a, b) => (a.idOrdenCompraDirecta < b.idOrdenCompraDirecta ? 1 : -1))
                  .map((a) => {
                    if (
                      a.status == "Abierto" &&
                      a.is_active == "Si" &&
                      a.autorizado == "Si"
                    ) {
                      return (
                        <option value={a._id}>
                          {a.idOrdenCompraDirecta} {a.proveedores[0].razon_social}
                        </option>
                      );
                    }
                  })}
              </Input>
            </Col>
            <Col md={3}>
              <Label>Fecha OC</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha_orden_compra}
                required
                disabled
                onChange={(e) => {
                  setFechaOrdenCompra(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Fecha Compromiso</Label>
              <Input
                type="date"
                placeholder="Fecha Compromiso"
                value={fecha_compromiso}
                required
                disabled
                onChange={(e) => {
                  setFechaCompromiso(e.target.value);
                }}
              />
            </Col>
          <Col md={3}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones Orden Compra
              </Label>
              <Input
                type="text"
                placeholder="ObservacionesOrden"
                value={observacionesOrden}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label>Almacen</Label>
              <Input
                  type="select"
                  value={selectedArea}
                  onChange={(e) => {
                    setSelectedArea(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {areas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
            <Col md={2}>
              <Label>Fecha</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                // disabled
                onChange={(e) => {
                  setFecha(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Factura Proveedor</Label>
              <Input
                type="text"
                placeholder="Factura Proveedor"
                value={factura_proveedor}
                required
                onChange={(e) => {
                  setFacturaProveedor(e.target.value);
                }}
              />
            </Col>

            <Col md={2}>
              <Label>Pais Origen</Label>
              <Input
                type="text"
                placeholder="Pais Origen"
                value={pais_origen}
                required
                onChange={(e) => {
                  setPaisOrigen(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="text"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h4 id="logoutBoton">
                TOTALES {new Intl.NumberFormat("en-US").format(total_piezas)} pzas.
              </h4>
            </Col>
          </Row>
          <Row>
          <Col md={2}>
              <h3></h3>
              <Input
                type="checkbox"
                onChange={(event) => calculaIVA(event.currentTarget.checked)}
                checked={impuestos}
              />
              <Label className="subMenuTitulo">Costo mas IVA?</Label>
            </Col>
            {impuestos ? (
              <>
                <Col sm={2}>
                  <Label for="exampleEmail" className="mr-sm-2">
                    IVA
                  </Label>
                  <Input
                    name="iva"
                    value={iva}
                    disabled
                  />
                </Col>
                <Col sm={2}>
                  <Label for="exampleEmail" className="mr-sm-2">
                    % IVA
                  </Label>
                  <Input
                    name="impuestosPor"
                    value={impuestosPor}
                    onValueChange={(value) => calculaPor(value)}
                  />
                </Col>
              </>
            ) : undefined}

          </Row>

          <br />
          {/* Tabla Articulos */}
            <>
              <Row>
                <Col md={4}>
                  <Label className="mr-sm-2">Articulos</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Color</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Talla</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Cantidad</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Ubicacion</Label>
                </Col>
              </Row>

              {inputFields.map((inputField) => (
                <div key={inputField.id}>
                  <Row>
                    <Col md={4}>
                    <Input
                        bsSize="sm"
                        name="articulos"
                        type="string"
                        value={inputField.articulosName}
                        disabled
                      ></Input>
                    </Col>
                    <Col md={2}>
                      <Input
                        bsSize="sm"
                        name="colores"
                        type="string"
                        value={inputField.coloresName}
                        disabled
                      ></Input>
                    </Col>
                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="tallas"
                        type="string"
                        value={inputField.tallasName}
                        disabled
                      ></Input>
                    </Col>

                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={inputField.cantidad}
                        required
                        onChange={(event) =>
                          handleChangeInputCantidad(inputField.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="ubicacion"
                        type="text"
                        placeholder="ubicacion"
                        value={inputField.ubicacion}
                        required
                        onChange={(event) =>
                          handleChangeInputUbicacion(inputField.id, event)
                        }
                      />
                    </Col>
                    {/* <Col>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFields.length === 1}
                        onClick={() => handleRemoveFields(inputField.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={handleAddFields}
                        tabindex="-1"
                      >
                        <i class="fas fa-plus"></i>
                      </Button>
                    </Col> */}
                  </Row>
                </div>
              ))}
              <Row>
                <Col md={12}>
                  <h4 id="logoutBoton">
                    TOTALES {new Intl.NumberFormat("en-US").format(total_piezas)} pzas. 
                  </h4>
                </Col>
              </Row>

              <br />
              {validaBoton ? (
                <>
                  <Row>
                    <Button
                      type="submit"
                      className="btn btn-success"
                      onClick={saveCompraNueva}
                    >
                      Guardar
                    </Button>
                  </Row>
                </>
              ) : (
                <>
                  <Row>
                    <Button type="submit" className="btn btn-success" disabled>
                      Guardar
                    </Button>
                    <Button
                      href="/ListadoComprasDirectas"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      Regresar
                    </Button>
                  </Row>
                </>
              )}
              <br />
            </>


          {/* Termina Tabla Articulos */}

          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios minutos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ComprasDirectasCreate;
