import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import CompradoresCreate from "./CompradoresCreate";
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function ListadoCompradores() {
  const { user } = useContext(AuthContext);
  const URL_COMPRADORES = process.env.REACT_APP_URL_COMPRADORES;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const [compradores, setCompradores] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [value, setValue] = useState("");

  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");
  const toggle = () => setModal(!modal);
  const [text, setText] = useState(false);

  const [idEdit, setIdEdit] = useState()

  useEffect(() => {
    axios
      .get(URL_COMPRADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCompradores = response.data;
        setCompradores(allCompradores);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function editComprador(event) {
    event.preventDefault();
    const URL_COMPRADORES_EDIT = `${process.env.REACT_APP_URL_COMPRADORES}/${idEdit}`;
    axios
      .patch(
        URL_COMPRADORES_EDIT,
        {
          name,
          clientes: value._id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Editar Comprador',
            detalle: name,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        toggle();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(idEdit, name) {
        setName(name);
        setIdEdit(idEdit);
        toggle();
  }

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  return (
    <>
      <Header />
      <br />
      <br />
      <div className="container">
        { user.menu_articulos ? (
          <div className="row">
            {
              <div className="col-md-6 col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h3 align="center">Compradores</h3>
                    <Table
                      size="sm"
                      striped
                      borderless
                      className="table-responsive-xl"
                    >
                      <thead>
                        <tr>
                          <th className="tituloTabla">Nombre</th>
                          <th className="tituloTabla">Cliente</th>
                          <th className="tituloTabla">Editar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {compradores
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                              return (
                                <tr>
                                  <td>{a.name}</td>
                                  <td>{a.clientes[0].nombre_comercial}</td>
                                  <td>
                                    <Button
                                      color="info"
                                      id="Editar"
                                      size="sm"
                                      onClick={(e) => jalaInfo(a._id, a.name)}
                                    >
                                      <i class="far fa-edit"></i>{" "}
                                    </Button>
                                  </td>
                                </tr>
                              );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            }
            <div className="col-md-6 col-sm-6">
              <div className="card">
                <div className="card-body">
                  <CompradoresCreate />
                </div>
              </div>
            </div>
          </div>
        ) : undefined}
      </div>
      <Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Editar Comprador</ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Nombre</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={name}
            required
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
           <Label className="mr-sm-2">Cliente</Label>
                <Autocomplete
                  size="small"
                  value={value}
                  onChange={(event, selectedCliente) => {
                    setValue(selectedCliente);
                  }}
                  options={options.sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                  )}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.junta}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecciona"
                      variant="outlined"
                    />
                  )}
                  renderOption={(option) => (
                    <React.Fragment>{option.nombre_comercial}</React.Fragment>
                  )}
                />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editComprador}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ListadoCompradores;
