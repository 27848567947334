import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultiple from "../../StatusMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function ListadoAbonosProveedores() {
  const { user } = useContext(AuthContext);
  const URL_ABONOS_PROVEEDORES = process.env.REACT_APP_URL_ABONOS_PROVEEDORES;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-60);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");


  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [selectedBanco, setSelectedBanco] = useState("");
  const [bancos, setBancos] = useState([]);


  const [idAbonoEdit, setIdAbonoEdit] = useState("");
  const [numeroEdit, setNumeroEdit] = useState("");
  const [fechaEdit, setFechaEdit] = useState("");
  const [idProveedorEdit, setIdProveedorEdit] = useState("");
  const [importeEdit, setImporteEdit] = useState("");
  const [importeEditViejo, setImporteEditViejo] = useState("");
  const [proveedorEdit, setProveedorEdit] = useState("");
  const [notaEdit, setNotaEdit] = useState("");
  const [bancoEdit, setBancoEdit] = useState("");
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [validaBoton, setValidaBoton] = useState(true);
  const [text, setText] = useState(false);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");


  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(()=>{
    axios
      .get(`${URL_ABONOS_PROVEEDORES}ProveedoresFechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAbonos = response.data;
        // Array para pagination
        let arrayTabla = allAbonos
          .sort((a, b) => (a.idAbono < b.idAbono ? 1 : -1))
          .map((a) => {
            if(a.documentacion != ""){
            return {
              _id: a._id,
              numero: a.idAbonoProveedor,
              fecha: a.fecha,
              proveedores: a.proveedores[0].razon_social,
              idProveedores: a.proveedores[0]._id,
              bancos: a.bancos[0].banco + a.bancos[0].cuenta,
              idBancos: a.bancos[0]._id,
              observaciones: a.observaciones,
              importe: a.importe,
              nota: a.documentacion[0].idDocumentacion,
              idNota: a.documentacion[0]._id,
              editado:a.editado
            }
            }else{
              return {
                _id: a._id,
                numero: a.idAbonoProveedor,
                fecha: a.fecha,
                proveedores: a.proveedores[0].razon_social,
                idProveedores: a.proveedores[0]._id,
                bancos: a.bancos[0].banco + a.bancos[0].cuenta,
                idBancos: a.bancos[0]._id,
                observaciones: a.observaciones,
                importe: a.importe,
                nota: a.compras[0].idCompras,
                idNota: a.compras[0]._id,
                editado:a.editado
              }
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);

        setComments(data);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });


    axios
      .get(URL_BANCOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBancos = response.data;
        setBancos(allBancos);
      })
      .catch((err) => {
        console.log(err);
      });

  }, [ selectedFechaInicio, selectedFechaFin]);


  function PDFTabla() {
    let TotalImporte= 0
    const data = comments
    .filter(
      (comment) =>
        comment.proveedores.toLowerCase().includes(search.toLowerCase()) ||
        comment.bancos.toLowerCase().includes(search.toLowerCase()) ||
        comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
        comment.importe.toString().includes(search) ||
        comment.numero.toString().includes(search) 
    )
    .sort((a, b) => (a.idAbono > b.idAbono ? 1 : -1))
    .map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores) &&
        (selectedBanco == 0 || selectedBanco == a.idBancos) 
      ) {
        TotalImporte = TotalImporte + a.importe
        return [
          a.numero,
          a.fecha,
          a.proveedores,
          a.bancos,
          a.observaciones,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.importe),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });


    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Abonos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Proveedor",
          "Banco",
          "Observaciones",
          "Importe",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalImporte)
        ]
      ]
    });
    doc.save(`Abonos.pdf`);
  }

  function excel() {
    const dataExcel = comments
    .filter(
      (comment) =>
        comment.proveedores.toLowerCase().includes(search.toLowerCase()) ||
        comment.bancos.toLowerCase().includes(search.toLowerCase()) ||
        comment.importe.toString().includes(search) ||
        comment.numero.toString().includes(search) 
    )
    .sort((a, b) => (a.idAbono > b.idAbono ? 1 : -1))
    .map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores) &&
        (selectedBanco == 0 || selectedBanco == a.idBancos) 
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Proveedor: a.proveedores,
          Bancos: a.bancos,
          Observaciones: a.observaciones,
          Importe: a.importe,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoAbonos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoAbonos",
        sheetFilter: [
          "Numero",
          "Fecha",
          "Proveedor",
          "Banco",
          "Observaciones",
          "Importe",
          ],
        sheetHeader: [
          "Numero",
          "Fecha",
          "Proveedor",
          "Banco",
          "Observaciones",
          "Importe",
          ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let TotalImporte= 0
    const data = comments
    .filter(
      (comment) =>
        comment.proveedores.toLowerCase().includes(search.toLowerCase()) ||
        comment.bancos.toLowerCase().includes(search.toLowerCase()) ||
        comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
        comment.importe.toString().includes(search) ||
        comment.numero.toString().includes(search) 
    )
    .sort((a, b) => (a.idAbono > b.idAbono ? 1 : -1))
    .map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores) &&
        (selectedBanco == 0 || selectedBanco == a.idBancos)
      ) {
        TotalImporte = TotalImporte + a.importe
        return [
          a.numero,
          a.fecha,
          a.proveedores,
          a.bancos,
          a.observaciones,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.importe),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });


    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Abonos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Proveedor",
          "Banco",
          "Observaciones",
          "Importe",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalImporte)
        ]
      ]
    });
    
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Abonos",
          email: mailTo,
          fileName: "ListadoAbonos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Abonos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }




  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Proveedor", field: "proveedores", sortable: true },
    { name: "Banco", field: "banco", sortable: true },
    { name: "Observaciones", field: "observaciones", sortable: true },
    { name: "Importe", field: "importe", sortable: true },
    { name: "Editar", field: "editar", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.proveedores.toLowerCase().includes(search.toLowerCase()) ||
          comment.bancos.toLowerCase().includes(search.toLowerCase()) ||
          comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
          comment.importe.toString().includes(search) ||
          comment.numero.toString().includes(search) 
      );
    }
    if (selectedProveedor) {
      computedComments = computedComments.filter((e) =>
        e.idProveedores.includes(selectedProveedor)
      );
    }

    if (selectedBanco) {
      computedComments = computedComments.filter((e) =>
        e.idBancos.includes(selectedBanco)
      );
    }


    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "importe" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "importe" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "importe" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedProveedor,
    selectedFechaInicio,
    selectedFechaFin,
    selectedBanco,
  ]);

  function EditAbono(
    _id,
    numero,
    fecha,
    idProveedores,
    importe,
    proveedor,
    idBancos,
    idNota
  ) {
    setIdAbonoEdit(_id);
    setNumeroEdit(numero);
    setFechaEdit(fecha);
    setIdProveedorEdit(idProveedores);
    setImporteEdit(importe);
    setImporteEditViejo(importe);
    setProveedorEdit(proveedor);
    setNotaEdit(idNota);
    setBancoEdit(idBancos)
    toggleEdit();
  }
  
  const EditAbonoSave = (event) => {
    event.preventDefault();
    Swal.fire({
      title: "Estas seguro?",
      text: "Se registrará el abono",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Enviar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_ABONOS_PROVEEDORES}/${idAbonoEdit}`,
            {
              importe: parseFloat(importeEdit) - parseFloat(importeEditViejo),
              proveedores: idProveedorEdit,
              documentacion:notaEdit,
              fecha: fechaEdit,
              editado:"Si",
              bancos:bancoEdit
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            axios.post(
              URL_LOGS,
              {
                tipo: "Editar Abono",
                detalle: `${proveedorEdit}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
            Swal.fire("Good job!", "Creado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  };

 

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_adminCxP ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.menu_adminCxP ? (
                <>
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/AbonosProveedoresCreate"
                >
                  Nuevo Abono
                </Button>
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/BonificacionesProveedoresCreate"
                >
                  Nueva Bonificacion
                </Button>
                </>
              ) : (
                <>
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Abono
                </Button>
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nueva Bonificacion
                </Button>
                </>
              )}
              <Button
                size="sm"
                href="/MenuCXP"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Abonos</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Reporte de Abonos</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>

          
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedProveedor}
                onChange={(e) => {
                  setSelectedProveedor(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {proveedores
                  .sort((a, b) => (a.razon_social > b.razon_social ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.razon_social}</option>;
                  })}
              </Input>
              </td>

              <td style={{ paddingTop: "0px" }}>
              <Input
                      type="select"
                      bsSize="sm"
                      value={selectedBanco}
                      onChange={(e) => {
                        setSelectedBanco(e.target.value);
                      }}
                    >
                      <option value="">Selecciona un Banco</option>
                      {bancos
                        .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.banco} {a.cuenta}
                            </option>
                          );
                        })}
                    </Input>
              </td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                if(a.editado=="Si"){
                  return (
                    <tr style={{ backgroundColor: "orange" }}>
                      <td>{a.numero}</td>
                      <td style={{ minWidth: 100 }}>{a.fecha}</td>
                      <td>{a.proveedores}</td>
                      <td>{a.bancos}</td>
                      <td>{a.observaciones}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.importe)}</td>
                      <td style={{ minWidth: 120 }}>

                            <Button
                              size="sm"
                              className="btn"
                              color="info"
                              onClick={(e) =>
                                EditAbono(
                                  a._id,
                                  a.numero,
                                  a.fecha,
                                  a.idProveedores,
                                  a.importe,
                                  a.proveedores,
                                  a.idBancos,
                                  a.idNota
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                        
                      </td>
                    
                    </tr>
                  );
                } else{
                  return (
                    <tr>
                      <td>{a.numero}</td>
                      <td  style={{ minWidth: 100 }}>{a.fecha}</td>
                      <td>{a.proveedores}</td>
                      <td>{a.bancos}</td>
                      <td>{a.observaciones}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.importe)}</td>
                      <td style={{ minWidth: 120 }}>
                            <Button
                              size="sm"
                              className="btn"
                              color="info"
                              onClick={(e) =>
                                EditAbono(
                                  a._id,
                                  a.numero,
                                  a.fecha,
                                  a.idProveedores,
                                  a.importe,
                                  a.proveedores,
                                  a.idBancos,
                                  a.idNota
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                        
                      </td>
                    
                    </tr>
                  );

                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />
      <Modal size="sm" isOpen={modalEdit} toggle={toggleEdit}>
            <ModalHeader toggle={toggleEdit}>
              <h4>Editar Abono {numeroEdit} {proveedorEdit}</h4>
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={EditAbonoSave}>
                <Row>
                  <Col md={6}>
                    <Label>Fecha</Label>
                    <Input
                      type="date"
                      value={fechaEdit}
                      required
                      onChange={(e) => {
                        setFechaEdit(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <Label>Importe</Label>
                    <Input
                      className="col-sm-12"
                      type="number"
                      placeholder="Importe"
                      value={importeEdit}
                      required
                      onChange={(e) => {
                        setImporteEdit(e.target.value);
                      }}
                    />
                  </Col>
                </Row>

                <br />
                <Row>
                    <Button type="submit" className="btn btn-success">
                      Guardar
                    </Button>

                  <div>
                    <SweetAlert
                      show={text}
                      title="Creado con Exito"
                      onConfirm={() => {
                        setText(false);
                      }}
                    />
                  </div>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
     
      {loader}
    </>
  );
}

export default ListadoAbonosProveedores;
