import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function PedidosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_TALLAS = process.env.REACT_APP_URL_TALLAS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_COMPRADORES = process.env.REACT_APP_URL_COMPRADORES;
  const URL_EMPRESAS = process.env.REACT_APP_URL_EMPRESAS;
  const URL_EXPLOSION_GLOBAL = process.env.REACT_APP_URL_EXPLOSION_GLOBAL;
  const URL_TELAS = process.env.REACT_APP_URL_TELAS;

  let hoy = new Date()
  // let inicio = new Date()
  // inicio.setDate(inicio.getDate()-60);

  let endDate   = moment(hoy).format("YYYY-MM-DD");
  // let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);
  const [colores, setColores] = useState([]);
  const [tallas, setTallas] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [empresas, setEmpresas] = useState([]);
  const [selectedEmpresa, setSelectedEmpresa] = useState("");
  const [pedidoCliente, setPedidoCliente] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [comprador, setComprador] = useState("");
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [idArt, setIdArt] = useState("");
  const [idArtPdr, setIdArtPdr] = useState("");
  const [codigoCliente, setCodigoCliente] = useState("NA");
  const [precioEtiqueta, setPrecioEtiqueta] = useState(0);
  const [value, setValue] = useState("");
  const [descuento, setDescuento] = useState(0);
  const [precio, setPrecio] = useState(0);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [fecha, setFecha] = useState("");
  const [fecha_cancelacion, setFechaCancelacion] = useState("");
  const [fecha_confirmacion, setFechaConfirmacion] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState(0);
  const [total_piezas, setTotalPiezas] = useState(0);
  const [compradores, setCompradores] = useState([]);
  const [selectedComprador, setSelectedComprador] = useState("");
  const [pendFechaConf, setPendFechaConf] = useState("");

  const [telas, setTelas] = useState([]);
  const [modalExplosiones, setModalExplosiones] = useState(false);
  const toggleExplosiones = () => setModalExplosiones(!modalExplosiones);
  const [inputFieldsTela, setInputFieldsTela] = useState([
    {
      id: uuidv4(),
      idTela: "",
      idColor: "",
      cantidad: 0,
      observaciones: "NA",
    },
  ]);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      articulosNombre: "",
      colores: "",
      arrayColoresArticulo: [],
      tallas: "",
      arrayTallasArticulo: [],
      cantidad: 0,
      precio: 0,
      descuento: 0,
      total: 0,
      tc: [],
      totalArt: 0,
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useMemo(() => {
    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColores = res.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_TALLAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTallas = res.data;
        setTallas(allTallas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COMPRADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allCompradores = res.data;
        setCompradores(allCompradores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_EMPRESAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allEmpresa = response.data;
        setEmpresas(allEmpresa);
        //
      })
      .catch((err) => {
        console.log(err);
      });
      axios
    .get(URL_TELAS, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allTelas = res.data;
      setTelas(allTelas);
    })
  }, []);

  const savePedido = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();

    let inicio = new Date(fecha_cancelacion);
    let bla = inicio.setDate(inicio.getDate() - 60);
    let fechaStatus = moment(bla).format("YYYY-MM-DD");

    let totalPedido = inputFields.length + inputFieldsTela.length

    try {
      await axios
        .post(
          URL_PEDIDOS,
          {
            fecha,
            fechaEstado: fecha,
            fechaCreacion: endDate,
            resurtido: "Si",
            empresas: selectedEmpresa,
            clientes: value._id,
            colaboradores: selectedColaborador,
            fecha_cancelacion,
            fecha_confirmacion,
            total_general,
            total_piezas,
            compradores: selectedComprador,
            observaciones,
            articulos: idArt,
            pedidoCliente,
            surtido: 0,
            pendiente_surtir: total_piezas,
            confirmacionPedido: "No",
            status: "Abierto",
            fechaEstCodigos: fechaStatus,
            fechaEstFicha: fechaStatus,
            fechaEstAuditoria: fechaStatus,
            fechaEstMuestraFit: fechaStatus,
            fechaEstMuestraConfirmacion: fechaStatus,
            fechaEstEtiquetas: fechaStatus,
            fechaRealCodigos: fechaStatus,
            fechaRealFicha: fechaStatus,
            fechaRealAuditoria: fechaStatus,
            fechaRealMuestraFit: fechaStatus,
            fechaRealMuestraConfirmacion: fechaStatus,
            fechaRealEtiquetas: fechaStatus,

            autorizacionFitFecha: fechaStatus,
            autorizacionCostosFecha: fechaStatus,
            autorizacionDisenoFecha: fechaStatus,
            autorizacionCalidadFecha: fechaStatus,
            autorizacionPloterFecha: fechaStatus,
            autorizacionPloterDisenoFecha: fechaStatus,
            autorizacionSurtirFecha: fechaStatus,

            autorizacionFitColaborador: "613a9434225bce3df050c220",
            autorizacionCostosColaborador: "613a9434225bce3df050c220",
            autorizacionDisenoColaborador: "613a9434225bce3df050c220",
            autorizacionCalidadColaborador: "613a9434225bce3df050c220",
            autorizacionPloterColaborador: "613a9434225bce3df050c220",
            autorizacionPloterDisenoColaborador: "613a9434225bce3df050c220",
            autorizacionSurtirColaborador: "613a9434225bce3df050c220",
            descuento,
            pendFechaConf
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            if (a.articulos != "" && a.totalArt != 0) {
              a.tc.map((b) => {
                axios
                  .post(
                    URL_ARTICULOS_PEDIDO,
                    {
                      pedidos: data.data._id,
                      articulos: a.articulos,
                      colores: b.colores,
                      tallas: b.tallas,
                      cantidad: parseFloat(b.cantidad),
                      pendienteCortar: parseFloat(b.cantidad),
                      cortado: 0,
                      surtido: 0,
                      pendiente_surtir: parseFloat(b.cantidad),
                      cantidad_procesos: parseFloat(b.cantidad),
                      precio,
                      descuento,
                      total: (parseFloat(b.cantidad) * parseFloat(precio) - (parseFloat(b.cantidad) * parseFloat(precio)) / 100).toFixed(2),
                      codigoBarras: 0,
                      precioEtiqueta,
                      resurtido: "Si",
                      codigoCliente,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalPedido = totalPedido - 1;
                    if (totalPedido == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Pedido",
                            detalle: `${value._id} ${total_general}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", `Creado con exito, Pedido ${data.data.idPedido}`, `success`);
                          setTimeout(() => {
                            window.location.reload();
                          }, 2000);
                        });
                    }
                  });
              });
            } else {
              totalPedido = totalPedido - 1;
              if (totalPedido == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Pedido",
                      detalle: `${value._id} ${total_general}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", `Creado con exito, Pedido ${data.data.idPedido}`, `success`);
                    setTimeout(() => {
                      window.location.reload();
                    }, 2000);
                  });
              }
            }
          })

          inputFieldsTela.map((a) => {
            if ((a.idTela != "") && (a.idColor != "") && (a.cantidad > 0)) {
              axios
                .post(
                  URL_EXPLOSION_GLOBAL,
                  {
                    articulos: idArt,
                    pedidos: data.data._id,
                    telas: a.idTela,
                    colores: a.idColor,
                    cantidad: a.cantidad,
                    tipo: "Tela",
                    observaciones: a.observaciones,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                    },
                  }
                )
                .then(() => {
                  totalPedido = totalPedido - 1;
                  if (totalPedido == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Agregar Explosion",
                          detalle: `${idArt} ${a.idTela}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", `Creado con exito, Pedido ${data.data.idPedido}`, `success`);
                        setTimeout(() => {
                          window.location.reload();
                        }, 2000);
                      });
                  }
                });
            } else {
              totalPedido = totalPedido - 1;
            }
          });

        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        articulosNombre: "",
        colores: "",
        arrayColoresArticulo: [],
        tallas: "",
        arrayTallasArticulo: [],
        cantidad: 0,
        precio: 0,
        descuento: 0,
        total: 0,
        tc: [],
        totalArt: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);

    let cantidad = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cantidad.reduce((t, total, index) => t + total, 0);
    setTotalPiezas(TC);

    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneral(TG);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.totalArt * parseFloat(precio);
        i.total = totalParcial
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, colores, tallas, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.tc.map((a) => {
          if (a.colores == colores && a.tallas == tallas) {
            a.cantidad = event.target.value;
          }
        });

        let can = i.tc.map((c) => parseFloat(c.cantidad));
        let TC = can.reduce((t, total, index) => t + total, 0);

        i.totalArt = TC;
        let totalParcial = i.totalArt * parseFloat(precio);
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);

    ActualizaTotales();
  };

  function BuscaArticulo(id, selectedArticulo) {
    articulos.map((a) => {
      if (a._id == selectedArticulo._id) {
        let idArticulo = a._id;
        let precio = a.venta;
        let codigoCliente = a.codigoCliente;
        let coloresArticulo = a.colores;
        let tallasArticulo = a.tallas;
        let articulosNombre =
          selectedArticulo.codigo +
          " " +
          selectedArticulo.codigoCliente +
          " " +
          selectedArticulo.clientes[0].nombre_comercial;
        handleChangeInputArticulo(
          id,
          idArticulo,
          precio,
          coloresArticulo,
          tallasArticulo,
          articulosNombre,
          codigoCliente
        );
      }
    });
  }

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    precio,
    coloresArticulo,
    tallasArticulo,
    articulosNombre,
    codigoCliente
  ) => {
    setIdArt(idArticulo);
    const newInputFields2 = [];

    let coloresArray = Object.values(coloresArticulo);
    let tallasArray = Object.values(tallasArticulo);

    coloresArray.map((a) => {
      tallasArray.map((b) => {
        newInputFields2.push({
          colores: a._id,
          colorNombre: a.name,
          tallas: b._id,
          tallaNombre: b.name,
          cantidad: 0,
        });
      });
    });

    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.articulosNombre = articulosNombre;
        i.precio = precio;
        i.arrayColoresArticulo = coloresArticulo;
        i.arrayTallasArticulo = tallasArticulo;
        let totalParcial = i.cantidad * parseFloat(precio);
        i.total = totalParcial;
        i.tc = newInputFields2;
      }
      return i;
    });
    setCodigoCliente(codigoCliente)
    setInputFields(newInputFields);

    ActualizaTotales();
  };

  function ActualizaTotales() {
    let cantidad = inputFields.map((c) => parseFloat(c.totalArt));
    let TC = cantidad.reduce((t, total, index) => t + total, 0);
    setTotalPiezas(TC);

    let totalParcial = TC * parseFloat(precio);
    let totalGen = totalParcial;

    setTotalGeneral(totalGen);
  }

  function explosiones() {
    
    setInputFieldsTela([
      {
        id: uuidv4(),
        idTela: "",
        idColor: "",
        cantidad: 0,
        observaciones: "NA",
      },
    ]);
    toggleExplosiones();
  }

  const handleRemoveFieldsTelas = (id) => {
    const values = [...inputFieldsTela];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsTela(values);
  };


const handleAddFieldsTelas = () => {
  setInputFieldsTela([
    ...inputFieldsTela,
    {
      id: uuidv4(),
      idTela: "",
      idColor: "",
      cantidad: 0,
      observaciones: "NA",
    },
  ]);
};
const handleChangeInputTela = (id, event) => {
  const newInputFieldsTela = inputFieldsTela.map((i) => {
    if (id === i.id) {
      i[event.target.name] = event.target.value;
    }
    return i;
  });
  setInputFieldsTela(newInputFieldsTela);
};

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  const options2 = articulos.map((option) => {
    const junta =
      option.codigo +
      " " +
      option.codigoCliente +
      " " +
      option.clientes[0].nombre_comercial;
    const firstLetter = option.codigo[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  return (
    <>
      <Header />
      <br />
      <br />
      {user.pedidos_create ? (
        <div className="card container col-12">
          <h3 align="center">Nuevo Pedido</h3>
          <Form onSubmit={savePedido}>
            <Row>
              <Col md={2}>
                <Label>Fecha Venta</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Empresa</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedEmpresa}
                  required
                  onChange={(e) => {
                    setSelectedEmpresa(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {empresas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Cliente</Label>
                <Autocomplete
                  size="small"
                  value={value}
                  onChange={(event, selectedCliente) => {
                    setValue(selectedCliente);
                  }}
                  options={options.sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                  )}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.junta}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecciona"
                      variant="outlined"
                    />
                  )}
                  renderOption={(option) => (
                    <React.Fragment>{option.nombre_comercial}</React.Fragment>
                  )}
                />
              </Col>

              <Col md={2}>
                <Label>Vendedor</Label>
                <Input
                  type="select"
                  value={selectedColaborador}
                  onChange={(e) => {
                    setSelectedColaborador(e.target.value);
                  }}
                >
                  <option value="">Selecciona un Vendedor</option>
                  {colaboradores
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.nombre} {a.apellido}
                          </option>
                        );
                    })}
                </Input>
              </Col>
              <Col md={2}>
                <Label>Fecha Cancelacion</Label>
                <Input
                  type="date"
                  placeholder="Fecha Cancelacion"
                  value={fecha_cancelacion}
                  required
                  onChange={(e) => {
                    setFechaCancelacion(e.target.value);
                    let inicio = new Date(e.target.value);
                    let bla = inicio.setDate(inicio.getDate() - 41);
                    setFechaConfirmacion(moment(bla).format("YYYY-MM-DD"));
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>Fecha Confirmacion</Label>
                <Input
                  type="date"
                  placeholder="Fecha Confirmacion"
                  value={fecha_confirmacion}
                  required
                  onChange={(e) => {
                    setFechaConfirmacion(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
                  <Col md={2}>
                    <Label className="mr-sm-2">Pendiente Fecha Conf</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={pendFechaConf}
                      required
                      onChange={(e) => {
                        setPendFechaConf(e.target.value);
                      }}
                    >
                      <option value="">Selecciona</option>
                      <option value="Si">Si</option>
                      <option value="No">No</option>
                    </Input>
                  </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Comprador</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedComprador}
                  required
                  onChange={(e) => {
                    setSelectedComprador(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {compradores
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              <Col md={2}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Pedido Cliente
                </Label>
                <Input
                  type="text"
                  placeholder="Pedido Cliente"
                  value={pedidoCliente}
                  required
                  onChange={(e) => {
                    setPedidoCliente(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones Diseño
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
              <Label for="exampleEmail" className="mr-sm-2">
                    Agregar Consumo
                  </Label>
                  <br />
              <Button
                            color="success"
                            id="explosion"
                            onClick={(e) =>
                              explosiones()
                            }
                          >
                            <i class="fas fa-tape"></i>
                          </Button>
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <h4 id="logoutBoton">
                  TOTALES {total_piezas} pzas. /{" "}
                  {"$" + new Intl.NumberFormat("en-US").format(total_general)}
                </h4>
              </Col>
            </Row>
            <br />
            {/* Tabla Articulos */}

            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Buscar</Label>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Articulos</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Modelo Oficial</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio Etiqueta</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Piezas</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={3}>
                    <Autocomplete
                      size="small"
                      value={inputField.articulosNombre}
                      onChange={(event, selectedArticulo) => {
                        BuscaArticulo(inputField.id, selectedArticulo);
                      }}
                      options={options2.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.junta}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecciona"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => (
                        <React.Fragment>{option.codigo} {option.codigoCliente} {option.clientes[0].nombre_comercial}</React.Fragment>
                      )}
                    />
                  </Col>

                  <Col md={3}>
                    <Input
                      name="articulo"
                      type="string"
                      placeholder="Articulo"
                      value={inputField.articulosNombre}
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      type="text"
                      placeholder="CodigoCliente"
                      value={codigoCliente}
                      required
                      onChange={(e) => {
                        setCodigoCliente(e.target.value);
                      }}
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      name="precio"
                      type="number"
                      placeholder="Precio"
                      value={precio}
                      required
                      onChange={(e) => setPrecio(e.target.value)}
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      name="precioEtiqueta"
                      type="number"
                      placeholder="PrecioEtiqueta"
                      value={precioEtiqueta}
                      required
                      onChange={(e) => setPrecioEtiqueta(e.target.value)}
                    />
                  </Col>


                  <Col md={1}>
                    <Input
                      name="totalArt"
                      type="number"
                      placeholder="Piezas"
                      value={inputField.totalArt}
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      name="total"
                      type="number"
                      placeholder="Total"
                      value={inputField.total}
                      disabled
                    />
                  </Col>
                  {/* <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col> */}
                </Row>
                {inputField.arrayColoresArticulo.map((col) => {
                  return (
                    <>
                      <Row>
                        <Col md={9} className="tallas">
                          <Col md={1}>
                            <Label>{col.name}</Label>
                          </Col>
                          {inputField.tc.map((a) => {
                            if (a.colores == col._id) {
                              return (
                                <>
                                  <Col md={1}>
                                    <Label>{a.tallaNombre}</Label>
                                    <Input
                                      bsSize="sm"
                                      name="cantidad"
                                      type="number"
                                      value={a.cantidad}
                                      required
                                      onChange={(event) =>
                                        handleChangeInputCantidad(
                                          inputField.id,
                                          a.colores,
                                          a.tallas,
                                          event
                                        )
                                      }
                                    />
                                  </Col>
                                </>
                              );
                            }
                          })}
                        </Col>
                      </Row>
                    </>
                  );
                })}
                <br />
              </div>
            ))}
            <Row>
              <Col md={10}>
                <h4 id="logoutBoton">
                  TOTALES {total_piezas} pzas. /{" "}
                  {"$" + new Intl.NumberFormat("en-US").format(total_general)}
                </h4>
              </Col>
            </Row>
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoPedidos"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios minutos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
          <Modal size="xl" isOpen={modalExplosiones} toggle={toggleExplosiones}>
        <ModalHeader toggle={toggleExplosiones}>
          <h4>
            Explosion Modelo
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label>Tela</Label>
            </Col>
            <Col md={2}>
              <Label>Color</Label>
            </Col>
            <Col md={2}>
              <Label>Metros Totales</Label>
            </Col>
            <Col md={3}>
              <Label>Observaciones</Label>
            </Col>
          </Row>
    
          {inputFieldsTela.map((a) => (
            <div key={a.id}>
              <Row>
                <Col md={3}>
                  <Input
                    bsSize="sm"
                    name="idTela"
                    type="select"
                    value={a.idTela}
                    required
                    onChange={(event) => {
                      handleChangeInputTela(a.id, event);
                    }}
                  >
                    <option value="">Selecciona</option>
                    {telas
                      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.nombre} / {a.tiposTelas[0].name}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
                <Col md={2}>
                  <Input
                    bsSize="sm"
                    name="idColor"
                    type="select"
                    value={a.idColor}
                    required
                    onChange={(event) => {
                      handleChangeInputTela(a.id, event);
                    }}
                  >
                    <option value="">Selecciona</option>
                    {colores
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.name} 
                          </option>
                        );
                      })}
                  </Input>
                </Col>
                <Col md={2}>
                  <Input
                    bsSize="sm"
                    name="cantidad"
                    type="number"
                    value={a.cantidad}
                    onChange={(event) => {
                      handleChangeInputTela(a.id, event);
                    }}
                  ></Input>
                </Col>
                <Col md={3}>
                  <Input
                    bsSize="sm"
                    name="observaciones"
                    type="text"
                    value={a.observaciones}
                    onChange={(event) => {
                      handleChangeInputTela(a.id, event);
                    }}
                  ></Input>
                </Col>
                <Col>
                  <Button
                    size="sm"
                    className="btn"
                    color="danger"
                    disabled={inputFieldsTela.length === 1}
                    onClick={() => handleRemoveFieldsTelas(a.id)}
                    tabindex="-1"
                  >
                    <i class="fas fa-minus"></i>
                  </Button>
                  <Button
                    size="sm"
                    className="btn"
                    color="info"
                    onClick={handleAddFieldsTelas}
                    tabindex="-1"
                  >
                    <i class="fas fa-plus"></i>
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
          <br />
        
          
        
          </ModalBody>
      </Modal>
        
        </div>
      ) : undefined}
    </>
  );
}

export default PedidosCreate;
