import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import Baja from "../../Baja";
import { v4 as uuidv4 } from "uuid";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import Status from "../../Status";
import moment from "moment";

function ListadoArticulosCortes() {
  const { user } = useContext(AuthContext);
  const URL_ARTICULOS_CORTES = process.env.REACT_APP_URL_ARTICULOS_CORTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 7);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");


  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [totalCantidad, setTotalCantidad] = useState(0);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(`${URL_ARTICULOS_CORTES}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCortes = response.data;
        // Array para pagination
        let arrayTabla = allCortes
          .sort((a, b) => (a.idCorte > b.idCorte ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              corte: a.cortes[0].ordenesCorte[0].numero,
              fecha: a.fecha,
              cantidad: a.total_cantidad,
              articulo: a.pedidos[0].articulos[0].codigoCliente + " " + a.pedidos[0].articulos[0].codigo + " " + a.pedidos[0].articulos[0].descripcion,
              pedido: a.pedidos[0].idPedido,
              cliente: a.pedidos[0].clientes[0].nombre_comercial,
              talla: a.articulosOrdenesCorte[0].articulosPedido[0].tallas[0].name,
              color: a.articulosOrdenesCorte[0].articulosPedido[0].colores[0].name,
            };
          })
          .filter(function (el) {
            return el != null;
          });


        let dataFinal = Object.values(arrayTabla);

        // let agrupado = dataFinal.reduce(function (groups, item) {
        //   const val = item["corte"]
        //   groups[val] = groups[val] || {
        //     corte: item.corte,
        //     cantidad:0
        //   };
        //   groups[val].cantidad += item.cantidad;
        //   groups[val].articulo = item.articulo;
        //   groups[val].corte = item.corte;
        //   groups[val].fecha = item.fecha;
        //   groups[val].cliente = item.cliente;
        //   groups[val].pedido = item.pedido;
        //   return groups;
        // },[])
        // let dataFinal2 = Object.values(agrupado);

        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    
  }, [selectedFechaInicio, selectedFechaFin]);

  const ListaCortes = comments.sort((a, b) => (a.idCorte < b.idCorte ? 1 : -1));

  function PDFTabla() {
    const data = comments.map((a) => { {
        return [a.articulo, a.color, a.talla, a.fecha, a.corte, a.cliente, a.pedido, a.cantidad];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Articulos Cortes`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Articulo", "Color", "Talla", "Fecha", "Corte", "Cliente", "Pedido", "Cantidad"]],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCantidad)
        ]
      ],
      showFoot: "lastPage",
    });
    // doc.save(`Cortes.pdf`);
    window.open(doc.output("bloburl"), "_blank");
  }

  // function PDFTablaGlobal() {
  //   const data = comments.map((a) => { {
  //     let agrupado = data.reduce(function (groups, item) {
  //         const val = item["corte"]
  //         groups[val] = groups[val] || {
  //           corte: item.corte,
  //           cantidad:0
  //         };
  //         groups[val].cantidad += item.cantidad;
  //         groups[val].articulo = item.articulo;
  //         groups[val].corte = item.corte;
  //         groups[val].fecha = item.fecha;
  //         groups[val].cliente = item.cliente;
  //         groups[val].pedido = item.pedido;
  //         return groups;
  //       },[])
  //       let dataFinal2 = Object.values(agrupado);

  //       const data2 = dataFinal2.map((a) => {
  //         return [a.articulo, a.fecha, a.corte, a.cliente, a.pedido, a.cantidad];
    
  //       })
  //     }
  //   });

  //   const dataPDF = Object.values(data);

    


  //   const dataPDFLimpia = dataPDF.filter(function (el) {
  //     return el != null;
  //   });
  //   const doc = new jsPDF("landscape");
  //   var img = new Image();
  //   img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
  //   doc.addImage(img, "png", 230, 30, 45, 15);
  //   var img2 = new Image();
  //   img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
  //   doc.addImage(img2, "png", 120, 15, 60, 25);
  //   doc.text(`Listado Articulos Cortes`, 20, 30);
  //   doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
  //   doc.autoTable({
  //     head: [["Articulo", "Fecha", "Corte", "Cliente", "Pedido", "Cantidad"]],
  //     body: dataPDFLimpia,
  //     startY: 45,
  //     foot: [
  //       [
  //         "",
  //         "",
  //         "",
  //         "",
  //         "Total",
  //         new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCantidad)
  //       ]
  //     ],
  //     showFoot: "lastPage",
  //   });
  //   // doc.save(`Cortes.pdf`);
  //   window.open(doc.output("bloburl"), "_blank");
  // }

  function excel() {
    const dataExcel = ListaCortes.map((a) => {
        return {
          Articulo: a.articulo,
          Color: a.color,
          Talla: a.talla,
          Fecha: a.fecha,
          Corte: a.corte,
          Cliente: a.cliente,
          Pedido: a.pedido,
          Cantidad: a.cantidad,
        };
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoArticulosCortes";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoArticulosCortes",
        sheetFilter: ["Articulo", "Color", "Talla", "Fecha", "Corte", "Cliente", "Pedido", "Cantidad"],
        sheetHeader: ["Articulo", "Color", "Talla", "Fecha", "Corte", "Cliente", "Pedido", "Cantidad"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => { {
      return [a.articulo, a.color, a.talla, a.fecha, a.corte, a.cliente, a.pedido, a.cantidad];
    }
  });
  const dataPDF = Object.values(data);
  const dataPDFLimpia = dataPDF.filter(function (el) {
    return el != null;
  });
  const doc = new jsPDF("landscape");
  var img = new Image();
  img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
  doc.addImage(img, "png", 230, 30, 45, 15);
  var img2 = new Image();
  img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
  doc.addImage(img2, "png", 120, 15, 60, 25);
  doc.text(`Listado Articulos Cortes`, 20, 30);
  doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
  doc.autoTable({
    head: [["Articulo", "Color", "Talla", "Fecha", "Corte", "Cliente", "Pedido", "Cantidad"]],
    body: dataPDFLimpia,
    startY: 45,
    foot: [
      [
        "",
        "",
        "",
        "",
        "",
        "",
        "Total",
        new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCantidad)
      ]
    ],
    showFoot: "lastPage",
  });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Articulos Cortes",
          email: mailTo,
          fileName: "ListadoArticulosCortes.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Cortes.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Articulo", field: "articulo", sortable: true },
    { name: "Color", field: "color", sortable: true },
    { name: "Talla", field: "talla", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Corte", field: "corte", sortable: true },
    { name: "Cliente", field: "cliente", sortable: true },
    { name: "Pedido", field: "pedido", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.cliente.toLowerCase().includes(search.toLowerCase()) ||
          comment.articulo.toLowerCase().includes(search.toLowerCase()) ||
          comment.color.toLowerCase().includes(search.toLowerCase()) ||
          comment.talla.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search) ||
          comment.corte.toString().includes(search) ||
          comment.pedido.toString().includes(search) 
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    let cant = computedComments.map((c) => parseFloat(c.cantidad));
    let TCant = cant.reduce((t, cantidad, index) => t + cantidad, 0);
    setTotalCantidad(TCant);


    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cantidad" &&
      sorting.field != "pedido" &&
      sorting.field != "ocrte" 
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "pedido" ||
        sorting.field == "corte" )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "pedido" ||
        sorting.field == "corte" )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
  ]);


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_produccion ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuProduccion"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
              {/* <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTablaGlobal}
                >
                  PDF Global <i class="far fa-file-pdf"></i>
                </Button> */}
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <h3 align="center">Articulos Cortes</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.articulo}</td>
                      <td>{a.color}</td>
                      <td>{a.talla}</td>
                      <td>{a.fecha}</td>
                      <td>{a.corte}</td>
                      <td>{a.cliente}</td>
                      <td>{a.pedido}</td>
                      <td>{a.cantidad}</td>
                    </tr>
                  );
                }
              })}
              <tr>
              <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita">TOTAL</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalCantidad)}
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
        <ModalHeader toggle={toggleMail}>
          <h4>Enviar Listado Articulos Cortes</h4>
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={mailTo}
            required
            onChange={(e) => {
              setMailTo(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={enviaMail}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoArticulosCortes;
