import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";

function ListadoArticulosPadre() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ARTICULOS_PADRE = process.env.REACT_APP_URL_ARTICULOS_PADRE;
  const URL_DEPARTAMENTOS = process.env.REACT_APP_URL_DEPARTAMENTOS;
  const URL_SUBDEPARTAMENTOS = process.env.REACT_APP_URL_SUBDEPARTAMENTOS;
  const URL_CLASES = process.env.REACT_APP_URL_CLASES;
  const URL_SUBCLASES = process.env.REACT_APP_URL_SUBCLASES;

  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  const [subDepartamentos, setSubDepartamentos] = useState([]);
  const [selectedSubDepartamento, setSelectedSubDepartamento] = useState("");
  const [clases, setClases] = useState([]);
  const [selectedClase, setSelectedClase] = useState("");
  const [subClases, setSubClases] = useState([]);
  const [selectedSubClase, setSelectedSubClase] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [idEdit, setIdEdit] = useState("");
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [activo, setActivo] = useState("");



  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
    .get(URL_ARTICULOS_PADRE, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allDep = response.data;
      let arrayTabla = allDep
      .sort((a, b) => (a.codigoInterno < b.codigoInterno ? 1 : -1))
      .map((a) => {
        return {
          _id: a._id,
          activo: a.is_active,
          codigoInterno: a.codigoInterno,
          departamento: a.departamentos[0].name,
          idDepartamento: a.departamentos[0]._id,
          subDepartamento: a.subDepartamentos[0].name,
          idSubDepartamento: a.subDepartamentos[0]._id,
          clase: a.clases[0].name,
          idClase: a.clases[0]._id,
          subClase: a.subClases[0].name,
          idSubClase: a.subClases[0]._id,
        };
      })
      .filter(function (el) {
        return el != null;
      });

    let dataFinal = Object.values(arrayTabla);

    setComments(dataFinal);
      //
    })
    .catch((err) => {
      console.log(err);
    });


    axios
    .get(URL_DEPARTAMENTOS, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allDep = response.data;
      setDepartamentos(allDep);
      //
    })
    .catch((err) => {
      console.log(err);
    });

    axios
    .get(URL_SUBDEPARTAMENTOS, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allSubDep = response.data;
      setSubDepartamentos(allSubDep);
      //
    })
    .catch((err) => {
      console.log(err);
    });


    axios
    .get(URL_CLASES, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allClase = response.data;
      setClases(allClase);
      //
    })
    .catch((err) => {
      console.log(err);
    });

    axios
    .get(URL_SUBCLASES, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allSubClase = response.data;
      setSubClases(allSubClase);
      //
    })
    .catch((err) => {
      console.log(err);
    });
   
  }, []);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        activo == 0 ||
        activo == a.is_active
      ) {
        return [
          a.codigoInterno,
          a.departamento,
          a.subDepartamento,
          a.clase,
          a.subClase
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Articulos`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Nombre",
          "Departamento",
          "Sub Departamento",
          "Clase",
          "Etiqueta"
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Articulos.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (activo == 0 || activo == a.is_active) {
        return {
          CodigoInterno: a.codigoInterno,
          Departamento: a.departamento,
          SubDepartamento: a.SubDepartamento,
          Clase: a.clase,
          SubClase: a.subClase
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoArticulos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoArticulos",
        sheetFilter: [
          "CodigoInterno",
          "Departamento",
          "SubDepartamento",
          "Clase",
          "SubClase",
        ],
        sheetHeader: [
          "CodigoInterno",
          "Departamento",
          "SubDepartamento",
          "Clase",
          "SubClase",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        activo == 0 ||
        activo == a.is_active
      ) {
        return [
          a.codigoInterno,
          a.departamento,
          a.subDepartamento,
          a.clase,
          a.subClase
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Articulos`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Nombre",
          "Departamento",
          "Sub Departamento",
          "Clase",
          "Etiqueta"
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Articulos",
          email: mailTo,
          fileName: "ListadoArticulos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Articulos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }




  function jalaInfo(
  idEdit,
  departamento,
  subDepartamento,
  clase,
  subClase
  ) {
  setIdEdit(idEdit)
  setSelectedDepartamento(departamento)
  setSelectedSubDepartamento(subDepartamento)
  setSelectedClase(clase)
  setSelectedSubClase(subClase)
    toggleEdit();
  }

  function editArticuloPadre(event) {
    event.preventDefault();
          axios.patch(
            `${URL_ARTICULOS_PADRE}/${idEdit}`,
            {
              departamentos: selectedDepartamento,
              subDepartamentos: selectedSubDepartamento,
              clases: selectedClase,
              subClases: selectedSubClase,  
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
        .then(() => {
          axios.post(
            URL_LOGS,
            {
              tipo: "Editar Articulo Padre",
              detalle: `${idEdit}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );

          toggleEdit();
          Swal.fire("Good job!", "Actualizado con exito", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });

  }

  const headers = [
    { name: "Activo", field: "activo", sortable: true },
    { name: "Codigo Interno", field: "codigoInterno", sortable: false },
    { name: "Departamento", field: "departamento", sortable: true },
    { name: "SubDepartamento", field: "subDepartamento", sortable: true },
    { name: "Clase", field: "clase", sortable: true },
    { name: "SubClase", field: "subClase", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.departamento.toLowerCase().includes(search.toLowerCase()) ||
          comment.subDepartamento.toLowerCase().includes(search.toLowerCase()) || 
          comment.clase.toLowerCase().includes(search.toLowerCase()) ||
          comment.subClase.toLowerCase().includes(search.toLowerCase()) ||
          comment.codigoInterno.toString().includes(search)
      );
    }

    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    activo,
  ]);

 

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_articulos ? (
        <div className="card container col-8">
          <Row>
            <Col md={8}>
              {user.menu_articulos ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ArticulosPadreCreate"
                >
                  Nuevo Articulo
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Articulo
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuCatalogos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Articulos</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Articulos Padre</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
         

            <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={activo}
                required
                onChange={(e) => {
                  setActivo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.activo}</td>
                      <td>{a.codigoInterno}</td>
                      <td>{a.departamento}</td>
                      <td>{a.subDepartamento}</td>
                      <td>{a.clase}</td>
                      <td>{a.subClase}</td>
                      <td>
                          <div>



                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  a._id,
                                  a.idDepartamento,
                                  a.idSubDepartamento,
                                  a.idClase,
                                  a.idSubClase,
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                            <Baja
                              idStatus={a._id}
                              is_active={a.activo}
                              URL_BAJA={process.env.REACT_APP_URL_ARTICULOS_PADRE}
                            />
                          </div>

                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>
            Editar Articulo
          </h4>
        </ModalHeader>
        <ModalBody>
        <Row>
            <Col md={6}>
            <Label className="mr-sm-2">Departamento</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedDepartamento}
                  required
                  onChange={(e) => {
                    setSelectedDepartamento(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {departamentos
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
            </Col>

            <Col md={6}>
            <Label className="mr-sm-2">Sub Departamento</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedSubDepartamento}
                  required
                  onChange={(e) => {
                    setSelectedSubDepartamento(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {subDepartamentos
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
            <Label className="mr-sm-2">Clase</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedClase}
                  required
                  onChange={(e) => {
                    setSelectedClase(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {clases
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
            </Col>

            <Col md={6}>
            <Label className="mr-sm-2">Etiqueta</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedSubClase}
                  required
                  onChange={(e) => {
                    setSelectedSubClase(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {subClases
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editArticuloPadre}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>





      {loader}
    </>
  );
}

export default ListadoArticulosPadre;
