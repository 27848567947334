import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import * as XLSX from "xlsx";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function ComprasContenedorCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_ORDENES_COMPRA = process.env.REACT_APP_URL_ORDENES_COMPRA;
  const URL_COMPRAS = process.env.REACT_APP_URL_COMPRAS;
  const URL_TELAS_OC = process.env.REACT_APP_URL_TELAS_OC;
  const URL_INVENTARIOS_TELAS = process.env.REACT_APP_URL_INVENTARIOS_TELAS;
  const URL_COMPRAS_ACUMULADOS = process.env.REACT_APP_URL_COMPRAS_ACUMULADOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [ordenesCompra, setOrdenesCompra] = useState([]);
  const [selectedOrdenCompra, setSelectedOrdenCompra] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [colores, setColores] = useState([]);
  const [proveedores, setProveedores] = useState([]);

  const [fecha, setFecha] = useState(hoy);
  const [factura_proveedor, setFacturaProveedor] = useState("NA");
  const [observaciones, setObservaciones] = useState("NA");
  const [pais_origen, setPaisOrigen] = useState("Mexico");
  const [total_kilos, setTotalKilos] = useState(0);
  const [total_metros, setTotalMetros] = useState(0);
  const [total_piezas, setTotalPiezas] = useState(0);
  const [total_dinero, setTotalDinero] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [impuestos, setImpuestos] = useState(false);
  const [impuestosString, setImpuestosString] = useState("No");
  const [impuestosPor, setImpuestosPor] = useState(16);

  const [telasOC, setTelasOC] = useState([]);
  const [telasOC2, setTelasOC2] = useState([]);
  const [fecha_orden_compra, setFechaOrdenCompra] = useState("");
  const [plazo, setPlazo] = useState("");
  const [proveedorOrden, setProveedorOrden] = useState("");
  const [fecha_compromiso, setFechaCompromiso] = useState("");
  const [total_kilos_orden, setTotalKilosOrden] = useState(0);
  const [total_metros_orden, setTotalMetrosOrden] = useState(0);
  const [total_piezas_orden, setTotalPiezasOrden] = useState(0);
  const [total_rollos, setTotalRollos] = useState(0);

  const [tipo, setTipo] = useState("");
  const [observacionesOrden, setObservacionesOrden] = useState("");

  const [totalCaptura, setTotalCaptura] = useState(1);
  const [cantidadAgrupado, setCantidadAgrupado] = useState([]);

  const [inputFields2, setInputFields2] = useState([]);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      telasOrden: "",
      telas: "",
      telasName: "",
      unidad: "",
      colores: "",
      coloresName: "",
      cantidad: 0,
      captura: 1,
      costo: 0,
      total: 0,
    },
  ]);

  const [inputFieldsHab, setInputFieldsHab] = useState([
    {
      id: uuidv4(),
      telasOrden: "",
      habilitacion: "",
      unidad: "",
      cantidad: 0,
      captura: 1,
      costo: 0,
      total: 0,
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_ORDENES_COMPRA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allOrdenesCompra = res.data;
        setOrdenesCompra(allOrdenesCompra);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColores = res.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveCompraNueva = async (event) => {
    let subtotalGuarda = subtotal
    let ivaGuarda = iva
    event.preventDefault();
    if (
      fecha != "" &&
      observaciones != "" &&
      factura_proveedor != "" &&
      pais_origen != ""
    ) {
      let kilosTemp = total_kilos_orden * 1.1
      let metrosTemp = total_metros_orden * 1.1
      let kilosTemp2 = total_kilos_orden * .9
      let metrosTemp2 = total_metros_orden * .9
      // if (
      //   (total_kilos > kilosTemp || total_metros > metrosTemp) ||
      //   (total_kilos < kilosTemp2 || total_metros < metrosTemp2) 
      //   ) {
      //   Swal.fire({
      //     title: "Hay una diferencia por mas del 10% de la Orden de Compra!",
      //     text: `Debes de solicitar autorizacion`,
      //     icon: "warning",
      //     showCancelButton: true,
      //     confirmButtonColor: "#3085d6",
      //     cancelButtonColor: "#d33",
      //     confirmButtonText: "Si, Recibir!",
      //   }).then(async (result) => {
      //     if (result.isConfirmed) {

      //       setValidaBoton(false);
      //       toggleProgreso();

      //       let totalRollos =
      //         inputFields2.length +
      //         cantidadAgrupado.length +
      //         cantidadAgrupado.length;
      //       try {
      //         await axios
      //           .post(
      //             URL_COMPRAS,
      //             {
      //               fecha,
      //               ordenesCompra: selectedOrdenCompra,
      //               factura_proveedor,
      //               observaciones,
      //               total_kilos,
      //               total_metros,
      //               total_piezas,
      //               user: user.id,
      //               pais_origen,
      //               total_costo: total_dinero.toFixed(2),
      //               saldo: total_dinero.toFixed(2),
      //               proveedores: proveedorOrden,
      //               subtotal: subtotalGuarda,
      //               iva: ivaGuarda,
      //               documentado: "No"
      //             },
      //             {
      //               headers: {
      //                 Authorization: `Bearer: ${localStorage.getItem(
      //                   "app_token"
      //                 )}`,
      //               },
      //             }
      //           )
      //           .then((data) => {
      //             inputFields2.map((a) => {
      //               if (a.telas != "" && a.cantidad != 0) {
      //                 axios
      //                   .post(
      //                     URL_INVENTARIOS_TELAS,
      //                     {
      //                       compras: data.data._id,
      //                       telas: a.telas,
      //                       telasOC: a.telasOrden,
      //                       colores: a.colores,
      //                       cantidad: parseFloat(a.cantidad),
      //                       captura: a.captura,
      //                       produccion: "No",
      //                     },
      //                     {
      //                       headers: {
      //                         Authorization: `Bearer: ${localStorage.getItem(
      //                           "app_token"
      //                         )}`,
      //                       },
      //                     }
      //                   )
      //                   .then(() => {
      //                     totalRollos = totalRollos - 1;
      //                     if (totalRollos == 0) {
      //                       axios
      //                         .post(
      //                           URL_LOGS,
      //                           {
      //                             tipo: "Crear Compra",
      //                             detalle: `OC ${selectedOrdenCompra}`,
      //                             user: user.id,
      //                           },
      //                           {
      //                             headers: {
      //                               Authorization: `Bearer: ${localStorage.getItem(
      //                                 "app_token"
      //                               )}`,
      //                             },
      //                           }
      //                         )
      //                         .then(() => {
      //                           Swal.fire(
      //                             "Good job!",
      //                             "Creado con exito",
      //                             "success"
      //                           );
      //                           setTimeout(() => {
      //                             window.location.reload();
      //                           }, 4000);
      //                         })
      //                         .catch((error) => {
      //                           Swal.fire({
      //                             icon: "error",
      //                             title: "Oops...",
      //                             text: "Something went wrong!",
      //                             footer: `${error.response.data}`,
      //                           });
      //                           console.log(error);
      //                           setValidaBoton(true);
      //                         });
      //                     }
      //                   });
      //               } else {
      //                 totalRollos = totalRollos - 1;
      //                 if (totalRollos == 0) {
      //                   axios
      //                     .post(
      //                       URL_LOGS,
      //                       {
      //                         tipo: "Crear Compra",
      //                         detalle: `OC ${selectedOrdenCompra}`,
      //                         user: user.id,
      //                       },
      //                       {
      //                         headers: {
      //                           Authorization: `Bearer: ${localStorage.getItem(
      //                             "app_token"
      //                           )}`,
      //                         },
      //                       }
      //                     )
      //                     .then(() => {
      //                       Swal.fire(
      //                         "Good job!",
      //                         "Creado con exito",
      //                         "success"
      //                       );
      //                       setTimeout(() => {
      //                         window.location.reload();
      //                       }, 4000);
      //                     })
      //                     .catch((error) => {
      //                       Swal.fire({
      //                         icon: "error",
      //                         title: "Oops...",
      //                         text: "Something went wrong!",
      //                         footer: `${error.response.data}`,
      //                       });
      //                       console.log(error);
      //                       setValidaBoton(true);
      //                     });
      //                 }
      //               }
      //             });
      //             cantidadAgrupado.map((w) => {
      //               axios
      //                 .patch(
      //                   `${URL_TELAS_OC}editRecibido/${w.telasOrden}`,
      //                   {
      //                     cantidad: w.cantidad,
      //                   },
      //                   {
      //                     headers: {
      //                       Authorization: `Bearer: ${localStorage.getItem(
      //                         "app_token"
      //                       )}`,
      //                     },
      //                   }
      //                 )
      //                 .then(() => {
      //                   totalRollos = totalRollos - 1;
      //                   if (totalRollos == 0) {
      //                     axios
      //                       .post(
      //                         URL_LOGS,
      //                         {
      //                           tipo: "Crear Compra",
      //                           detalle: `OC ${selectedOrdenCompra}`,
      //                           user: user.id,
      //                         },
      //                         {
      //                           headers: {
      //                             Authorization: `Bearer: ${localStorage.getItem(
      //                               "app_token"
      //                             )}`,
      //                           },
      //                         }
      //                       )
      //                       .then(() => {
      //                         Swal.fire(
      //                           "Good job!",
      //                           "Creado con exito",
      //                           "success"
      //                         );
      //                         setTimeout(() => {
      //                           window.location.reload();
      //                         }, 4000);
      //                       })
      //                       .catch((error) => {
      //                         Swal.fire({
      //                           icon: "error",
      //                           title: "Oops...",
      //                           text: "Something went wrong!",
      //                           footer: `${error.response.data}`,
      //                         });
      //                         console.log(error);
      //                         setValidaBoton(true);
      //                       });
      //                   }
      //                 })
      //                 .catch((error) => {
      //                   Swal.fire({
      //                     icon: "error",
      //                     title: "Oops...",
      //                     text: "Something went wrong!",
      //                     footer: `${error.response.data}`,
      //                   });
      //                   console.log(error);
      //                 });
      //             });

      //             // Pegamos a Compras Acumulado

      //             cantidadAgrupado.map((w) => {
      //               axios
      //                 .post(
      //                   `${URL_COMPRAS_ACUMULADOS}`,
      //                   {
      //                     compras: data.data._id,
      //                     telas: w.telas,
      //                     telasOC: w.telasOrden,
      //                     colores: w.colores,
      //                     cantidad: w.cantidad,
      //                     ordenesCompra: selectedOrdenCompra,
      //                     fecha,
      //                     proveedores: proveedorOrden,
      //                     total: w.total,
      //                   },
      //                   {
      //                     headers: {
      //                       Authorization: `Bearer: ${localStorage.getItem(
      //                         "app_token"
      //                       )}`,
      //                     },
      //                   }
      //                 )
      //                 .then(() => {
      //                   totalRollos = totalRollos - 1;
      //                   if (totalRollos == 0) {
      //                     axios
      //                       .post(
      //                         URL_LOGS,
      //                         {
      //                           tipo: "Crear Compra",
      //                           detalle: `OC ${selectedOrdenCompra}`,
      //                           user: user.id,
      //                         },
      //                         {
      //                           headers: {
      //                             Authorization: `Bearer: ${localStorage.getItem(
      //                               "app_token"
      //                             )}`,
      //                           },
      //                         }
      //                       )
      //                       .then(() => {
      //                         Swal.fire(
      //                           "Good job!",
      //                           "Creado con exito",
      //                           "success"
      //                         );
      //                         setTimeout(() => {
      //                           window.location.reload();
      //                         }, 4000);
      //                       })
      //                       .catch((error) => {
      //                         Swal.fire({
      //                           icon: "error",
      //                           title: "Oops...",
      //                           text: "Something went wrong!",
      //                           footer: `${error.response.data}`,
      //                         });
      //                         console.log(error);
      //                         setValidaBoton(true);
      //                       });
      //                   }
      //                 })
      //                 .catch((error) => {
      //                   Swal.fire({
      //                     icon: "error",
      //                     title: "Oops...",
      //                     text: "Something went wrong!",
      //                     footer: `${error.response.data}`,
      //                   });
      //                   console.log(error);
      //                 });
      //             });

      //             // Terminamos de post a compras acumulado
      //           });
      //       } catch (error) {
      //         Swal.fire({
      //           icon: "error",
      //           title: "Oops...",
      //           text: "Something went wrong!",
      //           footer: `${error.response.data}`,
      //         });
      //         console.log(error);
      //       }
      //     } 
      //   });
      // }
      // else{
        setValidaBoton(false);
        toggleProgreso();

        let totalRollos =
          inputFields.length +
          cantidadAgrupado.length +
          cantidadAgrupado.length;
        try {
          await axios
            .post(
              URL_COMPRAS,
              {
                fecha,
                ordenesCompra: selectedOrdenCompra,
                factura_proveedor,
                observaciones,
                total_kilos,
                total_metros,
                total_piezas,
                user: user.id,
                pais_origen,
                total_costo: total_dinero.toFixed(2),
                saldo: total_dinero.toFixed(2),
                proveedores: proveedorOrden,
                subtotal: subtotalGuarda,
                iva: ivaGuarda,
                documentado: "No"
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem(
                    "app_token"
                  )}`,
                },
              }
            )
            .then((data) => {
              inputFields2.map((a) => {
                if (a.telas != "" && a.cantidad != 0) {
                  axios
                    .post(
                      URL_INVENTARIOS_TELAS,
                      {
                        compras: data.data._id,
                        telas: a.telas,
                        telasOC: a.telasOrden,
                        colores: a.colores,
                        cantidad: parseFloat(a.cantidad),
                        captura: a.captura,
                        produccion: "No",
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      totalRollos = totalRollos - 1;
                      if (totalRollos == 0) {
                        axios
                          .post(
                            URL_LOGS,
                            {
                              tipo: "Crear Compra",
                              detalle: `OC ${selectedOrdenCompra}`,
                              user: user.id,
                            },
                            {
                              headers: {
                                Authorization: `Bearer: ${localStorage.getItem(
                                  "app_token"
                                )}`,
                              },
                            }
                          )
                          .then(() => {
                            Swal.fire(
                              "Good job!",
                              "Creado con exito",
                              "success"
                            );
                            setTimeout(() => {
                              window.location.reload();
                            }, 4000);
                          })
                          .catch((error) => {
                            Swal.fire({
                              icon: "error",
                              title: "Oops...",
                              text: "Something went wrong!",
                              footer: `${error.response.data}`,
                            });
                            console.log(error);
                            setValidaBoton(true);
                          });
                      }
                    });
                } else {
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Compra",
                          detalle: `OC ${selectedOrdenCompra}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire(
                          "Good job!",
                          "Creado con exito",
                          "success"
                        );
                        setTimeout(() => {
                          window.location.reload();
                        }, 4000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                }
              });
              cantidadAgrupado.map((w) => {
                axios
                  .patch(
                    `${URL_TELAS_OC}editRecibido/${w.telasOrden}`,
                    {
                      cantidad: w.cantidad,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalRollos = totalRollos - 1;
                    if (totalRollos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Compra",
                            detalle: `OC ${selectedOrdenCompra}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire(
                            "Good job!",
                            "Creado con exito",
                            "success"
                          );
                          setTimeout(() => {
                            window.location.reload();
                          }, 4000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
              });

              // Pegamos a Compras Acumulado

              cantidadAgrupado.map((w) => {
                axios
                  .post(
                    `${URL_COMPRAS_ACUMULADOS}`,
                    {
                      compras: data.data._id,
                      telas: w.telas,
                      telasOC: w.telasOrden,
                      colores: w.colores,
                      cantidad: w.cantidad,
                      ordenesCompra: selectedOrdenCompra,
                      fecha,
                      proveedores: proveedorOrden,
                      total: w.total,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalRollos = totalRollos - 1;
                    if (totalRollos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Compra",
                            detalle: `OC ${selectedOrdenCompra}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire(
                            "Good job!",
                            "Creado con exito",
                            "success"
                          );
                          setTimeout(() => {
                            window.location.reload();
                          }, 4000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
              });

              // Terminamos de post a compras acumulado
            });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        } 
      // }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
    }
  };

  const saveCompraNuevaHab = async (event) => {
    let subtotalGuarda = subtotal
    let ivaGuarda = iva
    event.preventDefault();
    if (
      fecha != "" &&
      observaciones != "" &&
      factura_proveedor != "" &&
      pais_origen != ""
    ) {
      setValidaBoton(false);
      toggleProgreso();

      let totalRollos =
        inputFieldsHab.length +
        cantidadAgrupado.length +
        cantidadAgrupado.length;
      try {
        await axios
          .post(
            URL_COMPRAS,
            {
              fecha,
              ordenesCompra: selectedOrdenCompra,
              factura_proveedor,
              observaciones,
              total_kilos,
              total_metros,
              total_piezas,
              total_faltante:0,
              user: user.id,
              pais_origen,
              total_costo: total_dinero.toFixed(2),
              saldo: total_dinero.toFixed(2),
              proveedores: proveedorOrden,
              subtotal: subtotalGuarda,
              iva: ivaGuarda,
              documentado: "No"
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {
            inputFieldsHab.map((a) => {
              if (a.habilitacion != "" && a.cantidad != 0) {
                axios
                  .post(
                    `${URL_INVENTARIOS_TELAS}/HabilitacionCreate`,
                    {
                      compras: data.data._id,
                      habilitacion: a.habilitacion,
                      telasOC: a.telasOrden,
                      cantidad: parseFloat(a.cantidad),
                      captura: a.captura,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalRollos = totalRollos - 1;
                    if (totalRollos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Compra",
                            detalle: `OC ${selectedOrdenCompra}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 4000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  });
              } else {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Compra",
                        detalle: `OC ${selectedOrdenCompra}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 4000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              }
            });
            cantidadAgrupado.map((w) => {
              axios
                .patch(
                  `${URL_TELAS_OC}editRecibido/${w.telasOrden}`,
                  {
                    cantidad: w.cantidad,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Compra",
                          detalle: `OC ${selectedOrdenCompra}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 4000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            });

            // Pegamos a Compras Acumulado

            cantidadAgrupado.map((w) => {
              axios
                .post(
                  `${URL_COMPRAS_ACUMULADOS}`,
                  {
                    compras: data.data._id,
                    habilitacion: w.habilitacion,
                    telasOC: w.telasOrden,
                    cantidad: w.cantidad,
                    ordenesCompra: selectedOrdenCompra,
                    fecha,
                    proveedores: proveedorOrden,
                    total: w.total,
                    faltante:0,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Compra",
                          detalle: `OC ${selectedOrdenCompra}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 4000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            });

            // Terminamos de post a compras acumulado
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
    }
  };

  const handleAddFields = () => {
    let TC = totalCaptura + 1;
    const ultimo = inputFields[inputFields.length - 1];

    setTotalCaptura(TC);
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        telasOrden: ultimo.telasOrden,
        telas: ultimo.telas,
        telasName: ultimo.telasName,
        unidad: ultimo.unidad,
        colores: ultimo.colores,
        coloresName: ultimo.coloresName,
        cantidad: 0,
        captura: TC,
        costo: ultimo.costo,
        total: 0,
      },
    ]);
  };

  const handleAddFieldsHab = () => {
    let TC = totalCaptura + 1;
    const ultimo = inputFieldsHab[inputFieldsHab.length - 1];

    setTotalCaptura(TC);
    setInputFieldsHab([
      ...inputFieldsHab,
      {
        id: uuidv4(),
        telasOrden: ultimo.telasOrden,
        habilitacion: ultimo.habilitacion,
        unidad: ultimo.unidad,
        cantidad: 0,
        captura: TC,
        costo: ultimo.costo,
        total: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let mts = values.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = values.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);
    let PZ = values.filter((e) => e.unidad == "Piezas");
    let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
    let TP = totalPZ.reduce((t, total, index) => t + total, 0);
    setTotalPiezas(TP);


    let totalDin = inputFields.map((c) => parseFloat(c.total));
    let Din = totalDin.reduce((t, total, index) => t + total, 0);
    setSubtotal(Din);

    if (impuestos == true) {
      let i = parseFloat(Din) * (impuestosPor / 100);
      setIva(i);
      setTotalDinero(parseFloat(Din) + i);
    } else {
      setTotalDinero(parseFloat(Din));
      setIva(0);
    }
  };

  const handleRemoveFieldsHab = (id) => {
    const values = [...inputFieldsHab];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsHab(values);
    let mts = values.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = values.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);
    let PZ = values.filter((e) => e.unidad == "Piezas");
    let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
    let TP = totalPZ.reduce((t, total, index) => t + total, 0);
    setTotalPiezas(TP);

    let totalDin = inputFieldsHab.map((c) => parseFloat(c.total));
    let Din = totalDin.reduce((t, total, index) => t + total, 0);
    setSubtotal(Din);

    if (impuestos == true) {
      let i = parseFloat(Din) * (impuestosPor / 100);
      setIva(i);
      setTotalDinero(parseFloat(Din) + i);
    } else {
      setTotalDinero(parseFloat(Din));
      setIva(0);
    }
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        i.total = parseFloat(event.target.value) * parseFloat(i.costo);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidadHab = (id, event) => {
    const newInputFieldsHab = inputFieldsHab.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        i.total = parseFloat(event.target.value) * parseFloat(i.costo);
      }
      return i;
    });
    setInputFieldsHab(newInputFieldsHab);
    ActualizaTotalesHab();
  };

  function BuscaTela(id, event) {
    telasOC.map((a) => {
      if (a._id == event.target.value) {
        let telasOrden = a._id;
        let idTela = a.telas[0]._id;
        let telasName = a.telas[0].codigo + " " + a.telas[0].nombre;
        let unidadTela = a.telas[0].unidad;
        let color = a.colores[0]._id;
        let coloresName = a.colores[0].name;
        let costo = a.precio;
        handleChangeInputTela(id, telasOrden, idTela, telasName, unidadTela, color, coloresName, costo);
      }
    });
  }

  const handleChangeInputTela = (
    id,
    telasOrden,
    idTela,
    telasName,
    unidadTela,
    color,
    coloresName,
    costo
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.telasOrden = telasOrden;
        i.telas = idTela;
        i.telasName = telasName;
        i.unidad = unidadTela;
        i.colores = color;
        i.coloresName = coloresName;
        i.costo = costo;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let mts = inputFields2.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = inputFields2.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);

    let PZ = inputFields2.filter((e) => e.unidad == "Piezas");
    let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
    let TP = totalPZ.reduce((t, total, index) => t + total, 0);
    setTotalPiezas(TP);

    let totalDin = inputFields2.map((c) => parseFloat(c.total));
    let Din = totalDin.reduce((t, total, index) => t + total, 0);
    setSubtotal(Din);

    let totalRollos = inputFields2.map((c) => parseFloat(c.rollos));
        let Rollos = totalRollos.reduce((t, total, index) => t + total, 0);
        setTotalRollos(Rollos);

    if (impuestos == true) {
      let i = parseFloat(Din) * (impuestosPor / 100);
      setIva(i);
      setTotalDinero(parseFloat(Din) + i);
    } else {
      setTotalDinero(parseFloat(Din));
      setIva(0);
    }

    let agrupado = inputFields2.reduce(function (groups, item) {
      const val = item["telas"] + item["colores"];
      groups[val] = groups[val] || {
        telas: item.telas,
        colores: item.colores,
        cantidad: 0,
        total: 0,
        rollos: 0
      };
      groups[val].cantidad += item.cantidad;
      groups[val].total += item.total;
      groups[val].rollos += item.rollos;
      groups[val].id = item.id;
      groups[val].telas = item.telas;
      groups[val].telasName = item.telasName;
      groups[val].unidad = item.unidad;
      groups[val].coloresName = item.coloresName;
      groups[val].telasOrden = item.telasOrden;
      return groups;
    }, []);

    let CA = Object.values(agrupado);
    setCantidadAgrupado(CA);
  }

  const calculaIVA = (e) => {
    setImpuestos(e);
    if (e == true) {
      let i = parseFloat(subtotal) * (impuestosPor / 100);
      setIva(i);
      setTotalDinero(parseFloat(subtotal) + i);
    } else {
      setTotalDinero(parseFloat(subtotal));
      setIva(0);
    }
  };

  const calculaPor = (e) => {
    setImpuestosPor(e);
    if (impuestos == true) {
      let i = parseFloat(subtotal) * (e / 100);
      setIva(i);
      setTotalDinero(parseFloat(subtotal) + i);
    } else {
      setTotalDinero(parseFloat(subtotal));
      setIva(0);
    }
  };

  function BuscaHabilitacion(id, event) {
    telasOC.map((a) => {
      if (a._id == event.target.value) {
        let telasOrden = a._id;
        let idHabilitacion = a.habilitacion[0]._id;
        let unidadHabiltacion = a.habilitacion[0].unidad;
        let costo = a.precio;
        handleChangeInputHabilitacion(
          id,
          telasOrden,
          idHabilitacion,
          unidadHabiltacion,
          costo
        );
      }
    });
  }

  const handleChangeInputHabilitacion = (
    id,
    telasOrden,
    idHabilitacion,
    unidadHabiltacion,
    costo
  ) => {
    const newInputFieldsHab = inputFieldsHab.map((i) => {
      if (id === i.id) {
        i.telasOrden = telasOrden;
        i.habilitacion = idHabilitacion;
        i.unidad = unidadHabiltacion;
        i.costo = costo;
      }
      return i;
    });
    setInputFieldsHab(newInputFieldsHab);
    ActualizaTotalesHab();
  };

  function ActualizaTotalesHab() {
    let mts = inputFieldsHab.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = inputFieldsHab.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);

    let PZ = inputFieldsHab.filter((e) => e.unidad == "Piezas");
    let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
    let TP = totalPZ.reduce((t, total, index) => t + total, 0);
    setTotalPiezas(TP);

    let totalDin = inputFieldsHab.map((c) => parseFloat(c.total));
    let Din = totalDin.reduce((t, total, index) => t + total, 0);
    setSubtotal(Din);

    if (impuestos == true) {
      let i = parseFloat(Din) * (impuestosPor / 100);
      setIva(i);
      setTotalDinero(parseFloat(Din) + i);
    } else {
      setTotalDinero(parseFloat(Din));
      setIva(0);
    }

    let agrupado = inputFieldsHab.reduce(function (groups, item) {
      const val = item["habilitacion"];
      groups[val] = groups[val] || {
        habilitacion: item.habilitacion,
        cantidad: 0,
        total: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].total += item.total;
      groups[val].id = item.id;
      groups[val].habilitacion = item.habilitacion;
      groups[val].unidad = item.unidad;
      groups[val].telasOrden = item.telasOrden;
      return groups;
    }, []);

    let CA = Object.values(agrupado);
    setCantidadAgrupado(CA);
  }

  function jalaOrden(e) {
    setSelectedOrdenCompra(e);
    setTelasOC([]);
    setInputFields([
      {
        id: uuidv4(),
        telasOrden: "",
        telas: "",
        unidad: "",
        colores: "",
        cantidad: 0,
        captura: 1,
        costo: 0,
        total: 0,
      },
    ]);

    setInputFieldsHab([
      {
        id: uuidv4(),
        telasOrden: "",
        habilitacion: "",
        unidad: "",
        cantidad: 0,
        captura: 1,
        costo: 0,
        total: 0,
      },
    ]);

    ordenesCompra.map((a) => {
      if (a._id == e) {
        setFechaOrdenCompra(a.fecha);
        setPlazo(a.plazo);
        setFechaCompromiso(a.fecha_compromiso);
        setTotalKilosOrden(a.total_kilos);
        setTotalMetrosOrden(a.total_metros);
        setTotalPiezasOrden(a.total_piezas);
        setProveedorOrden(a.proveedores[0]._id);
        setObservacionesOrden(a.observaciones);
        setTipo(a.tipo);
        if(a.fecha_compromiso < hoy){
          Swal.fire({
            title: "Estas recibiendo una Orden de Compra vencida!",
            text: `Debes de solicitar autorizacion`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, Recibir!",
          })
        }
      }
    });

    axios
      .get(`${URL_TELAS_OC}/ordenesCompra/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTelasOC = res.data;
        setTelasOC(allTelasOC);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function importarArchivo(inputField, event){
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let hojas = [];
   
    if (name === "file") {
  
      let reader = new FileReader();
        reader.readAsArrayBuffer(target.files[0]);
        reader.onloadend = (e) => {
          var data = new Uint8Array(e.target.result);
          var workbook = XLSX.read(data, { type: "array" });
  
          workbook.SheetNames.forEach(function (sheetName) {
            // Here is your object
            var XL_row_object = XLSX.utils.sheet_to_json(
              workbook.Sheets[sheetName]
            );
            hojas.push({
              data: XL_row_object,
              sheetName,
            });
          });
  
          let A1 = hojas[0].data
  
          let TC = totalCaptura
          A1.map((a)=>{
             TC = TC + 1
            setTotalCaptura(TC)
          inputFields2.push({
            id: uuidv4(),
            telasOrden: inputField.telasOrden,
            telas: inputField.telas,
            unidad:inputField.unidad,
            colores: inputField.colores,
            cantidad: a.cantidad,
            captura:TC,
            costo:inputField.costo,
            total: parseFloat(a.cantidad) * parseFloat(inputField.costo),
            telasName: inputField.telasName,
            coloresName: inputField.coloresName,
            rollos: parseFloat(1)
          })
          })
  
          ActualizaTotales()
        }
    }
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.compras_create ? (
        <div className="card container col-10">
          <Row>
            <Col md={2}>
              <Button
                size="sm"
                href="/ListadoCompras"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row>
          <h3 align="center">Nueva Compra Contenedor</h3>
          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Orden de Compra</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedOrdenCompra}
                required
                onChange={(e) => jalaOrden(e.target.value)}
              >
                <option value="0">Selecciona una Orden</option>
                {ordenesCompra
                  .sort((a, b) => (a.idOrdenCompra < b.idOrdenCompra ? 1 : -1))
                  .map((a) => {
                    if (
                      a.status == "Abierto" &&
                      a.is_active == "Si" &&
                      a.autorizado == "Si" &&
                      a.tipo == "Tela"
                    ) {
                      return (
                        <option value={a._id}>
                          {a.idOrdenCompra} {a.proveedores[0].razon_social}
                        </option>
                      );
                    }
                  })}
              </Input>
            </Col>
            <Col md={3}>
              <Label>Fecha OC</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha_orden_compra}
                required
                disabled
                onChange={(e) => {
                  setFechaOrdenCompra(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Plazo</Label>
              <Input
                type="text"
                placeholder="Plazo"
                value={plazo}
                required
                disabled
                onChange={(e) => {
                  setPlazo(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Fecha Compromiso</Label>
              <Input
                type="date"
                placeholder="Fecha Compromiso"
                value={fecha_compromiso}
                required
                disabled
                onChange={(e) => {
                  setFechaCompromiso(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label>Fecha</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                // disabled
                onChange={(e) => {
                  setFecha(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Factura Proveedor</Label>
              <Input
                type="text"
                placeholder="Factura Proveedor"
                value={factura_proveedor}
                required
                onChange={(e) => {
                  setFacturaProveedor(e.target.value);
                }}
              />
            </Col>

            <Col md={3}>
              <Label>Pais Origen</Label>
              <Input
                type="text"
                placeholder="Pais Origen"
                value={pais_origen}
                required
                onChange={(e) => {
                  setPaisOrigen(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="text"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
          </Row>

         

          <Row>
            <Col md={12}>
              <h4 id="logoutBoton">
                TOTALES {new Intl.NumberFormat("en-US").format(total_kilos)} kg.
                / {new Intl.NumberFormat("en-US").format(total_metros)} mts. /{" "}
                {new Intl.NumberFormat("en-US").format(total_piezas)} pzas. /{" "}
                {new Intl.NumberFormat("en-US").format(total_rollos)}{" "}
                Rollos
              </h4>
            </Col>
          </Row>
          <Row>
          <Col md={2}>
              <h3></h3>
              <Input
                type="checkbox"
                onChange={(event) => calculaIVA(event.currentTarget.checked)}
                checked={impuestos}
              />
              <Label className="subMenuTitulo">Costo mas IVA?</Label>
            </Col>
            {impuestos ? (
              <>
                <Col sm={2}>
                  <Label for="exampleEmail" className="mr-sm-2">
                    IVA
                  </Label>
                  <Input
                    name="iva"
                    value={iva}
                    disabled
                  />
                </Col>
                <Col sm={2}>
                  <Label for="exampleEmail" className="mr-sm-2">
                    % IVA
                  </Label>
                  <Input
                    name="impuestosPor"
                    value={impuestosPor}
                    onValueChange={(value) => calculaPor(value)}
                  />
                </Col>
              </>
            ) : undefined}

          <Col md={3}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones Orden Compra
              </Label>
              <Input
                type="text"
                placeholder="ObservacionesOrden"
                value={observacionesOrden}
                disabled
              />
            </Col>
          </Row>

          <br />

          <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Articulos</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Color</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Cantidad</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Rollos</Label>
                </Col>
              </Row>
                {cantidadAgrupado.map((ag)=>{
                  return (
                    <>
                    <Row>
                    <Col md={2}>
                    <Label className="mr-sm-2">{ag.telasName}</Label>
                      </Col> 
                    <Col md={2}>
                    <Label className="mr-sm-2">{ag.coloresName}</Label>
                      </Col>
                    <Col md={2}>
                    <Label className="mr-sm-2">{ag.cantidad}</Label>
                      </Col> 
                    <Col md={2}>
                    <Label className="mr-sm-2">{ag.rollos}</Label>
                      </Col> 
                    </Row>
                    </>
                    )
                })}
                <br />
          {/* Tabla Articulos */}

          {tipo == "Tela" ? (
            <>
              <Row>
                <Col md={4}>
                  <Label className="mr-sm-2">Articulos</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Unidad</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Costo</Label>
                </Col>
              </Row>

              {inputFields.map((inputField) => (
                <div key={inputField.id}>
                  <Row>
                    <Col md={4}>
                      <Input
                        bsSize="sm"
                        name="telas"
                        type="select"
                        value={inputField.telasOrden}
                        required
                        onChange={(event) => {
                          BuscaTela(inputField.id, event);
                        }}
                      >
                        <option value="">Selecciona</option>
                        {telasOC
                          .sort((a, b) =>
                            a.telas[0].codigo > b.telas[0].codigo ? 1 : -1
                          )
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                {a.telas[0].codigo} / {a.telas[0].nombre} /{" "}
                                {a.colores[0].name}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="unidad"
                        type="string"
                        value={inputField.unidad}
                        disabled
                      ></Input>
                    </Col>

                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="costo"
                        type="number"
                        placeholder="Costo"
                        value={inputField.costo}
                        disabled
                      />
                    </Col>
                    <Col>
                    <Input
                      required
                      type="file"
                      name="file"
                      id="file"
                      onChange={(event) =>
                        importarArchivo(inputField, event)
                      }
                    />
                    </Col>

                    <Col>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFields.length === 1}
                        onClick={() => handleRemoveFields(inputField.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={handleAddFields}
                        tabindex="-1"
                      >
                        <i class="fas fa-plus"></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}
              <Row>
                <Col md={12}>
                  <h4 id="logoutBoton">
                    TOTALES {new Intl.NumberFormat("en-US").format(total_kilos)}{" "}
                    kg. / {new Intl.NumberFormat("en-US").format(total_metros)}{" "}
                    mts. / {new Intl.NumberFormat("en-US").format(total_piezas)}{" "}
                    pzas. /{" "}
                    {new Intl.NumberFormat("en-US").format(total_rollos)}{" "}
                    Rollos
                  </h4>
                </Col>
              </Row>
              <br />
              {/* {validaBoton ? ( */}
              <>
                <Row>
                  <Button
                    type="submit"
                    className="btn btn-success"
                    onClick={saveCompraNueva}
                  >
                    Guardar
                  </Button>
                </Row>
              </>

              {/* ) : (
                <>
                <Row>
                <Button type="submit" className="btn btn-success" disabled>
                  Guardar
                </Button>
              <Button
                href="/ListadoCompras"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
                </Row>
                </>
              )} */}
              <br />
            </>
          ) : undefined}

          {tipo == "Habilitacion" ? (
            <>
              <Row>
                <Col md={4}>
                  <Label className="mr-sm-2">Articulos</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Unidad</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Cantidad</Label>
                </Col>
              </Row>

              {inputFieldsHab.map((inputField) => (
                <div key={inputField.id}>
                  <Row>
                    <Col md={4}>
                      <Input
                        bsSize="sm"
                        name="telas"
                        type="select"
                        value={inputField.telasOrden}
                        required
                        onChange={(event) => {
                          BuscaHabilitacion(inputField.id, event);
                        }}
                      >
                        <option value="">Selecciona</option>
                        {telasOC
                          .sort((a, b) =>
                            a.habilitacion[0].nombre > b.habilitacion[0].nombre
                              ? 1
                              : -1
                          )
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                {a.habilitacion[0].nombre}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="unidad"
                        type="string"
                        value={inputField.unidad}
                        disabled
                      ></Input>
                    </Col>

                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={inputField.cantidad}
                        required
                        onChange={(event) =>
                          handleChangeInputCantidadHab(inputField.id, event)
                        }
                      />
                    </Col>
                    <Col>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFieldsHab.length === 1}
                        onClick={() => handleRemoveFieldsHab(inputField.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={handleAddFieldsHab}
                        tabindex="-1"
                      >
                        <i class="fas fa-plus"></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}
              <Row>
                <Col md={12}>
                  <h4 id="logoutBoton">
                    TOTALES {new Intl.NumberFormat("en-US").format(total_kilos)}{" "}
                    kg. / {new Intl.NumberFormat("en-US").format(total_metros)}{" "}
                    mts. / {new Intl.NumberFormat("en-US").format(total_piezas)}{" "}
                    pzas. /{" "}
                    {new Intl.NumberFormat("en-US").format(total_rollos)}{" "}
                    Rollos
                  </h4>
                </Col>
              </Row>

              <br />
              {validaBoton ? (
                <>
                  <Row>
                    <Button
                      type="submit"
                      className="btn btn-success"
                      onClick={saveCompraNuevaHab}
                    >
                      Guardar
                    </Button>
                  </Row>
                </>
              ) : (
                <>
                  <Row>
                    <Button type="submit" className="btn btn-success" disabled>
                      Guardar
                    </Button>
                    <Button
                      href="/ListadoCompras"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      Regresar
                    </Button>
                  </Row>
                </>
              )}
              <br />
            </>
          ) : undefined}

          {/* Termina Tabla Habilitacion */}

          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios minutos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ComprasContenedorCreate;
