import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultipleSurtido from "../../StatusMultipleSurtido";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function ListadoPendientePedidoCliente() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_PEDIDOS_CLIENTE = process.env.REACT_APP_URL_PEDIDOS_CLIENTE;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_ARTICULOS_PEDIDO_CLIENTE =
    process.env.REACT_APP_URL_ARTICULOS_PEDIDO_CLIENTE;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");

  const [clientesName, setClientesName] = useState([]);
  const [clientesCodigo, setClientesCodigo] = useState([]);

  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedAreaFiltro, setSelectedAreaFiltro] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");

  const [numero_pedido, setNumeroPedido] = useState("");
  const [pedidoCliente, setPedidoCliente] = useState("");

  const [idColaboradores, setIdColaboradores] = useState("");
  const [idCompradores, setIdCompradores] = useState("");
  const [idArticulo, setIdArticulo] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [descuento, setDescuento] = useState(0);

  const [fecha, setFecha] = useState(hoy);
  const [fechaCancelacion, setFechaCancelacion] = useState("");
  const [fechaCita, setFechaCita] = useState("");
  const [fechaConfirmacion, setFechaConfirmacion] = useState("");

  const [observaciones, setObservaciones] = useState("NA");
  const [total, setTotal] = useState(0);

  const [pedidos, setPedidos] = useState([]);
  const [selectedPedido, setSelectedPedido] = useState("");
  const [cliente, setCliente] = useState("");

  const [articulosPed, setArticulosPed] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(true);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [abrirSurtir, setAbrirSurtir] = useState(false);

  const [activo, setActivo] = useState("");
  const [impuestos, setImpuestos] = useState(0);
  const [comisionFlete, setComisionFlete] = useState(0);
  const [comisionTDC, setComisionTDC] = useState(0);

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cantidad: 0,
      idArtPed: "",
      precio: 0,
      precioEtiqueta: 0,
      total: 0,
      pendSurtir: 0,
      cantPed: 0,
      idTalla: "",
      talla: "",
      idColor: "",
      color: "",
      descuento: 0,
    },
  ]);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    setSelectedArea(user.areas);
    axios
      .get(`${URL_PEDIDOS}PendienteSurtir`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPedidos = response.data;
        // Array para pagination
        let arrayTabla = allPedidos
          .sort((a, b) => (a.idPedido < b.idPedido ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idPedido,
              activo: a.is_active,
              articulos:
                "Cod: " +
                a.articulos[0].codigoCliente +
                " / Ref: " +
                a.articulos[0].codigo +
                " / Desc: " +
                a.articulos[0].descripcion,
              fecha: a.fecha,
              fecha_cancelacion: a.fecha_cancelacion,
              fecha_confirmacion: a.fecha_confirmacion,
              colaboradores:
                a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
              idColaboradores: a.colaboradores[0]._id,
              idCompradores: a.compradores[0]._id,
              observaciones: a.observaciones,
              total_general: a.total_general,
              clientes: a.clientes[0].nombre_comercial,
              clientesCodigo: a.clientes[0].codigo,
              idClientes: a.clientes[0]._id,
              total_piezas: a.total_piezas,
              status: a.status,
              autorizado: a.autorizado,
              surtido: a.surtido,
              pendienteSurtir: a.pendiente_surtir,
              impuestos: a.impuestos,
              pedidoCliente: a.pedidoCliente,
              idArticulo: a.articulos[0]._id,
              empresa: a.empresas[0]._id,
              descuento: a.descuento,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);

        setPedidos(allPedidos);
        setComments(data);

        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.colaboradores,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Pedidos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Pedidos.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return {
          Activo: a.activo,
          Numero: a.numero,
          Fecha: a.fecha,
          Vendedor: a.colaboradores,
          Cliente: a.clientes,
          Cantidad: a.total_cantidad,
          Total: a.total_general,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoPedidos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoPedidos",
        sheetFilter: [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
        sheetHeader: [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.colaboradores,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Ordenes de Compra`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Pedidos",
          email: mailTo,
          fileName: "ListadoPedidos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Pedidos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    // { name: "Activo", field: "activo", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Vendedor", field: "colaboradores", sortable: true },
    { name: "Articulo", field: "articulos", sortable: true },
    { name: "Fecha Cancelacion", field: "fecha_cancelacion", sortable: true },
    { name: "Fecha Confirmacion", field: "fecha_confirmacion", sortable: true },
    { name: "Cantidad", field: "total_piezas", sortable: true },
    { name: "Pedido", field: "surtir", sortable: false },
    // { name: "Cerrar", field: "surtido", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.colaboradores.toLowerCase().includes(search.toLowerCase()) ||
          comment.articulos.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.fecha_cancelacion
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.fecha_confirmacion
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.total_piezas.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idColaboradores.includes(selectedColaborador)
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "total_piezas" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "total_piezas" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "total_piezas" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedColaborador,
    activo,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  // save
  const saveSurtidos = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();

    let totalArticulos = inputFields.length;

    let STotal = inputFields.map((c) => parseFloat(c.total));
    let ST = STotal.reduce((t, total, index) => t + total, 0);

    try {
      await axios
        .post(
          URL_PEDIDOS_CLIENTE,
          {
            fecha,
            clientes: cliente,
            colaboradores: idColaboradores,
            fecha_cancelacion: fechaCancelacion,
            fecha_cita: fechaCita,
            fecha_confirmacion: fechaConfirmacion,
            observaciones,
            total_general: ST,
            total_piezas: total,
            pedidoCliente,
            compradores: idCompradores,
            surtido: 0,
            pendiente_surtir: total,
            articulos: idArticulo,
            pedidos: selectedPedido,
            status: "Abierto",
            empresas: empresa,
            descuento,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            if (a.cantidad > 0) {
              axios
                .post(
                  URL_ARTICULOS_PEDIDO_CLIENTE,
                  {
                    pedidos: selectedPedido,
                    pedidosCliente: data.data._id,
                    articulos: a.articulos,
                    articulosPedido: a.idArtPed,
                    tallas: a.idTalla,
                    colores: a.idColor,
                    cantidad: parseFloat(a.cantidad),
                    surtido: 0,
                    pendiente_surtir: parseFloat(a.cantidad),
                    precio: a.precio,
                    descuento: a.descuento,
                    total: a.total,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Pedido Cliente",
                          detalle: `${numero_pedido}  ${total}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", `Creado con exito, Pedido ${data.data.idPedidoCliente}`, "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 2000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    // footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            } else {
              totalArticulos = totalArticulos - 1;
              if (totalArticulos == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Pedido Cliente",
                      detalle: `${numero_pedido} ${total}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", `Creado con exito, Pedido ${data.data.idPedidoCliente}`, "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 2000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              }
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            // footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        // footer: `${error.response.data}`,
      });
      console.log(error);
      setValidaBoton(true);
    }
  };

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        cantidad: 0,
        idArtPed: "",
        precio: 0,
        precioEtiqueta: 0,
        total: 0,
        pendSurtir: 0,
        cantPed: 0,
        idTalla: "",
        talla: "",
        idColor: "",
        color: "",
        descuento: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let cant = values.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (i.pendSurtir >= event.target.value || event.target.value == "") {
          i[event.target.name] = event.target.value;

          i.total = (parseFloat(event.target.value) * parseFloat(i.precio) - (parseFloat(event.target.value) * parseFloat(i.precio) * i.descuento) / 100).toFixed(2);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede tu pedido!",
            showConfirmButton: false,
          });
          i.cantidad = 0;
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let cant = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);
  }

  function seleccionarPedido(
    id,
    numero,
    idClientes,
    clientes,
    clientesCodigo,
    idColaboradores,
    idCompradores,
    idArticulo,
    empresa,
    descuento,
    fecha_cancelacion,
    fecha_confirmacion,
    pedidoCliente,
    pendienteSurtir
  ) {
    setSelectedPedido(id);
    setNumeroPedido(numero);
    setCliente(idClientes);
    setClientesName(clientes);
    setClientesCodigo(clientesCodigo);
    setIdColaboradores(idColaboradores);
    setIdCompradores(idCompradores);
    setIdArticulo(idArticulo);
    setEmpresa(empresa);
    setDescuento(descuento);
    setFechaConfirmacion(fecha_confirmacion);
    setFechaCancelacion(fecha_cancelacion);
    setPedidoCliente(pedidoCliente);
    setObservaciones("NA");
    setTotal(pendienteSurtir);

    axios
      .get(`${URL_ARTICULOS_PEDIDO}/pedidos/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosPed = res.data;
        setArticulosPed(allArticulosPed);

        let arrayInputFields = allArticulosPed.map((a) => {
          return {
            id: uuidv4(),
            articulos: a.articulos[0]._id,
            cantidad: a.pendiente_surtir,
            idArtPed: a._id,
            precio: a.precio,
            precioEtiqueta: a.precioEtiqueta,
            total: (a.pendiente_surtir * a.precio - (a.pendiente_surtir * a.precio * a.descuento) / 100).toFixed(2),
            pendSurtir: a.pendiente_surtir,
            cantPed: a.cantidad,
            idTalla: a.tallas[0]._id,
            talla: a.tallas[0].name,
            idColor: a.colores[0]._id,
            color: a.colores[0].name,
            descuento: a.descuento,
          };
        });

        let dataIF = Object.values(arrayInputFields);

        setInputFields(dataIF);
      })
      .catch((err) => {
        console.log(err);
      });

    setAbrirSurtir(true);
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_pedidos ? (
        <div className="card col-12">
          {!abrirSurtir ? (
            <div>
              <Row>
                <Col md={8}>
                  <Button
                    size="sm"
                    href="/MenuVentas"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    Regresar
                  </Button>
                </Col>
                <Col sm={4}>
                  <ButtonGroup id="logoutBoton">
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      onClick={PDFTabla}
                    >
                      PDF <i class="far fa-file-pdf"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      onClick={toggleMail}
                      color="info"
                    >
                      eMail <i class="fas fa-at"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="primary"
                      onClick={excel}
                    >
                      Excel <i class="far fa-file-excel"></i>
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                <ModalHeader toggle={toggleMail}>
                  <h4>Enviar Listado Pedidos Cliente</h4>
                </ModalHeader>
                <ModalBody>
                  <Label className="mr-sm-2">Email</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={mailTo}
                    required
                    onChange={(e) => {
                      setMailTo(e.target.value);
                    }}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button size="sm" color="success" onClick={enviaMail}>
                    Enviar
                  </Button>
                </ModalFooter>
              </Modal>
              <h3 align="center">Crear Pedidos Cliente</h3>
              <div className="row">
                <div className="col-md-8">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
                <div className="col-md-4 d-flex flex-row-reverse">
                  <Search
                    onSearch={(value) => {
                      setSearch(value);
                      setCurrentPage(1);
                    }}
                  />
                </div>
              </div>

              <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha Inicio</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaInicio}
                    required
                    onChange={(e) => {
                      setSelectedFechaInicio(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha Fin</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaFin}
                    required
                    onChange={(e) => {
                      setSelectedFechaFin(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label>Clientes</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedCliente}
                    onChange={(e) => {
                      setSelectedCliente(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="0">Selecciona</option>
                    {clientes
                      .sort((a, b) =>
                        a.nombre_comercial > b.nombre_comercial ? 1 : -1
                      )
                      .map((a) => {
                        return (
                          <option value={a._id}>{a.nombre_comercial}</option>
                        );
                      })}
                  </Input>
                </Col>

                <Col md={2}>
                  <Label>Vendedor</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedColaborador}
                    onChange={(e) => {
                      setSelectedColaborador(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">Selecciona un Vendedor</option>
                    {colaboradores
                      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.nombre} {a.apellido}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
              </Row>
              <br />
              <Table
                size="sm"
                striped
                borderless
                className="table-responsive-xl"
              >
                <TableHeader
                  headers={headers}
                  onSorting={(field, order) => setSorting({ field, order })}
                />
                <tbody>
                  {commentsData.map((a) => {
                    return (
                      <tr>
                        <td>{a.numero}</td>
                        <td>{a.fecha}</td>
                        <td>{a.clientes}</td>
                        <td>{a.colaboradores}</td>
                        <td>{a.articulos}</td>
                        <td>{a.fecha_cancelacion}</td>
                        <td>{a.fecha_confirmacion}</td>
                        <td>
                          {new Intl.NumberFormat("en-US").format(
                            a.total_piezas
                          )}
                        </td>

                        <td>
                          <Button
                            color="info"
                            id="Editar"
                            size="sm"
                            onClick={(e) =>
                              seleccionarPedido(
                                a._id,
                                a.numero,
                                a.idClientes,
                                a.clientes,
                                a.clientesCodigo,
                                a.idColaboradores,
                                a.idCompradores,
                                a.idArticulo,
                                a.empresa,
                                a.descuento,
                                a.fecha_cancelacion,
                                a.fecha_confirmacion,
                                a.pedidoCliente,
                                a.pendienteSurtir
                              )
                            }
                          >
                            <i class="fas fa-shipping-fast"></i>
                          </Button>
                        </td>
                        {/* <td>
                            <StatusMultipleSurtido
                              idStatus={a._id}
                              status={a.status}
                              URL_STATUS={URL_PEDIDOS_CLIENTE}
                              hijo={`${URL_ARTICULOS_PEDIDO_CLIENTE}/baja`}
                            />
                          </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="col-md-6">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          ) : (
            // Termina Abrir Surtir
            <div className="card container col-md-12">
              <h4>Pedido {numero_pedido}</h4>
              <h4>
                Cliente: {clientesCodigo} {clientesName}
              </h4>

              <Form onSubmit={saveSurtidos}>
                <Row>
                  <Col md={2}>
                    <Label>Fecha</Label>
                    <Input type="date" value={fecha} required disabled />
                  </Col>

                  <Col md={2}>
                    <Label>Fecha Cancelacion</Label>
                    <Input
                      type="date"
                      value={fechaCancelacion}
                      required
                      onChange={(e) => {
                        setFechaCancelacion(e.target.value);
                      }}
                    />
                  </Col>

                  <Col md={2}>
                    <Label>Fecha Confirmacion</Label>
                    <Input
                      type="date"
                      value={fechaConfirmacion}
                      required
                      onChange={(e) => {
                        setFechaConfirmacion(e.target.value);
                      }}
                    />
                  </Col>

                  <Col md={2}>
                    <Label>Fecha Entrega</Label>
                    <Input
                      type="date"
                      value={fechaCita}
                      required
                      onChange={(e) => {
                        setFechaCita(e.target.value);
                      }}
                    />
                  </Col>

                  <Col md={2}>
                    <Label>Impuestos %</Label>
                    <Input
                      type="number"
                      placeholder="Impuestos"
                      value={impuestos}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={2}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Pedido Cliente
                    </Label>
                    <Input
                      type="text"
                      placeholder="Pedido Cliente"
                      value={pedidoCliente}
                      required
                      onChange={(e) => {
                        setPedidoCliente(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Observaciones
                    </Label>
                    <Input
                      type="text"
                      placeholder="Observaciones"
                      value={observaciones}
                      required
                      onChange={(e) => {
                        setObservaciones(e.target.value);
                      }}
                    />
                  </Col>
                </Row>

                <br />
                {/* Tabla Articulos */}

                <Row>
                  <Col md={4}>
                    <Label className="mr-sm-2">Modelo</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Color</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Talla</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Precio</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Precio Etiqueta</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cant Pedido</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Pendiente</Label>
                  </Col>

                  <Col md={1}>
                    <Label className="mr-sm-2">Cantidad</Label>
                  </Col>
                </Row>

                {inputFields
                  .sort(
                    (a, b) =>
                      a.color.localeCompare(b.color) ||
                      a.talla.localeCompare(b.talla)
                  )
                  .map((inputField) => (
                    <div key={inputField.id}>
                      <Row>
                        <Col md={4}>
                          <Input
                            bsSize="sm"
                            name="articulos"
                            type="select"
                            value={inputField.articulos}
                            disabled
                          >
                            <option value="">Selecciona un Articulo</option>
                            {articulosPed
                              .sort((a, b) =>
                                a.articulos[0].codigoCliente >
                                b.articulos[0].codigoCliente
                                  ? 1
                                  : -1
                              )
                              .map((a) => {
                                return (
                                  <option value={a.articulos[0]._id}>
                                    Cod:{a.articulos[0].codigoCliente} / Ref:{" "}
                                    {a.articulos[0].codigo} / Desc:{" "}
                                    {a.articulos[0].descripcion}
                                  </option>
                                );
                              })}
                          </Input>
                        </Col>
                        <Col md={1}>
                          <Input
                            bsSize="sm"
                            name="color"
                            type="text"
                            min="0"
                            step="any"
                            placeholder="color"
                            value={inputField.color}
                            disabled
                          />
                        </Col>
                        <Col md={1}>
                          <Input
                            bsSize="sm"
                            name="talla"
                            type="text"
                            min="0"
                            step="any"
                            placeholder="talla"
                            value={inputField.talla}
                            disabled
                          />
                        </Col>
                        <Col md={1}>
                          <Input
                            bsSize="sm"
                            name="precio"
                            type="number"
                            min="0"
                            step="any"
                            placeholder="Precio"
                            value={inputField.precio}
                            disabled
                          />
                        </Col>
                        <Col md={1}>
                          <Input
                            bsSize="sm"
                            name="precioEtiqueta"
                            type="number"
                            min="0"
                            step="any"
                            placeholder="PrecioEtiqueta"
                            value={inputField.precioEtiqueta}
                            disabled
                          />
                        </Col>
                        <Col md={1}>
                          <Input
                            bsSize="sm"
                            name="cantPed"
                            type="number"
                            min="0"
                            step="any"
                            placeholder="Cant Ped"
                            value={inputField.cantPed}
                            disabled
                          />
                        </Col>
                        <Col md={1}>
                          <Input
                            bsSize="sm"
                            name="pendSurtir"
                            type="number"
                            min="0"
                            step="any"
                            placeholder="Cantidad"
                            value={inputField.pendSurtir}
                            required
                            disabled
                          />
                        </Col>

                        <Col md={1}>
                          <Input
                            bsSize="sm"
                            name="cantidad"
                            type="number"
                            min="0"
                            step="any"
                            placeholder="Cantidad"
                            value={inputField.cantidad}
                            required
                            onChange={(event) =>
                              handleChangeInputCantidad(inputField.id, event)
                            }
                          />
                        </Col>

                        <Col>
                          <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            disabled={inputFields.length === 1}
                            onClick={() => handleRemoveFields(inputField.id)}
                            tabindex="-1"
                          >
                            <i class="fas fa-minus"></i>
                          </Button>
                          <Button
                            size="sm"
                            className="btn"
                            color="info"
                            onClick={handleAddFields}
                            tabindex="-1"
                          >
                            <i class="fas fa-plus"></i>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  ))}
                <Row>
                  <Col md={10}>
                    <h4 id="logoutBoton">TOTAL {total} Pzas.</h4>
                  </Col>
                </Row>
                {/* Termina Tabla Articulos */}

                <br />
                {validaBoton ? (
                  <Button type="submit" className="btn btn-success">
                    Guardar
                  </Button>
                ) : (
                  <Button type="submit" className="btn btn-success" disabled>
                    Guardar
                  </Button>
                )}
                <Button
                  onClick={(e) => setAbrirSurtir(false)}
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Form>
            </div>
          )}
        </div>
      ) : undefined}
      <br />

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            Este proceso puede tardar varios segundos.
            <br />
            Por favor no cierre ni refresque su navegador.
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoPendientePedidoCliente;
