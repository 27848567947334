import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultipleSurtido from "../../StatusMultipleSurtido";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function ListadoPedidoCliente() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_PEDIDOS_CLIENTE = process.env.REACT_APP_URL_PEDIDOS_CLIENTE;
  const URL_ARTICULOS_PEDIDO_CLIENTE =
    process.env.REACT_APP_URL_ARTICULOS_PEDIDO_CLIENTE;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");

  const [clientesName, setClientesName] = useState([]);
  const [clientesCodigo, setClientesCodigo] = useState([]);

  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedAreaFiltro, setSelectedAreaFiltro] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");

  const [numero_pedido, setNumeroPedido] = useState("");
  const [pedidoCliente, setPedidoCliente] = useState("");
  const [empresa, setEmpresa] = useState("");

  const [inventarios, setInventarios] = useState([]);
  const [idsArticulos, setIdsArticulos] = useState([]);
  const [fecha, setFecha] = useState(hoy);
  const [areas, setAreas] = useState([]);
  const [observaciones, setObservaciones] = useState("NA");
  const [pedido, setPedido] = useState("");
  const [total, setTotal] = useState(0);

  const [pedidos, setPedidos] = useState([]);
  const [selectedPedidoCliente, setSelectedPedidoCliente] = useState("");
  const [selectedPedido, setSelectedPedido] = useState("");
  const [cliente, setCliente] = useState("");

  const [articulosPed, setArticulosPed] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(true);

  const [modalEditArticulos, setModalEditArticulos] = useState(false);
  const toggleEditArticulos = () => setModalEditArticulos(!modalEditArticulos);
  const [articulosPedido, setArticulosPedido] = useState([]);
  const [total_general_edit, setTotalGeneralEdit] = useState(0);
  const [total_kilos_edit, setTotalKilosEdit] = useState(0);
  const [total_metros_edit, setTotalMetrosEdit] = useState(0);
  const [total_cantidad_edit, setTotalCantidadEdit] = useState(0);
  const [idEdit, setIdEdit] = useState("");
  const [articulos, setArticulos] = useState([]);

  const [modalEditFecha, setModalEditFecha] = useState(false);
  const toggleEditFecha = () => setModalEditFecha(!modalEditFecha);
  const [fechaCita, setFechaCita] = useState("");
  const [fechaCancelacion, setFechaCancelacion] = useState("");
  const [fechaConfirmacion, setFechaConfirmacion] = useState("");
  const [idEditFecha, setIdEditFecha] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [activo, setActivo] = useState("");
  const [impuestos, setImpuestos] = useState(0);
  const [comisionFlete, setComisionFlete] = useState(0);
  const [comisionTDC, setComisionTDC] = useState(0);

  const [idColaboradores, setIdColaboradores] = useState("");

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cantidad: 0,
      cantInv: 0,
      idInv: "",
      idArtPed: "",
      precio: 0,
      total: 0,
      pendSurtir: 0,
      cantPed: 0,
      idTalla: "",
      talla: "",
      idColor: "",
      color: "",
    },
  ]);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    setSelectedArea(user.areas);
    axios
      .get(`${URL_PEDIDOS_CLIENTE}PendienteSurtir`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPedidos = response.data;
        // Array para pagination
        let arrayTabla = allPedidos
          .sort((a, b) => (a.idPedido > b.idPedido ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idPedidoCliente,
              activo: a.is_active,
              fecha: a.fecha,
              colaboradores:
                a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
              idColaboradores: a.colaboradores[0]._id,
              compradores: a.compradores[0].name,
              idCompradores: a.compradores[0]._id,
              observaciones: a.observaciones,
              total_general: a.total_general,
              clientes: a.clientes[0].nombre_comercial,
              clientesCodigo: a.clientes[0].codigo,
              idClientes: a.clientes[0]._id,
              total_piezas: a.total_piezas,
              status: a.status,
              autorizado: a.autorizado,
              surtido: a.surtido,
              impuestos: a.impuestos,
              pedidoCliente: a.pedidoCliente,
              idPedido: a.pedidos[0]._id,
              empresa: a.empresas[0]._id,
              empresaNombre: a.empresas[0].name,
              articulo:
                "Cod: " +
                a.articulos[0].codigoCliente +
                " / Ref: " +
                a.articulos[0].codigo +
                " / Desc: " +
                a.articulos[0].descripcion,
              marca: a.articulos[0].marcas[0].name,
              pedido: a.pedidoCliente,
              fecha_cancelacion: a.fecha_cancelacion,
              fecha_confirmacion: a.fecha_confirmacion,
              fecha_cita: a.fecha_cita,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);

        setPedidos(allPedidos);
        setComments(data);

        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [
          a.fecha,
          a.empresaNombre,
          a.clientes,
          a.compradores,
          a.colaboradores,
          a.marca,
          a.articulo,
          a.total_piezas,
          a.pedido,
          a.fecha_cancelacion,
          a.fecha_confirmacion,
          a.fecha_cita,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Pedidos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Fecha Ped.",
          "Empresa",
          "Cliente",
          "Comprador",
          "Vendedor",
          "Marca",
          "Articulo",
          "Cantidad",
          "Pedido",
          "Fecha Canc.",
          "Fecha Conf.",
          "Fecha Cita",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    // doc.save(`Pedidos.pdf`);
    window.open(doc.output("bloburl"), "_blank");
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return {
          FechaPed: a.fecha,
          Empresa: a.empresaNombre,
          Cliente: a.clientes,
          Comprador: a.compradores,
          Vendedor: a.colaboradores,
          Marca: a.marca,
          Articulo: a.articulo,
          Cantidad: a.total_piezas,
          Pedido: a.pedido,
          FechaCanc: a.fecha_cancelacion,
          FechaConf: a.fecha_confirmacion,
          FechaCita: a.fecha_cita,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoPedidos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoPedidos",
        sheetFilter: [
          "FechaPed",
          "Empresa",
          "Cliente",
          "Comprador",
          "Vendedor",
          "Marca",
          "Articulo",
          "Cantidad",
          "Pedido",
          "FechaCanc",
          "FechaConf",
          "FechaCita",
        ],
        sheetHeader: [
          "FechaPed",
          "Empresa",
          "Cliente",
          "Comprador",
          "Vendedor",
          "Marca",
          "Articulo",
          "Cantidad",
          "Pedido",
          "FechaCanc",
          "FechaConf",
          "FechaCita",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [
          a.fecha,
          a.empresaNombre,
          a.clientes,
          a.compradores,
          a.colaboradores,
          a.marca,
          a.articulo,
          a.total_piezas,
          a.pedido,
          a.fecha_cancelacion,
          a.fecha_cita,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Pedidos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Fecha Ped.",
          "Empresa",
          "Cliente",
          "Comprador",
          "Vendedor",
          "Marca",
          "Articulo",
          "Cantidad",
          "Pedido",
          "Fecha Canc.",
          "Fecha Cita",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Pedidos",
          email: mailTo,
          fileName: "ListadoPedidos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Pedidos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha Alta Pedido", field: "fecha", sortable: true },
    { name: "Empresa", field: "empresa", sortable: true },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Comprador", field: "compradores", sortable: true },
    { name: "Vendedor", field: "colaboradores", sortable: true },
    { name: "Marca", field: "marca", sortable: true },
    { name: "Articulo", field: "articulo", sortable: true },
    { name: "Observaciones", field: "observaciones", sortable: true },
    { name: "Cantidad", field: "total_piezas", sortable: true },
    { name: "Pedido", field: "pedido", sortable: true },
    { name: "Fecha Cancelacion", field: "fecha_cancelacion", sortable: true },
    { name: "Fecha Confirmacion", field: "fecha_confirmacion", sortable: true },
    { name: "Fecha Cita", field: "fecha_cita", sortable: true },
    { name: "Editar", field: "editar", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.empresa.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.compradores.toLowerCase().includes(search.toLowerCase()) ||
          comment.colaboradores.toLowerCase().includes(search.toLowerCase()) ||
          comment.marca.toLowerCase().includes(search.toLowerCase()) ||
          comment.articulo.toLowerCase().includes(search.toLowerCase()) ||
          comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
          comment.pedido.toLowerCase().includes(search.toLowerCase()) ||
          comment.numero.toString().includes(search) ||
          comment.total_piezas.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idColaboradores.includes(selectedColaborador)
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "numero" &&
      sorting.field != "total_piezas"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "numero" || sorting.field == "total_piezas")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "numero" || sorting.field == "total_piezas")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedColaborador,
    activo,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  function jalaInfo(
    fecha_cita,
    fecha_confirmacion,
    fecha_cancelacion,
    observaciones,
    pedido,
    idEditCita
  ) {
    setFechaCita(fecha_cita);
    setFechaConfirmacion(fecha_confirmacion);
    setFechaCancelacion(fecha_cancelacion);
    setObservaciones(observaciones);
    setPedido(pedido);
    setIdEditFecha(idEditCita);
    toggleEditFecha();
  }

  const saveEditFecha = async (event) => {
    event.preventDefault();
    try {
      await axios
        .patch(
          `${URL_PEDIDOS_CLIENTE}/${idEditFecha}`,
          {
            fecha_cita: fechaCita,
            fecha_confirmacion: fechaConfirmacion,
            fecha_cancelacion: fechaCancelacion,
            pedidoCliente: pedido,
            observaciones
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          Swal.fire("Good job!", "Creado con exito", `success`);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  // save

  function EditArticulos(idOC, numero_pedido) {
    setIdEdit(idOC);
    setNumeroPedido(numero_pedido);
    axios
      .get(`${URL_ARTICULOS_PEDIDO_CLIENTE}/pedidos/${idOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = allArticulosPedido
          .map((a) => {
            return {
              id: a._id,
              activo: a.is_active,
              articulos: a.articulos[0]._id,
              unidad: a.articulos[0].unidad,
              colores: a.colores[0]._id,
              tallas: a.tallas[0]._id,
              arrayColoresArticulo: a.articulos[0].colores,
              arrayTallasArticulo: a.articulos[0].tallas,
              cantidad: a.cantidad,
              cantidadAnterior: a.cantidad,
              surtido: a.surtido,
              precio: a.precio,
              precioEtiqueta: a.articulosPedido[0].precioEtiqueta,
              descuento: a.descuento,
              total: a.total,
              pendiente_surtir: a.pendiente_surtir,
              resurtido: a.resurtido,
              codigoBarras: parseFloat(a.codigoBarras),
              articulosPedido: a.articulosPedido[0]._id,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setArticulosPedido(dataFinal);

        let totalMts = dataFinal.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalCantidadEdit(TM);
        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneralEdit(TG);
      })
      .catch((err) => {
        console.log(err);
      });
    toggleEditArticulos();
  }

  const handleChangeInputExistentes = (id, event) => {
    const newArticulosPedido = articulosPedido.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(
          2
        );
        i.pendiente_surtir = i.cantidad - i.surtido;
      }
      return i;
    });
    setArticulosPedido(newArticulosPedido);
    ActualizaTotalesEdit();
  };

  function ActualizaTotalesEdit() {
    let cantidadExistentes = articulosPedido.map((c) => parseFloat(c.cantidad));
    let cantidadNuevos = inputFields.map((c) => parseFloat(c.cantidad));
    let cantidad = cantidadExistentes.concat(cantidadNuevos);
    let TC = cantidad.reduce((t, total, index) => t + total, 0);
    setTotalCantidadEdit(TC);

    let importesExistentes = articulosPedido.map((c) => parseFloat(c.total));
    let importesNuevos = inputFields.map((c) => parseFloat(c.total));
    let importes = importesExistentes.concat(importesNuevos);
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneralEdit(TG);
  }

  const savePedido = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();
    let totalPedido = articulosPedido.length;
    const URL_PEDIDOS_CLIENTE_EDIT = `${process.env.REACT_APP_URL_PEDIDOS_CLIENTE}/${idEdit}`;
    try {
      await axios
        .patch(
          URL_PEDIDOS_CLIENTE_EDIT,
          {
            total_piezas: total_cantidad_edit,
            pendiente_surtir: total_cantidad_edit,
            total_general: total_general_edit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          articulosPedido.map((a) => {
            axios
              .patch(
                `${URL_ARTICULOS_PEDIDO_CLIENTE}/${a.id}`,
                {
                  cantidad: parseFloat(a.cantidad),
                  precio: parseFloat(a.precio),
                  total: parseFloat(a.total),
                  pendiente_surtir: parseFloat(a.pendiente_surtir),
                  idArticulosPedido: a.articulosPedido,
                  cantNueva:
                    parseFloat(a.cantidadAnterior) - parseFloat(a.cantidad),
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalPedido = totalPedido - 1;
                if (totalPedido == 0) {
                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Editar Pedido",
                      detalle: `${numero_pedido}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );
                  Swal.fire("Good job!", "Creado con exito", `success`);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              });
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  function cancelarPedido(id) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se cancelara el Pedido!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(`${URL_PEDIDOS_CLIENTE}Cancel/${id}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then(() => {
            Swal.fire("Good job!", "Enviado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_pedidos ? (
        <div className="card col-12">
          <div>
            <Row>
              <Col md={8}>
                <Button
                  size="sm"
                  href="/MenuVentas"
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Col>
              <Col sm={4}>
                <ButtonGroup id="logoutBoton">
                  <Button
                    size="sm"
                    className="btn"
                    color="danger"
                    onClick={PDFTabla}
                  >
                    PDF <i class="far fa-file-pdf"></i>
                  </Button>
                  <Button
                    size="sm"
                    className="btn"
                    onClick={toggleMail}
                    color="info"
                  >
                    eMail <i class="fas fa-at"></i>
                  </Button>
                  <Button
                    size="sm"
                    className="btn"
                    color="primary"
                    onClick={excel}
                  >
                    Excel <i class="far fa-file-excel"></i>
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
              <ModalHeader toggle={toggleMail}>
                <h4>Enviar Listado Pendiente Surtir</h4>
              </ModalHeader>
              <ModalBody>
                <Label className="mr-sm-2">Email</Label>
                <Input
                  className="col-sm-12"
                  type="text"
                  value={mailTo}
                  required
                  onChange={(e) => {
                    setMailTo(e.target.value);
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <Button size="sm" color="success" onClick={enviaMail}>
                  Enviar
                </Button>
              </ModalFooter>
            </Modal>
            <h3 align="center">Listado Pedidos Clientes</h3>
            <div className="row">
              <div className="col-md-8">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
              <div className="col-md-4 d-flex flex-row-reverse">
                <Search
                  onSearch={(value) => {
                    setSearch(value);
                    setCurrentPage(1);
                  }}
                />
              </div>
            </div>

            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Fecha Inicio</Label>
                <Input
                  bsSize="sm"
                  type="date"
                  value={selectedFechaInicio}
                  required
                  onChange={(e) => {
                    setSelectedFechaInicio(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Fecha Fin</Label>
                <Input
                  bsSize="sm"
                  type="date"
                  value={selectedFechaFin}
                  required
                  onChange={(e) => {
                    setSelectedFechaFin(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>Clientes</Label>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedCliente}
                  onChange={(e) => {
                    setSelectedCliente(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {clientes
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </Col>

              <Col md={2}>
                <Label>Vendedor</Label>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedColaborador}
                  onChange={(e) => {
                    setSelectedColaborador(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona un Vendedor</option>
                  {colaboradores
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                    })}
                </Input>
              </Col>
            </Row>
            <br />
            <Table size="sm" striped borderless className="table-responsive-xl">
              <TableHeader
                headers={headers}
                onSorting={(field, order) => setSorting({ field, order })}
              />
              <tbody>
                {commentsData.map((a) => {
                  return (
                    <tr>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.empresaNombre}</td>
                      <td>{a.clientes}</td>
                      <td>{a.compradores}</td>
                      <td>{a.colaboradores}</td>
                      <td>{a.marca}</td>
                      <td>{a.articulo}</td>
                      <td>{a.observaciones}</td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.total_piezas)}
                      </td>
                      <td>{a.pedido}</td>
                      <td>{a.fecha_cancelacion}</td>
                      <td>{a.fecha_confirmacion}</td>
                      <td>{a.fecha_cita}</td>
                      {a.activo == "Si" ? (
                        <td>
                          {a.surtido == 0 ? (
                            <>
                              <Button
                                color="info"
                                id="Editar"
                                size="sm"
                                onClick={(e) => EditArticulos(a._id, a.numero)}
                              >
                                <i class="fas fa-cart-plus"></i>
                              </Button>
                              <Button
                                color="success"
                                id="Editar"
                                size="sm"
                                onClick={(e) =>
                                  jalaInfo(
                                    a.fecha_cita,
                                    a.fecha_confirmacion,
                                    a.fecha_cancelacion,
                                    a.observaciones,
                                    a.pedido,
                                    a._id
                                  )
                                }
                              >
                                <i class="fas fa-edit"></i>
                              </Button>

                              <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                onClick={(e) => cancelarPedido(a._id)}
                              >
                                <i class="fas fa-ban"></i>
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                color="info"
                                id="Editar"
                                size="sm"
                                disabled
                              >
                                <i class="fas fa-cart-plus"></i>
                              </Button>

                              <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                disabled
                              >
                                <i class="fas fa-ban"></i>
                              </Button>
                            </>
                          )}
                        </td>
                      ) : (
                        <td>CANCELADO</td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div className="col-md-6">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            Este proceso puede tardar varios segundos.
            <br />
            Por favor no cierre ni refresque su navegador.
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal size="lg" isOpen={modalEditFecha} toggle={toggleEditFecha}>
        <ModalHeader toggle={toggleEditFecha}>
          <h4>Editar Pedido {pedido}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={4}>
              <Label>Fecha Entrega</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fechaCita}
                onChange={(e) => {
                  setFechaCita(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label>Fecha Confirmacion</Label>
              <Input
                type="date"
                placeholder="FechaConfirmacion"
                value={fechaConfirmacion}
                onChange={(e) => {
                  setFechaConfirmacion(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label>Fecha Cancelacion</Label>
              <Input
                type="date"
                placeholder="FechaCancelacion"
                value={fechaCancelacion}
                onChange={(e) => {
                  setFechaCancelacion(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
          <Col md={6}>
              <Label>Pedido</Label>
              <Input
                type="text"
                placeholder="Pedido"
                value={pedido}
                onChange={(e) => {
                  setPedido(e.target.value);
                }}
              />
            </Col>

          <Col md={6}>
              <Label>Observaciones</Label>
              <Input
                type="text"
                placeholder="Observaciones"
                value={observaciones}
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={saveEditFecha}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        size="xxl"
        isOpen={modalEditArticulos}
        toggle={toggleEditArticulos}
      >
        <ModalHeader toggle={toggleEditArticulos}>
          <h4>Editar Articulos Pedido {numero_pedido}</h4>
          <h4>
            TOTALES {new Intl.NumberFormat("en-US").format(total_cantidad_edit)}{" "}
            pzas. /{" "}
            {"$" + new Intl.NumberFormat("en-US").format(total_general_edit)}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={savePedido}>
            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Articulos</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Colores</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Tallas</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Surtido</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio Venta</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio Etiqueta</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
            </Row>

            {articulosPedido
              .sort(
                (a, b) =>
                a.colores.localeCompare(b.colores) ||
                  a.tallas.localeCompare(b.tallas),
              )
              .map((ao) => (
                <div key={ao.id}>
                  <Row>
                    <>
                      <Col md={2}>
                        <Input
                          name="articulos"
                          type="select"
                          value={ao.articulos}
                          required
                          disabled
                        >
                          <option value="">Selecciona</option>
                          {articulos
                            .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                            .map((a) => {
                              return (
                                <option value={a._id}>
                                  {a.codigo} / {a.codigoCliente} /{" "}
                                    {a.descripcion}
                                </option>
                              );
                            })}
                        </Input>
                      </Col>
                    </>
                    <>
                      <Col md={1}>
                        <Input
                          name="colores"
                          type="select"
                          value={ao.colores}
                          required
                          disabled
                        >
                          <option value="">Selecciona</option>
                          {ao.arrayColoresArticulo
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                            })}
                        </Input>
                      </Col>
                      <Col md={1}>
                        <Input
                          name="tallas"
                          type="select"
                          value={ao.tallas}
                          required
                          disabled
                        >
                          <option value="">Selecciona</option>
                          {ao.arrayTallasArticulo
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                            })}
                        </Input>
                      </Col>
                    </>

                    <Col md={1}>
                      <Input
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={ao.cantidad}
                        required
                        onChange={(event) =>
                          handleChangeInputExistentes(ao.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        name="surtido"
                        type="number"
                        placeholder="surtido"
                        value={ao.surtido.toFixed(2)}
                        disabled
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        name="precio"
                        type="number"
                        placeholder="Precio"
                        value={ao.precio}
                        required
                        disabled
                      />
                    </Col>
                    <Col md={1}>
                      <Input
                        name="precioEtiqueta"
                        type="number"
                        placeholder="PrecioEtiqueta"
                        value={ao.precioEtiqueta}
                        required
                        disabled
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="total"
                        type="number"
                        placeholder="Total"
                        value={ao.total}
                        disabled
                        onChange={(event) =>
                          handleChangeInputExistentes(ao.id, event)
                        }
                      />
                    </Col>
                  </Row>
                </div>
              ))}

            <Row>
              <Col md={6}>
                <h4 id="logoutBoton">
                  TOTALES{" "}
                  {new Intl.NumberFormat("en-US").format(total_cantidad_edit)}{" "}
                  pzas. /{" "}
                  {"$" +
                    new Intl.NumberFormat("en-US").format(total_general_edit)}
                </h4>
              </Col>
            </Row>
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
          </Form>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
      {loader}
    </>
  );
}

export default ListadoPedidoCliente;
