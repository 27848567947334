import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Col, Row, Button, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";

function ArticulosPadreCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS_PADRE = process.env.REACT_APP_URL_ARTICULOS_PADRE;
  const URL_DEPARTAMENTOS = process.env.REACT_APP_URL_DEPARTAMENTOS;
  const URL_SUBDEPARTAMENTOS = process.env.REACT_APP_URL_SUBDEPARTAMENTOS;
  const URL_CLASES = process.env.REACT_APP_URL_CLASES;
  const URL_SUBCLASES = process.env.REACT_APP_URL_SUBCLASES;
 
  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  const [subDepartamentos, setSubDepartamentos] = useState([]);
  const [selectedSubDepartamento, setSelectedSubDepartamento] = useState("");
  const [clases, setClases] = useState([]);
  const [selectedClase, setSelectedClase] = useState("");
  const [subClases, setSubClases] = useState([]);
  const [selectedSubClase, setSelectedSubClase] = useState("");


  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_DEPARTAMENTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDep = response.data;
        setDepartamentos(allDep);
        //
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_SUBDEPARTAMENTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSubDep = response.data;
        setSubDepartamentos(allSubDep);
        //
      })
      .catch((err) => {
        console.log(err);
      });


      axios
      .get(URL_CLASES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClase = response.data;
        setClases(allClase);
        //
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_SUBCLASES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSubClase = response.data;
        setSubClases(allSubClase);
        //
      })
      .catch((err) => {
        console.log(err);
      });
   
  }, []);


  const saveArticulo = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    try {
      await axios
        .post(
          URL_ARTICULOS_PADRE,
          {
            departamentos: selectedDepartamento,
            subDepartamentos: selectedSubDepartamento,
            clases: selectedClase,
            subClases: selectedSubClase,            
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )   .then((data) => {
                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Crear Articulo Padre",
                      detalle: `${data.data._id}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );
                  Swal.fire("Good job!", "Creado con exito", `success`);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true)
                });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };


  return (
    <>
      <Header />
      <br />
      <br />
      {user.articulos_create ? (
        <div className="card container">
          <h3 align="center">Nuevo Articulo</h3>
          <Form onSubmit={saveArticulo}>

          <Row>
            <Col md={6}>
            <Label className="mr-sm-2">Departamento</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedDepartamento}
                  required
                  onChange={(e) => {
                    setSelectedDepartamento(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {departamentos
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
            </Col>

            <Col md={6}>
            <Label className="mr-sm-2">Sub Departamento</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedSubDepartamento}
                  required
                  onChange={(e) => {
                    setSelectedSubDepartamento(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {subDepartamentos
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
            <Label className="mr-sm-2">Clase</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedClase}
                  required
                  onChange={(e) => {
                    setSelectedClase(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {clases
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
            </Col>

            <Col md={6}>
            <Label className="mr-sm-2">Etiqueta</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedSubClase}
                  required
                  onChange={(e) => {
                    setSelectedSubClase(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {subClases
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
            </Col>
          </Row>

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoArticulosPadre"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />

         
        </div>
      ) : undefined}
    </>
  );
}

export default ArticulosPadreCreate;
